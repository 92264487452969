import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NGXLogger } from 'ngx-logger';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import Swal from 'sweetalert2';
import { IncidenceService } from 'src/app/services/service.index';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-modal-order-detail',
  templateUrl: './modal-order-detail.component.html',
  styleUrls: ['./modal-order-detail.component.scss'],
  providers: [
    NGXLogger,
  ],
})
export class ModalOrderDetailComponent implements OnInit {
  company = '';
  displayedColumns = [
    'productCode', 'productName', 'measurementUnit', 'originProduct', 'listPriceUnit',
    'pickingQuantity', 'discount', 'totalPrice', 'seller', 'select',
  ];
  dataSource;
  detail: any;
  products: any[];
  fromIncidenceDialog: boolean = false;
  incidenceData: any;
  searchDocument: string;

  @ViewChild('sortOrderDetails', {static: true}) sort: MatSort;
  selection = new SelectionModel<any>(true, []);

  constructor(
    public incidenceService: IncidenceService,
    public dialogRef: MatDialogRef<ModalOrderDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private overlay: Overlay,
    public logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.detail = this.data.order;
    this.searchDocument = this.data.document,
    this.dataSource = new MatTableDataSource(this.detail.lstDetail);
    this.dataSource.sort = this.sort;
    this.selection.clear();

    this.dataSource.data.forEach(row => {
      if (row.selected) {
        this.selection.select(row);
      }
    });

    this.isAllSelected();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.dataSource.data.forEach(row => {
        row.selected = false;
      });
    } else {
      this.dataSource.data.forEach(row => {
        row.selected = true;
        this.selection.select(row);
      });
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  saveSelectedProducts() {
    this.logger.debug('selection', this.selection.selected, this.dataSource.data);
    this.products = this.products || [];

    this.selection.selected.forEach(product => {
      this.products.push({
        origin: product.originProduct,
        seller: product.seller,
        quantity: +product.pickingQuantity,
        listPriceUnit: +product.listPriceUnit,
        discount: +product.discount,
        totalPrice: +product.totalPrice,
        product: {
          code: product.productCode,
          name: product.productName,
        },
      });
    });

    this.detail.lstDetail = this.dataSource.data;
    this.dialogRef.close(this.detail);
  }
}
