import { Injectable, Inject } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_USER, RESTANGULAR_FCM } from 'src/app/app.restangular.config';
import { AuthService } from '../service.index';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable()
export class NotificationService {
  authState: any = null;
  currentMessage = new BehaviorSubject(null);
  usersBasePath;
  fcmBasePath;

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    @Inject(RESTANGULAR_USER) private restangularUserBase,
    @Inject(RESTANGULAR_FCM) private restangularFCMBase,
    private authService: AuthService,
    private logger: NGXLogger) {
      this.usersBasePath = this.restangularUserBase.all('auth').all('v1');
      this.fcmBasePath = this.restangularFCMBase.all('notifications');

      this.angularFireAuth.authState.subscribe((auth) => {
        this.authState = auth;
      });

      this.angularFireMessaging.messaging.subscribe(
        (messaging) => {
          messaging.onMessage = messaging.onMessage.bind(messaging);
          messaging.onTokenRefresh = messaging.onTokenRefresh.bind(messaging);
        }
      );
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token;
        this.angularFireDB.object('fcmTokens/').update(data);

        const tokenData = {
          user: { id: userId },
          token,
        };

        this.usersBasePath.all('fcm').all('tokens').all('save').post(tokenData).subscribe(
          response => {
            this.logger.debug('save fcm token', response);
            this.authService.saveFcmTokemId(response.id, response.token);
          },
        );
      });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token: string) => {
        this.logger.debug('requestPermission', token);

        if (token && token.length > 0) {
          this.updateToken(userId, token);
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `Error al activar las notificaciones. Por favor refresque la página`,
            showCancelButton: true,
            confirmButtonColor: '#20a8d8',
            cancelButtonColor: '#ea635f',
            confirmButtonText: 'Refrescar',
            cancelButtonText: 'Cancelar'
          }).then(
            result => {
              if (result.value) {
                window.location.reload();
              }
            },
          );
        }
      },
      (err) => {
        this.logger.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        this.logger.debug("new message received. ", payload);
        this.currentMessage.next(payload);
      });
  }

  get isUserAnonymousLoggedIn(): boolean {
    return (this.authState !== null) ? this.authState.isAnonymous : false;
  }

  get currentUserId(): string {
    return (this.authState !== null) ? this.authState.uid : '';
  }

  anonymousLogin() {
    return this.angularFireAuth.auth.signInAnonymously()
      .then((user) => {
        this.authState = user;
      })
      .catch(error => this.logger.debug(error));
  }

  signOut(): void {
    this.angularFireAuth.auth.signOut();
  }

  resetNotifications() {
    const token = this.authService.getFcmToken();
    this.fcmBasePath.all('tracking').all('reset').post({token}).subscribe(
      response => this.logger.debug('reset response', response),
    );
  }

  visitedNotification(id: number) {
    const token = this.authService.getFcmToken();
    this.fcmBasePath.all('tracking').all('visited').post({token, id}).subscribe(
      response => this.logger.debug('visited response', response),
    );
  }
}
