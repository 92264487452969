import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-ost-contacts',
  templateUrl: './modal-ost-contacts.component.html',
  styleUrls: ['./modal-ost-contacts.component.scss']
})
export class ModalOstContactsComponent implements OnInit {

  contacts = new FormArray([]);
  contactCtrl = new FormControl('', [ Validators.email]);
  contact: string;
  constructor(public dialogRef: MatDialogRef<ModalOstContactsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit() {

    if(this.data.contacts){
      const contacts:string = this.data.contacts;
      const listContacts = contacts.split(';');
      listContacts.forEach( item => {
        this.contacts.push(new FormControl(item));
      })
    }
    
  }

  getErrorMessage() {
    return this.contactCtrl.hasError('email') ? 'correo inválido' : '';
  }

  addContacts() {

    if(!this.contactCtrl.value) return;
    if(this.contactCtrl.value.trim().length == '') return;
    if(this.contactCtrl.hasError('email')) return

    if (this.contacts.length < 5) {
      this.contacts.push(new FormControl(this.contactCtrl.value));
      this.contactCtrl.setValue('');
    }
  }

  dropItemTo(i) {
    this.contacts.removeAt(i);
  }

  saveContacts() {
    const saveContacts = this.contacts.value;
    this.dialogRef.close(saveContacts.join(';'));
    console.log('saveContacts', saveContacts);
  }
}
