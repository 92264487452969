import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { Restangular } from 'ngx-restangular';
import { RESTANGULAR_USER } from 'src/app/app.restangular.config';
import Swal from 'sweetalert2';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth-service.service';

@Component({
  selector: 'app-modal-user-edit',
  templateUrl: './modal-user-edit.component.html',
  styleUrls: ['./modal-user-edit.component.scss'],
})
export class ModalUserEditComponent implements OnInit {
  sessionUser: string;
  user: any;
  isSameUsername: boolean = false;
  usernameExists: boolean = false;
  saving: boolean = false;
  checking: boolean = false;
  textAreaMinRows: number = environment.textAreaMinRows;
  textAreaMaxRows: number = environment.textAreaMaxRows;
  usersBasePath: Restangular;
  term$ = new Subject<string>();

  private checkUsernameSubscription: Subscription;

  constructor(
    private authService: AuthService,
    @Inject(RESTANGULAR_USER) public restangularUserBase,
    public dialogRef: MatDialogRef<ModalUserEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public logger: NGXLogger,
  ) {
    this.usersBasePath = this.restangularUserBase.all('auth').all('v1');

    this.checkUsernameSubscription = this.term$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => {
        if (term.length >= 6) {
          this.checkUsername(term);
        }

        return term;
      })
    ).subscribe();
  }

  ngOnInit() {
    this.user = this.data.user;

    this.authService.getUser().subscribe((user: any) => {
      this.sessionUser = user.username;
    });
  }

  checkUsername(username: string) {
    if (username.toLowerCase() === this.user.userName.toLowerCase()) {
      this.isSameUsername = true;
      return;
    }

    this.isSameUsername = false;
    this.checking = true;

    this.usersBasePath.all('users').one('', this.user.id).one('check', username).get().subscribe(
      response => {
        this.logger.debug('check', response);
        this.usernameExists = response.data;
        this.checking = false;
      },
      error => {
        this.logger.error(error);
        this.checking = false;
      }
    );
  }

  save() {
    this.saving = true;
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Actualizando...',
    });

    Swal.showLoading();

    this.usersBasePath.all('users').all('username').customPUT({
      id: this.user.id,
      username: this.user.newUsername,
      comment: this.user.comment,
      sessionUser: this.sessionUser,
      systemCode: environment.commons.systemCode,
    }).subscribe(
      response => {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `<strong class='modal-swal-title'>Se modificó el usuario a:</strong>`,
          html: `<span class='modal-swal-subtitle'>${this.user.newUsername}</spa>`,
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 1500,
        });

        this.dialogRef.close(true);
      },
      error => {
        this.logger.error(error);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `<strong class='modal-swal-title'>Error al actualizar el usuario</strong>`,
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 3000,
        });

        this.dialogRef.close();
      },
    );
  }
}
