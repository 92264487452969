import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Restangular } from "ngx-restangular";
import { Observable } from "rxjs";
import { RESTANGULAR_CLAIMS, RESTANGULAR_REPORTS } from "src/app/app.restangular.config";
import { environment } from "src/environments/environment";
import { IncidenceService } from "../incidencia/incidence.service";


@Injectable({
    providedIn: 'root'
  })
  export class ClaimService {
    private reportsBasePath: Restangular;
    private claimsBasePath: Restangular;

    constructor(
        private http: HttpClient,
        private incidenceService: IncidenceService,
        @Inject(RESTANGULAR_REPORTS) private restangularReportBase,
        @Inject(RESTANGULAR_CLAIMS) private restangularClaimsBase,
    ) {
        this.reportsBasePath = this.restangularReportBase;
        this.claimsBasePath = this.restangularClaimsBase;
    }

    obtainClaimsByFilters(filters: any, pageInfo?: any) {
        if (!pageInfo) {
            pageInfo = {
                orderDir: 'DESC',
                orderColumn: 'CREATED_ON',
                pageNumber: null,
                pageSize: 20,
            };
        }

        return this.reportsBasePath.all('claims').all('tracking').all('data').post(pageInfo, filters);
    }

    countClaimsByFilters(filters: any) {
        return this.reportsBasePath.all('claims').all('tracking').all('data').all('count').post({}, filters);
    }

    downloadClaimsByFilters(filters: any): Observable<any> {
        const user = JSON.parse(localStorage.getItem('user'));
        const data = {
            requestBy: user.username,
            requestByEmail: user.email,
        };

        return this.incidenceService.post(`${environment.API_REPORTS}/claims/tracking/export`, data, filters);
    }

    downloadStatusReport(filters: any): Observable<any> {
        const user = JSON.parse(localStorage.getItem('user'));
        const data = {
          requestBy: user.username,
          requestByEmail: user.email,
        };

        return this.incidenceService.post(`${environment.API_REPORTS}/claims/status/export`, data, filters);
    }

    changeState(data: any) {
        return this.claimsBasePath.one('', data.company).all('claims').one('', data.claim.id).all('status').post(data);
    }

    updateFinalizeValues(data: any) {
        const url = `${environment.API_CLAIMS}/${data.company}/claims/${data.id}/finalize`;
        const request = new Request(url, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(data),
        });

        return fetch(request);
    }

    obtainStatusTotalPieData(filters: any) {
        return this.reportsBasePath.all('claims').all('status').all('total').all('data').all('chart').getList(filters);
    }

    obtainStatusPendingPieData(filters: any) {
        return this.reportsBasePath.all('claims').all('status').all('pending').all('data').all('chart').getList(filters);
    }

    getLRResponseTemplate(company: string) {
        return this.http.get(`assets/templates/response-template-${company}.html`, {responseType: 'text' as 'json'});
    }
  }
