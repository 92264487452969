import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponsibleModels } from 'src/app/models/Responsible.models';
import { FormControl, NgForm, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { IncidenceService, UtilService } from 'src/app/services/service.index';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_USER } from 'src/app/app.restangular.config';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, map, catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-modal-assign',
  templateUrl: './modal-assign.component.html',
  styleUrls: ['./modal-assign.component.scss'],
})
export class ModalAssignComponent implements OnInit {
  assignRequest: ResponsibleModels = new ResponsibleModels();
  fromMassiveReasign: boolean = false;
  showError = false;
  company = '';
  basePath: any;
  tos: any[] = [];

  textAreaMinRows: number = +9;
  textAreaMaxRows: number = +9;

  searchToCtrl = new FormControl();
  searchToLoading: boolean = false;
  filteredUsers: any[];

  constructor(
    public incidenceService: IncidenceService,
    public dialogRef: MatDialogRef<ModalAssignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RESTANGULAR_USER) public restangularBase,
    private utilService: UtilService,
    public logger: NGXLogger,
  ) {
    this.basePath = this.restangularBase.all('auth').all('v1');
  }

  ngOnInit() {
    this.logger.debug('data', this.data);
    this.assignRequest.id = this.data.trackingIncidence ? this.data.trackingIncidence.id : null;
    this.fromMassiveReasign = this.data.fromMassiveReasign || false;
    this.company = this.fromMassiveReasign ? localStorage.getItem('company') : null;
    this.searchToCtrl.setValidators([Validators.required]);

    this.searchToCtrl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.filteredUsers = [];
          this.searchToLoading = true;
        }),
        switchMap((keyword: string) => !keyword || (keyword && keyword.length < 3) ? [] :
          this.basePath.all('users').all('search').getList({keyword, company: this.company || ''})
          .pipe(
            finalize(() => this.searchToLoading = false),
          ),
        ),
      )
      .subscribe((data: any[]) => {
        this.filteredUsers = data.length > 0 ? data : [];
      });
  }

  displayNamePropertyFn(item: any): string {
    return item && item.username ? item.username : '';
  }

  selectUser($event: MatAutocompleteSelectedEvent) {
    this.logger.debug('selectUser', $event);

    if ($event.option.value) {
      let user: any = $event.option.value;

      if (user.email) {
        const findTo = this.tos.find(item => {
          return item === user.email.trim();
        });

        if (!findTo && user && user.email && user.email.trim().length > 0) {
          this.tos.push(user.email.trim());
        }
      }
    }

    this.resetTxtTo();
  }

  resetTxtTo() {
    this.searchToCtrl.setValue('');
    this.filteredUsers = [];
    this.searchToCtrl.setValidators(this.tos.length > 0 ? [Validators.nullValidator] : [Validators.required]);
    this.searchToCtrl.updateValueAndValidity();
  }

  dropItemTo(index: any) {
    this.tos.splice(index, 1);
    this.resetTxtTo();
  }

  assignResponsible(form: NgForm) {
    if (form.invalid) {
      this.utilService.markFormGroupTouched(form.form);
      return;
    }

    if (this.tos.length <= 0 || this.tos.length > 5) {
      this.showError = true;
      return;
    }

    if (!this.fromMassiveReasign) {
      this.assignRequest.user = localStorage.getItem('username');
      this.assignRequest.email = this.tos.join(', ');

      Swal.fire({
        allowOutsideClick: false,
        icon: 'info',
        text: 'Asignando incidencia...'
      });

      Swal.showLoading();

      this.incidenceService.assignIncidence(this.assignRequest).subscribe(
        resp => {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Se re asignó la incidencia correctamente',
            showConfirmButton: false,
            timer: 1500
          });

          this.dialogRef.close(true);
        }, error => {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Error en la asignación',
            showConfirmButton: false,
            timer: 1500
          });

          this.dialogRef.close(false);
        },
      );
    } else {
      const assignRequest = {
        createdBy: localStorage.getItem('username'),
        emails: this.tos,
        comment: this.assignRequest.comment,
      };

      this.dialogRef.close(assignRequest);
    }
  }
}
