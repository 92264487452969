export enum State {
    REGISTERED = 1,
    PROCESSED,
    COMPLETED,
    ACTIVE,
    INVALIDATED,
    SENT_TO_SUPPLIER,
    PRODUCT_RECEIVED_IN_STORE,
    DELIVERED_TO_CUSTOMER,
    RETURN_REQUEST_SENT,
    PICKING_PRODUCT,
    PRODUCT_UNDER_REVIEW,
    RETURN_ACCEPTED,
    REFUSAL_REJECTED,
    REQUEST_CREATED_R_R,
    ASSIGNED_REQUEST_R_R,
    REQUEST_APPROVED_R_R,
    PICKUP_SCHEDULED_R_R,
    PICKUP_PROGRAM_R_R,
    PICKUP_MADE_R_R,
    RE_ENTERED_PRODUCT_R_R,
    RETURNED_PRODUCT_R_R,
    RETURNED_REFUND_R_R,
    REJECTED_REQUEST_R_R,
    PICKUP_NOT_DONE_R_R,
    PICKUP_RE_SCHEDULED_R_R,
    REQUEST_CLOSED_R_R,
    REQUEST_CREATED_R_CH,
    ASSIGNED_REQUEST_R_CH,
    REQUEST_APPROVED_R_CH,
    PICKUP_SCHEDULED_R_CH,
    PICKUP_PROGRAM_R_CH,
    PICKUP_MADE_R_CH,
    RE_ENTERED_PRODUCT_R_CH,
    REJECTED_REQUEST_R_CH,
    PICKUP_NOT_DONE_R_CH,
    PICKUP_RE_SCHEDULED_R_CH,
    REQUEST_CLOSED_R_CH,
    APPROVED_SERVICE_TECNIC,
    REPAIRED_IN_STORE,
    IN_REVIEW,
    PROGRAMMED_DELIVERY_PENDING_R_R,
    PROGRAMMED_DELIVERY_PENDING_R_CH,
    CLIENT_REPLICA,
    INCIDENCE_RETURNED,
    INACTIVE,
    REJECTED_SERVICE_TECNIC,
    APPROVED_RETURN_PRODUCT,
    APPROVED_REPAIRED_PRODUCT,
    REQUEST_ENTERED_ST_PILOT,
    REQUEST_CREATED_R_R_TVO,
    REQUEST_CREATED_R_CH_TVO,
    ASSIGNED_REQUEST_R_R_TVO,
    REQUEST_PRE_APPROVED_R_R_TVO,
    REJECTED_REQUEST_R_R_TVO,
    PENDING_SERVICE_TECNIC_R_R_TVO,
    SERVICE_TECNIC_PROGRAM_R_R_TVO,
    PICKUP_PROGRAM_R_R_TVO,
    PRODUCT_RETURN_R_R_TVO,
    REQUEST_FINISHED_R_R_TVO,
    ASSIGNED_REQUEST_R_CH_TVO,
    REQUEST_PRE_APPROVED_R_CH_TVO,
    REJECTED_REQUEST_R_CH_TVO,
    PENDING_SERVICE_TECNIC_R_CH_TVO,
    SERVICE_TECNIC_PROGRAM_R_CH_TVO,
    PICKUP_PROGRAM_WITH_STOCK_R_CH_TVO,
    PICKUP_PROGRAM_WITHOUT_STOCK_R_CH_TVO,
    PRODUCT_RETURN_R_CH_TVO,
    REQUEST_FINISHED_R_CH_TVO,
    REQUEST_CLOSED_DONT_ANSWER_CUSTOMER_R_R,
    REQUEST_CLOSED_ABSENT_CUSTOMER_R_R,
    REQUEST_CLOSED_DONT_ANSWER_CUSTOMER_R_CH,
    REQUEST_CLOSED_ABSENT_CUSTOMER_R_CH,
    REQUEST_CHANGE_FINISHED_R_CH_TVO ,
    REQUEST_DEVOLUTION_FINISHED_R_CH_TVO,
    TECHNICAL_SERVICE_PENDING_PICK_UP,
    TECHNICAL_SERVICE_REQUEST_CLOSED,
    CUSTOMER_REQUEST_R_R,
    CUSTOMER_REQUEST_R_CH,
    TEST_SYSTEM_R_R,
    TEST_SYSTEM_R_CH,
    NC_R_R,
    CHANGE_HAND_R_R,
    DERIVATE_S_C_R_R,
    DAMAGE_PIECE_R_R,
    INCOMPLETE_PIECE_R_R,
    RETURNED_REFUND_R_CH,
    NC_R_CH,
    CHANGE_HAND_R_CH,
    DERIVATE_S_C_R_CH,
    DAMAGE_PIECE_R_CH,
    INCOMPLETE_PIECE_R_CH,
    REQUEST_CANCEL_SDC,
    REQUEST_CANCEL_ABSENT_CUSTOMER_SDC,
    REACTIVATED,
}

export enum ClaimStatus {
    REGISTERED = 1,
    EXTENDED,
    IN_REVIEW,
    ATTENDED,
    NOT_ATTENDED,
    INVALIDATED,
    NOT_SHOWED,
}

export enum Reason {
    DISPATCH = 1,
    TECHNICAL_SERVICE_PILOT = 123,
    INSTALLATION_SERVICE = 3,
    ORDER_NOT_DELIVERED_ON_TIME = 62,
    INCOMPLETE_DELIVERY_ORDER = 63,
    SELLERCENTER_RETURNS = 66,
    RETURN_REQUEST = 90,    
    CHANGE_REQUEST = 91,
}

export enum OrderDeliveryMethod {
    HOME_DELIVERY = 1,
    STORE_PICKUP = 2,
    CAR_PICKUP = 3,
    DELIVERY_EXPRESS = 4,
    DIRECT_WAREHOUSE = 5,
}

export enum TracingTab {
    REGISTERED_BY_ME = 1,
    ASSIGNED_TO_ME,
    REGISTERED_BY_TEAM,
    ASSIGNED_TO_TEAM,
    REASSIGNED,
}

export enum Roles {
    ROLE_ADMIN = 1,
    ROLE_USER,
    ROLE_SUPERVISOR,
    ROLE_EXTERNAL_PROVIDER,
    ROLE_SUPERVISOR_LR,
}

export enum ClaimSource {
    PHYSICAL = 1,
    VIRTUAL,
}

export enum AssociateType {
    ORDER = 'ORDER',
    PRODUCT = 'PRODUCT',
}

export enum Format {
    PLAZA_VEA = 4
}

const OrderColumns: Map<string, string> = new Map([
    ['PRIORIZE', 'prioritize'],
    ['CODE', 'codIncidence'],
    ['CLIENT', 'clientName'],
    ['CREATED_ON', 'createDate'],
    ['NDAYS', 'daysPassed'],
    ['RESPONSIBLE', 'responsibles'],
    ['REASON', 'reasonName'],
    ['USER', 'createByFullName'],
    ['STATE', 'stateName'],
    ['RECONTACT', 'recontacts'],
]);

const OrderColumnsClaim: Map<string, string> = new Map([
    ['PRIORIZE', 'prioritize'],
    ['CODE', 'code'],
    ['CLIENT', 'clientFullName'],
    ['CREATED_ON', 'createDate'],
    ['NDAYS', 'daysPassed'],
    ['REASON', 'reasonName'],
    ['SUBREASON', 'subreasonName'],
    ['STATE', 'statusName'],
    ['RECONTACT', 'recontacts'],
    ['COMPANY', 'company'],
]);

const StoreItemsByDeliveryMethod = {
    STORE_PICKUP:       [
        19, // Vivanda Pardo
        61, // Pvea Cortijo
        66, // Pvea Ate
        75, // Pvea Trujillo
        81, // Pvea La Molina
        84, // Pvea Comas
        93, // Vivanda Dos de Mayo
        94, // Vivanda Benavides
        95, // Vivanda Monterrico
        102, // Pvea Chiclayo
        115, // Pvea Huancayo
        116, // Vivanda Javier prado
        117, // Pvea Izaguirre
        120, // Pvea Universitaria
        122, // Pvea Ica
        125, // Pvea SJ Lurigancho
        132, // Pvea El Ejercito
        140, // Vivanda Libertadores
        143, // Pvea Nuevo Chimbote
        145, // Pvea Piura
        159, // Pvea Acho
        172, // Pvea Puno
        223, // Pvea Cusco Arsobizpo
        225, // Pvea Cajamarca
        227, // Pvea San Isidro
        262, // Pvea Talara Mall
        291, // Pvea La Curva
        465, // Pvea Sucre
        723, // Pvea Puruchuco
    ],
    DISPATCH:           [
        19, // Vivanda Pardo
        61, // Pvea Cortijo
        66, // Pvea Ate
        75, // Pvea Trujillo
        81, // Pvea La Molina
        84, // Pvea Comas
        93, // Vivanda Dos de Mayo
        94, // Vivanda Benavides
        95, // Vivanda Monterrico
        102, // Pvea Chiclayo
        115, // Pvea Huancayo
        116, // Vivanda Javier prado
        120, // Pvea Universitaria
        122, // Pvea Ica
        125, // Pvea SJ Lurigancho
        132, // Pvea El Ejercito
        140, // Vivanda Libertadores
        143, // Pvea Nuevo Chimbote
        145, // Pvea Piura
        159, // Pvea Acho_
        106, // Pvea Valle Hermoso
        172, // Pvea Puno
        223, // Pvea Cusco Arsobizpo
        225, // Pvea Cajamarca
        227, // Pvea San Isidro
        291, // Pvea La Curva
        465, // Pvea Sucre
    ],
    DELIVETY_EXPRESS:   [
        19, // Vivanda Pardo
        48, // Pvea Higuereta
        93, // Vivanda Dos de Mayo
        94, // Vivanda Benavides
        95, // Vivanda Monterrico
        116, // Vivanda Javier prado
        140, // Vivanda Libertadores
        195, // Pvea San Borja
        139, // Pvea Alameda Sur
    ],
};

const hiddenStores = {
    hpsa: [
        410, // Servicio Técnico
        603, // Venta Institucional
        641, // Venta Institucional oriente
        701, // CD Lima
        801, // Publicidad
        803, // Servicio Técnico oriente
        805, // Sellercenter
    ],
};

const externalStores = {
    hpsa: [
        1, // Promart.pe
        2, // Tiendas físicas
    ],
};

const externalReasons = {
    hpsa: [
        124, // Servicio de armado/instalación
    ],
};

const externalHideSubReasons = {
    hpsa: [
        921, // Reporte de servicio no realizado
        922, // Coordinar visita de inspección
        920, // Reporte de pieza faltante
        919, // Producto dañado durante el armado/instalación  
    ],
};

export const commons = {
    states: State,
    claimStatus: ClaimStatus,
    reasons: Reason,
    formats: Format,
    deliveryMethods: OrderDeliveryMethod,
    tracingTabs: TracingTab,
    storesDeliveryMethod: StoreItemsByDeliveryMethod,
    firebase: {
        apiKey: 'AIzaSyC9KhsNN_ZkbIK77P7ffp5GrKhYTNQKuEk',
        authDomain: 'customer-care-8c04b.firebaseapp.com',
        databaseURL: 'https://customer-care-8c04b.firebaseio.com',
        projectId: 'customer-care-8c04b',
        storageBucket: 'customer-care-8c04b.appspot.com',
        messagingSenderId: '24287623378',
        appId: '1:24287623378:web:5a8d45509b71a025dcd50b',
        measurementId: 'G-L543SBRER4',
    },
    internalRoles: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_SUPERVISOR_LR'],
    externalRoles: ['ROLE_EXTERNAL_PROVIDER'],
    adminRoles: ['ROLE_ADMIN'],
    claimsRoles: ['ROLE_SUPERVISOR_LR'],
    orderColumns: OrderColumns,
    orderColumnsClaim: OrderColumnsClaim,
    itemsPerPage: [10, 15, 30, 50, 100],
    systemCode: 'TRACKING',
    hiddenStores,
    sellerCenterName: 'Sellercenter',
    roles: Roles,
    claimSource: ClaimSource,
    productExcludeCodes: ['0', '25968', '1586390'],
    externalStores,
    externalReasons,
    externalHideSubReasons,
    associateType: AssociateType,
    promartPeClaimID: 2,
    changesAndReturnReasons: [Reason.CHANGE_REQUEST, Reason.RETURN_REQUEST],
};
