import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_CLAIMS } from 'src/app/app.restangular.config';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { ClaimService, UtilService } from 'src/app/services/service.index';
import * as Mustache from 'mustache';
import moment from 'moment';
import Panzoom from '@panzoom/panzoom'
import { distinctUntilChanged } from 'rxjs/operators';
import Swal from 'sweetalert2';
// import { jsPDF } from "jspdf"; 
// import html2canvas from 'html2canvas';
import { Overlay } from '@angular/cdk/overlay';
import { ModalClaimCommentComponent } from '../modal-claim-comment/modal-claim-comment.component';
import { ModalClaimAssignComponent } from '../modal-claim-assign/modal-claim-assign.component';

@Component({
  selector: 'app-modal-claim-template-editor',
  templateUrl: './modal-claim-template-editor.component.html',
  styleUrls: ['./modal-claim-template-editor.component.scss'],
})
export class ModalClaimTemplateEditorComponent implements OnInit {
  claim: any;
  user: any;
  company: string = '';
  claimsBasePath: any;
  parametersBasePath: any;
  saving: boolean = false;
  loading: boolean = true;
  activeTextArea: boolean = false;

  responseTemplate;
  templateContent;
  unsavedChanges: boolean = false;
  currentTextControl = new FormControl();
  currentElement: Element;
  zoomTemplateViewer;

  currentScale: number = 1;
  maxScale: number = 5;

  // doc: jsPDF;

  isLetterReviewer: boolean = false;
  editable: boolean = true;

  @ViewChild("pageContent", { read: ElementRef, static: false }) pageContent: ElementRef;
  @ViewChild("pageContentClone", { read: ElementRef, static: false }) pageContentClone: ElementRef;

  constructor(
    private authService: AuthService,
    private claimService: ClaimService,
    public utilService: UtilService,
    public logger: NGXLogger,
    @Inject(RESTANGULAR_CLAIMS) public restangularBase,
    public dialogRef: MatDialogRef<ModalClaimTemplateEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private overlay: Overlay,
  ) {
    moment.locale('es');
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user: any) => {
      this.user = user;
      this.claim = this.data.claim;
      this.company = this.claim.company;
      this.isLetterReviewer = this.claim.letterReviewer ? (this.claim.letterReviewer.toLowerCase() === this.user.email.toLowerCase()): false;
      this.claimsBasePath = this.restangularBase.one('', this.company).all('claims');
      this.loadTemplate();

      const elem = document.getElementById('template-viewer')
      this.zoomTemplateViewer = Panzoom(elem, {
        minScale: 0.7,
        startScale: 0.7,
        origin: '1.5% 0%',
        maxScale: this.maxScale,
        panOnlyWhenZoomed: true,
      });
    });

    this.currentTextControl.valueChanges
    .pipe(
      distinctUntilChanged(),
    )
    .subscribe(text => {
      if (this.currentElement) {
        if (this.activeTextArea) {
          this.currentElement.innerHTML = text.replace(/\n/g, '<br>');
          this.unsavedChanges = true;
        }
        
        const element: Element = this.pageContent.nativeElement;
        const cloneElement: Element = this.pageContentClone.nativeElement;
        cloneElement.innerHTML = element.innerHTML;
      }
    });
  }

  loadTemplate() {
    this.claimsBasePath.one('', this.claim.id).one('response-template').get().subscribe(
      responseTemplate => {
        if (responseTemplate.id) {
          this.responseTemplate = responseTemplate;
          this.templateContent = this.responseTemplate.template;
          this.pageContent.nativeElement.innerHTML = this.templateContent;
          this.editable = !this.responseTemplate.validated;
          this.clearEditArea();
          this.logger.debug('finished load html template');
          this.cloneTemplate();
        } else {
          this.getDefaultTemplate();
        }
      },
      error => this.getDefaultTemplate(),
    );
  }

  getDefaultTemplate() {
    this.claimService.getLRResponseTemplate(this.company.toLowerCase())
    .subscribe(
      response => {
        const jsonDemo = {
          now: moment().format("DD [de] MMMM [de] YYYY"),
          claimCode: this.claim.code,
          claimCreatedDay: moment(this.claim.createdAt).format("DD"),
          claimCreatedMonth: moment(this.claim.createdAt).format("MMMM"),
          claimCreatedYear: moment(this.claim.createdAt).format("YYYY"),
          clientName: this.claim.client ? this.claim.client.name : '',
          clientShortName: this.claim.client ? this.claim.client.name.trim().split(' ')[0] : '',
          clientAddress: this.claim.client ? this.claim.client.address : '',
          hasClientName: this.claim.client ? true : false,
          hasClientAddress: this.claim.client && this.claim.client.address,
          storeName: this.claim.physicalStoreName ? (this.utilService.isHpsa(this.company) ? this.claim.physicalStoreName.toUpperCase() : this.claim.physicalStoreName) : '',
        };

        const output = Mustache.render(response, jsonDemo);
        this.templateContent = output;
        this.pageContent.nativeElement.innerHTML = this.templateContent;
        this.clearEditArea();
        this.cloneTemplate();
      },
      error => this.logger.error(error),
      () => this.logger.debug('finished load html template'),
    );
  }

  cloneTemplate() {
    const cloneElement: Element = this.pageContentClone.nativeElement;
    cloneElement.innerHTML = this.templateContent;
  }

  clearEditArea() {
    this.activeTextArea = false;
    this.currentTextControl.setValue('');

    if (this.currentElement && this.currentElement.classList.contains('selected')) {
      this.currentElement.classList.remove('selected');
      this.currentElement = null;
    }
  }

  setEditableContent(event: Event) {
    if (this.currentElement && this.currentElement.classList.contains('selected')) {
      this.currentElement.classList.remove('selected');
    }

    this.currentElement = (event.target ? event.target : event.srcElement) as Element;
    this.logger.debug('currentElement', this.currentElement, this.currentElement.classList);

		if (this.currentElement.classList.contains('editable') && this.editable) {
      const textValue = this.currentElement.innerHTML
      .split('\n')
      .map(item => item = item.trim())
      .filter(item => item.length > 0)
      .join('\n')
      .replace(/<br\s?\/?>/g, '\n')
      ;

      this.currentTextControl.setValue(textValue);
      this.currentElement.classList.add('selected');
      this.activeTextArea = true;
		} else {
      this.activeTextArea = false;
      this.currentTextControl.setValue('');
    }
  }

  zoomIn() {
    this.zoomTemplateViewer.zoomIn(1, {animated: true});
    this.currentScale++;
  }

  zoomOut() {
    this.zoomTemplateViewer.zoomOut(1, {animated: true});
    this.currentScale--;

    if (this.currentScale === 1) {
      this.zoomTemplateViewer.reset({animated: true});
    }
  }

  saveResponseTemplate() {
    const template: HTMLElement = this.pageContent.nativeElement;
    const nodes: NodeList = template.querySelectorAll('p.editable, span.editable');
    const editableValues: any[] = [];
    let zoneObj;

    nodes.forEach((node, index) => {
      zoneObj = {};
      zoneObj[`zone_${index+1}`] = (node as any).innerHTML.replace(/<br\s?\/?>/g, '\n');
      editableValues.push(JSON.parse(JSON.stringify(zoneObj)));
    });

    this.logger.debug('editableZones', editableValues);

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando plantilla',
    });

    Swal.showLoading();
    this.clearEditArea();

    if (!this.responseTemplate || !this.responseTemplate.id) {
      this.responseTemplate = {};
    }

    this.responseTemplate.template = `<div class="default-content">${this.pageContent.nativeElement.firstElementChild.innerHTML}</div>`;
    this.responseTemplate.editableValues = editableValues;
    this.responseTemplate.createdBy = this.user.username;

    this.claimsBasePath.one('', this.claim.id).one('response-template').customPOST(this.responseTemplate).subscribe(
      response => {
        this.logger.debug('response', response);
        this.responseTemplate = response;
        this.unsavedChanges = false;

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Se guardó la plantilla correctamente',
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 3000,
        });

        this.loadTemplate();
      },
      error => {
        this.logger.error(error);

        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Lo sentimos',
          text: 'No se pudo guardar la plantilla',
          showConfirmButton: false,
          timer: 1500,
        });
      },
    );
  }

  cancelEditing() {
    Swal.fire({
      title: 'Todos los cambios no guardados se perderán. ¿Está seguro de cancelar la edición de la carta?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this.loadTemplate();
        this.unsavedChanges = false;
      }
    });
  }

  /*
  generatePDF() {
    this.logger.debug('Generating PDF file');
    const template: HTMLElement = this.pageContentClone.nativeElement;
    const nodes: NodeList = template.querySelectorAll('p, span');

    nodes.forEach(node => {
      (node as any).classList.remove('editable');
      (node as any).classList.remove('selected');
    });

    this.doc = new jsPDF('p', 'px', 'a4');
    this.fontService.addFontSeravek(this.doc);

    // Test 1: Transform HTML to image first, then generate PDF (Problem paginating)
    html2canvas(this.pageContentClone.nativeElement, {
      backgroundColor: 'white',
      scale: 1,
    })
    .then((canvas) => {
      this.logger.debug('canvas', canvas);
      const img = canvas.toDataURL('image/PNG', 2.0);
      this.logger.debug('img', img);

      const bufferX = 15;
      const bufferY = 15;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() - 2 * bufferX;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(img, 'PNG', bufferX, bufferY, pdfWidth, pdfHeight, undefined, 'FAST');
      return doc;
    }).then((result) => {
      result.save('sample.pdf');
    })
    ;

    // Test 2: Generate PDF from HTML (Problem attaching fonts)
    this.doc.html(template, {
      callback: function(doc) {
        doc.save('sample.pdf');
      },
      image: {
        type: 'png',
        quality: 2.5,
      },
      html2canvas: {
        backgroundColor: 'white',
        scale: 0.5,
      },
      x: 20,
      y: 20,
      fontFaces: [
        {
          family: 'Seravek',
          style: 'normal',
          weight: 'normal',
          src: [
            {
              format: 'truetype',
              url: 'assets/fonts/Seravek.ttf',
            }
          ],
        }
      ],
    }).then(
      () => this.logger.debug('lala'),
    );
  }
  */

  confirmAndSend() {
    Swal.fire({
      title: '¿Estás seguro de enviar la carta de respuesta?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Enviando carta',
        });
    
        Swal.showLoading();

        const data = {
          createdBy: this.user.username,
        };
        this.claimsBasePath.one('', this.claim.id).one('response-template').one('confirm-send').customPOST(data).subscribe(
          response => {
            this.logger.debug('response', response);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Se envió la carta correctamente',
              showConfirmButton: true,
              confirmButtonColor: '#20a8d8',
              confirmButtonText: 'Ok',
              timer: 3000,
            });

            this.dialogRef.close(true);
          },
          error => {
            this.logger.error(error);

            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Lo sentimos',
              text: 'No se pudo enviar la carta',
              showConfirmButton: false,
              timer: 1500,
            });
          }
        );
      } else {
        this.saveResponseTemplate();
      }
    });
  }

  comment() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalClaimCommentComponent, {
      width: '500px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        claim: this.claim,
        fromResponseTemplate: true,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.logger.debug('Comment added successful');
      }
    });
  }

  validate() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalClaimAssignComponent, {
      width: '740px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        claim: this.claim,
        fromResponseTemplate: true,
        fromMassiveReasign: true, // For return assigned object
      }
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.logger.debug('responsible', dialogResponse);
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Asignando validación...'
        });
    
        Swal.showLoading();

        this.claimsBasePath.one('', this.claim.id).all('assign').all('response-template').customPOST(dialogResponse).subscribe(
          response => {
            this.logger.debug('assign', response);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: `Se asignó con éxito la validación del reclamo ${this.claim.code}`,
              showConfirmButton: true,
              confirmButtonColor: '#20a8d8',
              confirmButtonText: 'Ok',
              timer: 3000,
            });
            
            this.claim.letterReviewer = dialogResponse.emails[0];
            this.isLetterReviewer = this.claim.letterReviewer ? (this.claim.letterReviewer.toLowerCase() === this.user.email.toLowerCase()): false;
          },
          error => {
            this.logger.error(error);
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Lo sentimos',
              text: 'No se pudo asignar la validación del reclamo',
              showConfirmButton: false,
              timer: 1500,
            });
          },
        );
      }
    });
  }
}
