import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import Swal from "sweetalert2";
import { environment } from 'src/environments/environment';
import { UtilService, IncidenceService } from 'src/app/services/service.index';
import { RESTANGULAR_PARAMETER } from 'src/app/app.restangular.config';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbDateStructAdapter } from '@ng-bootstrap/ng-bootstrap/datepicker/adapters/ngb-date-adapter';
const I18N_VALUES = {
    'es': {
      weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
      months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    },
  };
  
  @Injectable()
  export class I18n {
    language = 'es';
  }
  
  @Injectable()
  export class CustomDatepickerI18n extends NgbDatepickerI18n {
  
    constructor(private i18n: I18n) {
      super();
    }
  
    getWeekdayShortName(weekday: number): string {
      return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
      return I18N_VALUES[this.i18n.language].months[month - 1];
    }
    getMonthFullName(month: number): string {
      return this.getMonthShortName(month);
    }
  
    getDayAriaLabel(date: NgbDateStruct): string {
      return `${date.day}-${date.month}-${date.year}`;
    }
  }
  
  @Injectable()
  export class CustomDateParserFormatter extends NgbDateParserFormatter {
  
    readonly DELIMITER = '/';
  
    parse(value: string): NgbDateStruct | null {
      if (value) {
        const date = value.split(this.DELIMITER);
        return {
          day : parseInt(date[0], 10),
          month : parseInt(date[1], 10),
          year : parseInt(date[2], 10)
        };
      }
      return null;
    }
  
    format(date: NgbDateStruct | null): string {
      return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
    }
  }

@Component({
    selector: 'app-modal-incidence',
    templateUrl: './modal-program-delivery.component.html',
    styleUrls: ['./modal-program-delivery.component.scss'],
    providers: [
      NGXLogger,
      I18n,
      {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
      {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
    ],
  }) 
export class ModalProgramDeliveryComponent implements OnInit {
  
    trackinIncidenceState: any;
    trackingIncidence: any;
    reasons: any[];
    user: any;
    states = environment.commons.states;
    codeState : any;
  
    basePath: any;
    textAreaMinRows: number = environment.textAreaMinRows;
    textAreaMaxRows: number = environment.textAreaMaxRows;
   // reasonsCommon = environment.commons.reasons;
    company;
    registerDateObj: NgbDate | null;
    minDate: NgbDate | null;

    constructor(
      public incidenciaService: IncidenceService,
      @Inject(RESTANGULAR_PARAMETER) public restangularBase,
      public dialogRef: MatDialogRef<ModalProgramDeliveryComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public utilService: UtilService,
      public logger: NGXLogger,
      private calendar: NgbCalendar,
      public formatter: NgbDateParserFormatter,
    ) {
      this.basePath = this.restangularBase.all('parameter').all('parameters').all('v1');
    }
  
    ngOnInit() {
      this.company = localStorage.getItem('company');
      this.trackinIncidenceState = {};
      this.trackingIncidence = this.data.trackingIncidence;
      this.codeState = this.data.codeState;
      this.user = localStorage.getItem('username');
      this.logger.debug('trackingIncidence', this.trackingIncidence);
  
    /*  this.basePath.all('state-reasons').getList().subscribe(
        response => {
          this.logger.debug('response', response);
          this.reasons = response;
        },
        error => this.logger.error(error),
      );*/
      this.registerDateObj = this.calendar.getToday();
      this.minDate = this.calendar.getToday();
    }
  
    save() {
      this.trackinIncidenceState.trackingIncidence = { id: this.trackingIncidence.id };
      this.trackinIncidenceState.state = { id: this.codeState };
      this.trackinIncidenceState.username = this.user;
      this.trackinIncidenceState.comment = "Fecha de recojo programada para el: "+this.registerDateObj.day+"/"+this.registerDateObj.month+"/"+this.registerDateObj.year;
      this.logger.debug('state', this.trackinIncidenceState);
      Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Cargando...'
        });
      Swal.showLoading();
      this.incidenciaService.changeState(this.trackinIncidenceState).subscribe(
        response => {
          Swal.close();
          this.logger.debug('response', response);
          Swal.fire({
            position: 'center',
            icon: 'info',
            title: `El estado de la incidencia se actualizo correctamente`,
            showConfirmButton: false,
            timer: 1500
          });
          this.dialogRef.close(true);
        },
        error => {
          this.logger.error(error);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `No se pudo actualizar el estado de la incidencia.`,
            showConfirmButton: false,
            timer: 1500
          });
          this.dialogRef.close();
        });
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        this.logger.debug('validateInput', currentValue, input);
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : null;
      }
}