import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { UtilService, IncidenceService } from 'src/app/services/service.index';
import { RESTANGULAR_PARAMETER } from 'src/app/app.restangular.config';
import { environment } from 'src/environments/environment';
import { CommentModels } from 'src/app/models/Comment.models';
import Swal from 'sweetalert2';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-modal-reason',
    templateUrl: './modal-reason.component.html',
    styleUrls: ['./modal-reason.component.scss'],
  })
  export class ModalReasonComponent implements OnInit {
    subReasons: any[];
    subReason : any;
    comment : any;
    json: any;
  
    basePath: any;
    textAreaMinRows: number = environment.textAreaMinRows;
    textAreaMaxRows: number = environment.textAreaMaxRows;
    reasonsCommon = environment.commons.reasons;
    company;
    constructor(
        public incidenceService: IncidenceService,
        @Inject(RESTANGULAR_PARAMETER) public restangularBase,
        public dialogRef: MatDialogRef<ModalReasonComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public utilService: UtilService,
        public logger: NGXLogger,
      ) {
        this.basePath = this.restangularBase.all('parameter').all('parameters').all('v1');
      }
    
      ngOnInit() {
        this.comment = new CommentModels();
        this.company = this.data.company;

        if (this.data.newReason == 90) {
           this.data.newReasonName = 'Solicitud de Devolución';
        } else if(this.data.newReason == 91) {
           this.data.newReasonName = 'Solicitud de Cambio';
        }
    
        this.basePath.all('reasons').all('search').all('by-reason').all(this.data.newReason).all(this.company.toUpperCase()).getList().subscribe(
          response => {
            this.logger.debug('response', response);
            this.subReasons = response;
          },
          error => this.logger.error(error),
        );
      }

      save(form: NgForm) {
        if (form.invalid) {
          return;
        }
        this.comment.idIncidence = this.data.incidence.id;
        this.comment.user = localStorage.getItem('username');
        this.json = 
            {
                "idIncidence" : this.data.incidence.idIncidence,
                "idReason" : this.data.newReason ,
                "idSubReason" : this.subReason.id
            }
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Guardando comentario'
        });
    
        Swal.showLoading();
        this.incidenceService.newComment(this.comment).subscribe(
          detail => {
              this.incidenceService.putIncidenceByReason(this.json).subscribe(
                  success=>{
                    this.showCommentSuccessMessage();
                  }, error=>{
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Error al guardar comentario',
                        showConfirmButton: false,
                        timer: 1500
                      });
              
                      this.logger.error(error);
                      this.dialogRef.close();
                    }

              )
          },
          error => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Error al guardar comentario',
              showConfirmButton: false,
              timer: 1500
            });
    
            this.logger.error(error);
            this.dialogRef.close();
        });
      }
    
      showCommentSuccessMessage() {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Se guardó el comentario correctamente',
          showConfirmButton: false,
          timer: 1500
        });
    
        this.dialogRef.close(true);
      }

      closedPopUp(){
        this.dialogRef.close(true);
      }
  

  }

