import { environment } from "src/environments/environment";
import { InjectionToken } from "@angular/core";
import { Restangular } from "ngx-restangular";
import { Subject } from "rxjs";

export function RestangularConfigFactory(RestangularProvider) {
    RestangularProvider.setBaseUrl(environment.API_INCIDENCE);

    RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
        return {
            params: Object.assign({}, params),
            headers: Object.assign({}, headers),
        };
    });

    RestangularProvider.addResponseInterceptor((data, operation, what, url, response) => {
        const finalData = data && data.data && typeof data.data === 'object' ? data.data : data;
        if (operation === 'getList' && (!finalData || !(finalData instanceof Array))) {
            return [];
        }
        return finalData;
    });

    RestangularProvider.addErrorInterceptor((response, subject: Subject<any>, responseHandler) => {
        const handledErrorResponse = 'handledErrorResponse';
        subject.error(handledErrorResponse);
        return false;
    });

    RestangularProvider.setRestangularFields({
        selfLink: '_links.self.href',
    });
}

// Restangular service for incidence api
export const RESTANGULAR_INCIDENCE = new InjectionToken<any>('RestangularIncidence');
export function RestangularIncidenceFactory(restangular: Restangular) {
    return restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.API_INCIDENCE);
    });
}

// Restangular service for client api
export const RESTANGULAR_CLIENT = new InjectionToken<any>('RestangularClient');
export function RestangularClientFactory(restangular: Restangular) {
    return restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.API_CLIENT);
    });
}

// Restangular service for parameters api
export const RESTANGULAR_PARAMETER = new InjectionToken<any>('RestangularParameter');
export function RestangularParameterFactory(restangular: Restangular) {
    return restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.API_PARAMETERS);
    });
}

// Restangular service for auth api
export const RESTANGULAR_USER = new InjectionToken<any>('RestangularUser');
export function RestangularUserFactory(restangular: Restangular) {
    return restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.API_LOGIN);
    });
}

// Restangular service for fcm api
export const RESTANGULAR_FCM = new InjectionToken<any>('RestangularFCM');
export function RestangularFCMFactory(restangular: Restangular) {
    return restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.API_FCM);
    });
}

// Restangular service for reports api
export const RESTANGULAR_REPORTS = new InjectionToken<any>('RestangularReports');
export function RestangularReportsFactory(restangular: Restangular) {
    return restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.API_REPORTS);
    });
}

// Restangular service for claims api
export const RESTANGULAR_CLAIMS = new InjectionToken<any>('RestangularClaims');
export function RestangularClaimsFactory(restangular: Restangular) {
    return restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.API_CLAIMS);
    });
}

// Restangular service for services api
export const RESTANGULAR_SERVICES = new InjectionToken<any>('RestangularServices');
export function RestangularServicesFactory(restangular: Restangular) {
    return restangular.withConfig((RestangularConfigurer) => {
        RestangularConfigurer.setBaseUrl(environment.API_SERVICES);
    });
}
