import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioModel } from "../models/usuario.model";
import { AuthService } from "../services/auth/auth-service.service";
import { NGXLogger } from 'ngx-logger';
import { NotificationService } from '../services/fcm/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit {
  intentos: number = 0;
  error: any = {};

  usuario: UsuarioModel = new UsuarioModel();
  userTemp = '';

  constructor(
    private auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    public logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    if (this.auth.isAuthenticated()) {
      this.router.navigate(['pages']);
    } else {
      this.notificationService.signOut();

      this.activatedRoute.params.subscribe(params => this.error.forbidden = params.forbidden || false);
    }
  }

  login(form: NgForm) {
    this.logger.debug('Attempts', this.intentos);

    if (form.invalid) {
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor...'
    });

    Swal.showLoading();

    this.auth.login(this.usuario).subscribe(
      resp => {
        // Sesion iniciada
        this.auth.getUser().subscribe(
          user => {
            if (user.roles.includes('ROLE_EXTERNAL_PROVIDER')) {
              this.router.navigate(['pages/tracing']);
            } else if (user.roles.includes('ROLE_SUPERVISOR_LR')) {
              this.router.navigate(['pages/claims']);
            } else {
              this.router.navigate(['pages/search']);
            }
          },
          error => this.logger.error(error),
          () => Swal.close(),
        );
      },
      (err) => {
        if (err.error.message === 'Bad credentials') {
          if (this.userTemp === this.usuario.username) {
            this.intentos = this.intentos + 1;
          } else {
            this.intentos = 1;
            this.userTemp = this.usuario.username;
          }

          if (this.intentos === 3) {
            this.usuario.estado = "0";
            this.auth.lockUser(this.usuario).subscribe(
              response => this.alertUserLock(),
              error => this.logger.error(error),
            );
          } else {
            this.alertUserIncorrect();
          }
        }

        if (err.error.message === 'Error: Usuario bloqueado!') {
          this.alertUserLock();
        } else {
          this.alertUserIncorrect();
        }
      },
    );
  }

  alertUserIncorrect() {
    Swal.fire({
      icon: 'error',
      title: 'Error al autenticar',
      text: 'Usuario y/o contraseña incorrectos. Tienes tres intentos para ingresar al sistema, luego de esto tu usuario será bloqueado',
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
    });
  }

  alertUserLock() {
    Swal.fire({
      icon: 'error',
      title: 'Usuario bloqueado',
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
    });
  }

}
