import { Injectable } from "@angular/core";

import { IncidenceModels } from "../../models/Incidence.models";
import { OrderResponseModels } from "../../models/OrderResponse.models";
import { OrderTracking } from "src/app/models/order-tracking.model";

@Injectable()
export class SearchIncidenceService {

  incidenceModels: IncidenceModels = new IncidenceModels();
  incidences: any[] = [];
  listOrdenes: any[] = [];
  listMotivos: any[] = [];
  listSubMotivos: any[] = [];
  listOrdenesDetalle: OrderResponseModels[] = [];
  reason: any;
  subReason: any;
  public oculto: string = '';
  client: any;
  ordersTracking: OrderTracking[] = [];

  constructor() {
    this.listMotivos = [];
  }

  clearLists() {
    this.listOrdenes = [];
    this.listOrdenesDetalle = [];
    this.incidences = [];
    this.ordersTracking = [];
    this.client = null;
  }

}
