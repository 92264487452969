import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import { RESTANGULAR_CLAIMS, RESTANGULAR_USER } from 'src/app/app.restangular.config';
import { AuthService } from 'src/app/services/service.index';
import { UtilService } from 'src/app/services/util/util.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-claim-assign',
  templateUrl: './modal-claim-assign.component.html',
  styleUrls: ['./modal-claim-assign.component.scss']
})
export class ModalClaimAssignComponent implements OnInit {
  assignRequest: any = {};
  user: any;
  claim: any;
  fromMassiveReasign: boolean = false;
  fromResponseTemplate: boolean = false;
  showError = false;
  company = '';
  basePath: any;
  userTypeahead: any;
  searching: boolean = false;
  searchFailed: boolean = false;
  tos: any[] = [];

  maxResponsibles: number = 5;
  textAreaMinRows: number = +9;
  textAreaMaxRows: number = +9;

  claimsBasePath: any;
  superLRRol: number = environment.commons.roles.ROLE_SUPERVISOR_LR;

  searchToCtrl = new FormControl();
  searchToLoading: boolean = false;
  filteredUsers: any[];

  constructor(
    public dialogRef: MatDialogRef<ModalClaimAssignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RESTANGULAR_USER) public restangularBase,
    @Inject(RESTANGULAR_CLAIMS) public restangularClaimBase,
    private authService: AuthService,
    private utilService: UtilService,
    public logger: NGXLogger,
  ) {
    this.basePath = this.restangularBase.all('auth').all('v1');
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user: any) => {
      this.user = user;
      this.claim = this.data.claim;
      this.company = this.claim ? this.claim.company : this.user.company;
      this.fromMassiveReasign = this.data.fromMassiveReasign || false;
      this.fromResponseTemplate = this.data.fromResponseTemplate || false;
      this.maxResponsibles = this.fromResponseTemplate ? 1 : this.maxResponsibles;
      this.claimsBasePath = this.restangularClaimBase.one('', this.company).all('claims');
      this.searchToCtrl.setValidators([Validators.required]);
    });

    this.searchToCtrl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.filteredUsers = [];
          this.searchToLoading = true;
        }),
        switchMap((keyword: string) => !keyword || (keyword && keyword.length < 3) ? [] :
          this.basePath.all('users').all('search').getList({keyword, rol: this.superLRRol, company: this.fromResponseTemplate ? this.company : ''})
          .pipe(
            finalize(() => this.searchToLoading = false),
          ),
        ),
      )
      .subscribe((data: any[]) => {
        this.filteredUsers = data.length > 0 ? data : [];
      });
  }

  displayNamePropertyFn(item: any): string {
    return item && item.username ? item.username : '';
  }

  selectUser($event: MatAutocompleteSelectedEvent) {
    this.logger.debug('selectUser', $event);

    if ($event.option.value) {
      let user: any = $event.option.value;

      if (user.email) {
        const findTo = this.tos.find(item => {
          return item === user.email.trim();
        });
  
        if (!findTo && user && user.email && user.email.trim().length > 0) {
          this.tos.push(user.email.trim());
        }
      }
    }

    this.resetTxtTo();
  }

  resetTxtTo() {
    this.searchToCtrl.setValue('');
    this.filteredUsers = [];
    this.searchToCtrl.setValidators(this.tos.length > 0 ? [Validators.nullValidator] : [Validators.required]);
    this.searchToCtrl.updateValueAndValidity();
  }

  dropItemTo(index: any) {
    this.tos.splice(index, 1);
    this.resetTxtTo();
  }

  assignResponsible(form: NgForm) {
    if (form.invalid) {
      this.utilService.markFormGroupTouched(form.form);
      return;
    }

    if (this.tos.length <= 0 || this.tos.length > this.maxResponsibles) {
      this.showError = true;
      return;
    }

    this.assignRequest.emails = this.tos;
    this.assignRequest.createdBy = this.user.username;

    if (!this.fromMassiveReasign) {
      Swal.fire({
        allowOutsideClick: false,
        icon: 'info',
        text: 'Asignando reclamo...'
      });
  
      Swal.showLoading();
  
      this.claimsBasePath.one('', this.claim.id).all('assign').customPUT(this.assignRequest).subscribe(
        response => {
          this.logger.debug('assign', response);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: `Se re asignó con éxito el reclamo ${this.claim.code}`,
            showConfirmButton: true,
            confirmButtonColor: '#20a8d8',
            confirmButtonText: 'Ok',
            timer: 3000,
          });
          this.dialogRef.close(true);
        },
        error => {
          this.logger.error(error);
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Lo sentimos',
            text: 'No se pudo re asignar el reclamo',
            showConfirmButton: false,
            timer: 1500,
          });
  
          this.dialogRef.close(false);
        },
      );
    } else {
      this.dialogRef.close(this.assignRequest);
    }
  }
}
