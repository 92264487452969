import { Overlay } from '@angular/cdk/overlay';
import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_CLAIMS } from 'src/app/app.restangular.config';
import { FileObject } from 'src/app/models/file-object.model';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { AppendFilesComponent } from 'src/app/shared/append-files-module/append-files.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-claim-comment',
  templateUrl: './modal-claim-comment.component.html',
  styleUrls: ['./modal-claim-comment.component.scss']
})
export class ModalClaimCommentComponent implements OnInit {
  user: any;
  claim: any;
  fromResponseTemplate: boolean = false;
  company = '';
  comment: any = {};
  fileList: FileObject[];
  timeNow: Date;

  claimsBasePath: any;

  constructor(
    public dialogRef: MatDialogRef<ModalClaimCommentComponent>,
    public dialog: MatDialog,
    private overlay: Overlay,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    @Inject(RESTANGULAR_CLAIMS) public restangularClaimBase,
    public logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user: any) => {
      this.user = user;
      this.claim = this.data.claim;
      this.fromResponseTemplate = this.data.fromResponseTemplate || false;
      this.company = this.claim.company;
      this.timeNow = new Date();
      this.claimsBasePath = this.restangularClaimBase.one('', this.company).all('claims');
    });
  }

  openAppendFileDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(AppendFilesComponent, {
      width: '500px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        isFromClaims: true,
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      this.logger.debug('inside dialog response', res);
      if (res) {
        this.fileList = res;
      }
    });
  }

  saveComment(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.comment.createdBy = this.user.username;

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando comentario'
    });

    Swal.showLoading();

    const request = this.fromResponseTemplate ?
      this.claimsBasePath.one('', this.claim.id).all('comment').all('response-template') :
      this.claimsBasePath.one('', this.claim.id).all('comment');

    request.post(this.comment).subscribe(
      activity => {
        if (this.fileList && this.fileList.length > 0) {
          Swal.fire({
            allowOutsideClick: false,
            icon: 'info',
            text: 'Subiendo archivos'
          });

          Swal.showLoading();

          let document: any;
          let formData: FormData;
          let count = 0;

          this.fileList.forEach((item: FileObject, index) => {
            if (item.file.isFile) {
              const fileEntry = item.file as FileSystemFileEntry;

              fileEntry.file((file: File) => {
                document = {};
                document.name = item.name;
                document.modifiedDate = item.lastModifiedDate;
                document.contentType = item.type;
                document.size = item.size;

                // Prepare formdata to upload
                formData = new FormData();
                formData.append('document', new Blob([JSON.stringify(document)], {type: 'application/json'}));
                formData.append('file', file);

                const url = `${environment.API_CLAIMS}/${this.company}/claims/${this.claim.id}/activity/${activity.id}/attachment`;
                const request = new Request(url, {
                  method: 'POST',
                  mode: 'cors',
                  body: formData,
                });

                // Upload to server
                (async () => {
                  const response = await fetch(request);
                  const json = await response.json();
                  this.logger.debug('Uploaded data', json);
                  count++;

                  if (count === this.fileList.length) {
                    this.showCommentSuccessMessage();
                  }
                })();
              });
            }
          });
        } else {
          this.showCommentSuccessMessage();
        }
      },
      error => {
        this.logger.error(error);
        this.dialogRef.close();

        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Lo sentimos',
          text: 'No se pudo guardar el comentario',
          showConfirmButton: false,
          timer: 1500,
        });
    });
  }

  showCommentSuccessMessage() {
    this.dialogRef.close(true);

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Se guardó el comentario correctamente',
      showConfirmButton: true,
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
      timer: 3000,
    });
  }
}
