
export class OstModel {
    id: number;
    count:number;
    storeStockFlag: boolean;
    customerReturnFlag: boolean;
    client: ClientModelOst;
    company: CompanyModelOst;
    product: ProductModelOst;
}


export class CompanyModelOst {
    companyCode: string;
    name: string;
    ruc: string;
    storeAddress: string;
    storeName: string;
    storeCode: string;
    storeReference: string;
    contactName: string;
    contactPhone: string;
    contactPhoneSecond: string;
    contactEmail: string;
    contactEmails: string[];
    openingHours: string;
    pickupAdress: string;
    deliveryArea: string;
}

export class ClientModelOst {
    name: string;
    documentType: string;
    documentNumber: string;
    phone: string;
    email: string;
    address: string;
    department: string;
    province: string;
    district: string;
}

export class ProductModelOst {
    name: string;
    brand: string;
    onDisplay: boolean;
    claimDate: string;
    shopDate: string;
    claimNumber: string;
    mtm: string;
    ean: string;
    imei: string;
    ticket: string;
    quantity: number;
    model: string;
    serie: string;
    physicalDamage: string;
    descriptionFailure: string;
    descriptionAccesoriesDamage: string;
}