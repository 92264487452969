import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbCalendar, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { Restangular } from 'ngx-restangular';
import { RESTANGULAR_CLAIMS } from 'src/app/app.restangular.config';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { ClaimService } from 'src/app/services/claims/claim.service';
import { UtilService } from 'src/app/services/util/util.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

const I18N_VALUES = {
  'es': {
    weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  },
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-modal-claim-status-confirm',
  templateUrl: './modal-claim-status-confirm.component.html',
  styleUrls: ['./modal-claim-status-confirm.component.scss'],
  providers: [
    NGXLogger,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class ModalClaimStatusConfirmComponent implements OnInit {
  claimStatus: any;
  claim: any;
  reasons: any[];
  user: any;
  states = environment.commons.claimStatus;
  responseMediums: any[] = [];
  areas: any[] = [];

  basePath: any;
  textAreaMinRows: number = environment.textAreaMinRows;
  textAreaMaxRows: number = environment.textAreaMaxRows;
  reasonsCommon = environment.commons.reasons;
  company;
  saving: boolean = false;
  claimsBasePath: Restangular;
  parametersBasePath: Restangular;

  constructor(
    private authService: AuthService,
    public claimService: ClaimService,
    @Inject(RESTANGULAR_CLAIMS) public restangularBase,
    public dialogRef: MatDialogRef<ModalClaimStatusConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utilService: UtilService,
    public logger: NGXLogger,
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
  ) {
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user: any) => {
      this.user = user;
      this.claim = this.data.claim;
      this.company = this.claim.company;

      this.claimStatus = {};
      this.claimStatus.claim = { id: this.claim.id };
      this.claimStatus.status = { id: this.data.status };
      this.claimStatus.createdBy = this.user.username;
      this.claimStatus.company = this.company;
      this.claimStatus.closingEndDate = this.calendar.getToday();

      this.claimsBasePath = this.restangularBase.one('', this.company).all('claims');
      this.parametersBasePath = this.restangularBase.one('', this.company).all('parameters');

      this.parametersBasePath.all('status').all('areas').getList().subscribe(
        response => {
          this.logger.debug('areas', response);
          this.areas = response;
        },
        error => this.logger.error(error),
      );

      this.parametersBasePath.all("response-mediums").getList().subscribe(
        items => this.responseMediums = items,
      );
    });
  }

  async save(flag: number) {
    if (flag === 2 && this.claimStatus.status.id !== this.states.ATTENDED) {
      this.dialogRef.close();
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando...',
    });

    Swal.showLoading();

    if (this.claimStatus.status.id === this.states.ATTENDED) {
      const finalizeData = {
        id: this.claim.id,
        company: this.company,
        proceeded: flag === 1,
        closingEndDate:  moment({ year: this.claimStatus.closingEndDate.year, month: this.claimStatus.closingEndDate.month - 1, day: this.claimStatus.closingEndDate.day }).format('YYYY-MM-DD'),
        returnAmount: this.claimStatus.returnAmount,
        responseMedium: this.claimStatus.responseMedium,
        area: this.claimStatus.area,
      };

      this.logger.debug('Calling to updateFinalizeData method');
      await this.updateFinalizeData(finalizeData);
    }

    this.logger.debug('After updateFinalizeData method');

    this.claimService.changeState(this.claimStatus).subscribe(
      response => {
        this.dialogRef.close(true);
        this.logger.debug('response', response);

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `El reclamo cambió de estado correctamente.`,
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 3000,
        });
      },
      error => {
        this.dialogRef.close();
        this.logger.error(error);

        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Lo sentimos',
          text: 'No se pudo cambiar el estado al reclamo',
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }

  private async updateFinalizeData(data: any) {
    this.logger.debug('Inside updateFinalizeData method');
    const response = await this.claimService.updateFinalizeValues(data);
    const json = await response.json();
    this.logger.debug('updateFinalizeData response', json);
  }
}
