import { NgModule } from "@angular/core";

// Modules
import { SharedModule } from "../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { CustomFormsModule } from 'ngx-custom-validators';

// Routes
import { PagesRoutingModule } from "./pages-routing.module";

// Components
import { PagesComponent } from "./pages.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SearchIncidenceComponent } from './search-incidence/search-incidence.component';
import { ModalDetalleComponent } from "../components/modal-detalle/modal-detalle.component";
import { ModalIncidenceComponent } from "../components/modal-incidence/modal-incidence.component";
import { ModalDestinatariosComponent } from "../components/modal-destinatarios/modal-destinatarios.component";
import { ModalDetalleIncComponent } from "../components/modal-detalle-inc/modal-detalle-inc.component";
import { ModalUpdateIncidenceComponent } from "../components/modal-update-incidence/modal-update-incidence.component";
import { ModalCommentComponent } from "../components/modal-comment/modal-comment.component";
import { ModalAssignComponent } from "../components/modal-assign/modal-assign.component";
import { ModalOrderRegisterComponent } from "../components/modal-order-register/modal-order-register.component";
import { ModalInvalidateIncidenceComponent } from "../components/modal-invalidate-incidence/modal-invalidate-incidence.component";
import { ModalRejectedReturnComponent } from "../components/modal-rejected-return/modal-rejected-return.component";
import { ModalRejectedRequestComponent } from "../components/modal-rejected-request/modal-rejected-request.component";
import { AutosizeModule } from "ngx-autosize";
import { ModalStateConfirmComponent } from "../components/modal-state-confirm/modal-state-confirm.component";
import { ModalUserEditComponent } from "../components/modal-user-edit/modal-user-edit.component";
import { ModalPasswordChangeComponent } from "../components/modal-password-change/modal-password-change.component";
import { ModalUserImportErrorComponent } from "../components/modal-user-import-error/modal-user-import-error.component";
import { ModalUserImportHelpComponent } from "../components/modal-user-import-help/modal-user-import-help.component";
import { ModalUserSearchComponent } from "../components/modal-user-search/modal-user-search.component";
import { ModalClientRegisterComponent } from "./claims/modals/modal-client-register/modal-client-register.component";
import { ModalRepresentativeRegisterComponent } from "./claims/modals/modal-representative-register/modal-representative-register.component";
import { ModalProgramDeliveryComponent } from "../components/modal-program-delivery/modal-porgram-delivery.component";
import { ModalOrderDetailComponent } from "./claims/modals/modal-order-detail/modal-order-detail.component";
import { ModalClaimRegisterComponent } from "./claims/modals/modal-claim-register/modal-claim-register.component";
import { ModalTechnicalServiceComponent } from '../components/modal-technical-service/modal-technical-service.component';
import { ModalClaimDetailComponent } from "./claims/modals/modal-claim-detail/modal-claim-detail.component";
import { ModalClaimAssignComponent } from "./claims/modals/modal-claim-assign/modal-claim-assign.component";
import { ModalClaimCommentComponent } from "./claims/modals/modal-claim-comment/modal-claim-comment.component";
import { ModalClaimInvalidateComponent } from "./claims/modals/modal-claim-invalidate/modal-claim-invalidate.component";
import { ModalClaimStatusConfirmComponent } from "./claims/modals/modal-claim-status-confirm/modal-claim-status-confirm.component";
import { ModalReasonComponent } from "../components/modal-reason/modal-reason.component";
import { ModalRejectedServiceComponent } from "../components/modal-rejected-service/modal-rejected-service.component";
import { ModalOstRegisterComponent } from "../components/modal-ost-register/modal-ost-register.component";
import { InputValidatorDirective } from "../utils/input-validator.directive";
import { TextAreaValidatorDirective } from "../utils/text-area-validator.directive";
import { ModalCancelComponent } from "../components/modal-cancel/modal-cancel.component";
import { ModalAttentionComponent } from "../components/modal-attention/modal-attention.component";
import { ModalIncidenceExternalComponent } from "../components/modal-incidence-external/modal-incidence-external.component";
import { ModalClaimTemplateEditorComponent } from "./claims/modals/modal-claim-template-editor/modal-claim-template-editor.component";
import { ModalClientRegisterComponentWithoutData } from "./claims/modals/modal-client-register-without-data/modal-client-register-without-data.component";
import { ModalProductInfoComponent } from "../components/modal-product-info/modal-product-info.component"; 
import { ModalProductInfoWithoutOrderComponent } from "../components/modal-product-info-without-order/modal-product-info-without-order.component"; 
import { ModalOstContactsComponent } from "../components/modal-ost-contacts/modal-ost-contacts.component";


@NgModule({
  declarations: [
    PagesComponent,
    DashboardComponent,
    SearchIncidenceComponent,
    ModalDetalleComponent,
    ModalIncidenceComponent,
    ModalDestinatariosComponent,
    ModalDetalleIncComponent,
    ModalUpdateIncidenceComponent,
    ModalCommentComponent,
    ModalAssignComponent,
    ModalOrderRegisterComponent,
    ModalInvalidateIncidenceComponent,
    ModalRejectedReturnComponent,
    ModalRejectedRequestComponent,
    ModalTechnicalServiceComponent,
    ModalStateConfirmComponent,
    ModalUserEditComponent,
    ModalPasswordChangeComponent,
    ModalUserImportErrorComponent,
    ModalUserImportHelpComponent,
    ModalUserSearchComponent,
    ModalClientRegisterComponent,
    ModalRepresentativeRegisterComponent,
    ModalProgramDeliveryComponent,
    ModalOrderDetailComponent,
    ModalClaimRegisterComponent,
    ModalClaimDetailComponent,
    ModalClaimAssignComponent,
    ModalClaimCommentComponent,
    ModalClaimInvalidateComponent,
    ModalClaimStatusConfirmComponent,
    ModalReasonComponent,
    ModalRejectedServiceComponent,
    ModalOstRegisterComponent,
    InputValidatorDirective,
    TextAreaValidatorDirective,
    ModalCancelComponent,
    ModalAttentionComponent,
    ModalIncidenceExternalComponent,
    ModalClaimTemplateEditorComponent,
    ModalClientRegisterComponentWithoutData,
    ModalProductInfoComponent,
    ModalProductInfoWithoutOrderComponent,
    ModalOstContactsComponent,
  ],
  exports: [
    DashboardComponent,
    ModalDetalleComponent,
    ModalIncidenceComponent,
    ModalDetalleIncComponent,
    ModalUpdateIncidenceComponent,
    ModalDestinatariosComponent,
    ModalCommentComponent,
    ModalClientRegisterComponentWithoutData,
  ],
  imports: [
    SharedModule,
    PagesRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    AutosizeModule,
    CustomFormsModule,
  ],
  entryComponents: [
    ModalIncidenceComponent,
    ModalDetalleComponent,
    ModalDetalleIncComponent,
    ModalUpdateIncidenceComponent,
    ModalDestinatariosComponent,
    ModalCommentComponent,
    ModalAssignComponent,
    ModalOrderRegisterComponent,
    ModalInvalidateIncidenceComponent,
    ModalRejectedReturnComponent,
    ModalRejectedRequestComponent,
    ModalTechnicalServiceComponent,
    ModalStateConfirmComponent,
    ModalUserEditComponent,
    ModalPasswordChangeComponent,
    ModalUserImportErrorComponent,
    ModalUserImportHelpComponent,
    ModalUserSearchComponent,
    ModalClientRegisterComponent,
    ModalRepresentativeRegisterComponent,
    ModalProgramDeliveryComponent,
    ModalOrderDetailComponent,
    ModalClaimRegisterComponent,
    ModalClaimDetailComponent,
    ModalClaimAssignComponent,
    ModalClaimCommentComponent,
    ModalClaimInvalidateComponent,
    ModalClaimStatusConfirmComponent,
    ModalReasonComponent,
    ModalRejectedServiceComponent,
    ModalOstRegisterComponent,
    ModalCancelComponent,
    ModalAttentionComponent,
    ModalIncidenceExternalComponent,
    ModalClaimTemplateEditorComponent,
    ModalClientRegisterComponentWithoutData,
    ModalProductInfoComponent,
    ModalProductInfoWithoutOrderComponent,
    ModalOstContactsComponent
  ],
})

export class PagesModule {
}
