import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { RESTANGULAR_CLIENT } from 'src/app/app.restangular.config';
import { ClienteService } from 'src/app/services/incidencia/cliente.service';
import { SearchService } from 'src/app/services/incidencia/search.service';
import { UtilService } from 'src/app/services/util/util.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-client-register',
  templateUrl: './modal-client-register.component.html',
  styleUrls: ['./modal-client-register.component.scss']
})
export class ModalClientRegisterComponent implements OnInit {
  isRepresentative: boolean = false;
  client: any = {};
  identificationType: any;
  identificationTypes: any[] = [];
  filteredIdentificationTypes: Observable<any[]>;

  department: any;
  province: any;
  district: any;
  departments: any[] = [];
  provinces: any[] = [];
  districts: any[] = [];
  filteredDepartments: Observable<any[]>;
  filteredProvices: Observable<any[]>;
  filteredDistricts: Observable<any[]>;

  clientsBasePath: any;
  saving: boolean = false;

  constructor(
    @Inject(RESTANGULAR_CLIENT) public restangularBase,
    public clientService: ClienteService,
    public searchService: SearchService,
    private utilService: UtilService,
    public dialogRef: MatDialogRef<ModalClientRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public logger: NGXLogger,
  ) {
    this.clientsBasePath = this.restangularBase.all('client').all('v1');
  }

  ngOnInit() {
    this.isRepresentative = this.data.isRepresentative;
    this.client = this.data.client || {};
    this.loadUbigeo(this.client.codUbigeo);
    this.loadIdentificationTypes();
    this.loadDepartaments();
  }

  displayNamePropertyFn(item: any): string {
    return item && item.name ? item.name : (item && item.description ? item.description : '');
  }

  loadIdentificationTypes() {
    this.clientsBasePath.all('identification-types').getList().subscribe(
      response => this.identificationTypes = response,
      error => this.logger.error(error),
    );
  }

  filterIdentificationTypes(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.identificationType === 'string' ? this.identificationType : this.identificationType.name;
    }

    this.filteredIdentificationTypes = of(value && value.trim().length > 0 ?
      this.identificationTypes.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.identificationTypes.slice());
  }

  loadDepartaments() {
    return this.searchService.obtainDepartamentos().subscribe((resp: any) => {
      this.departments = resp.data;
      this.departments = this.departments.sort((a, b) => a.description.localeCompare(b.description));

      if (this.client.codUbigeo) {
        const dep = this.client.codUbigeo.substr(0, 2);
        this.department = this.departments.find(item => item.code === dep);

        if (this.department) {
          this.loadProvinces();
        }
      }
    }, error => this.logger.debug(error));
  }

  loadProvinces() {
    return this.searchService.obtainProvincias(this.department.code.toString()).subscribe((resp: any) => {
      this.provinces = resp.data;
      this.provinces = this.provinces.sort((a, b) => a.description.localeCompare(b.description));

      if (this.client.codUbigeo) {
        const prov = this.client.codUbigeo.substr(0, 4);
        this.province = this.provinces.find(item => item.code === prov);

        if (this.province) {
          this.loadDistricts();
        }
      }
    }, error => this.logger.error(error));
  }

  loadDistricts() {
    return this.searchService.obtainProvincias(this.department.code.toString()).subscribe(
      (resp: any) => {
        const listProv2 = resp.data;
        if (listProv2 && listProv2.length > 0) {
          this.districts = listProv2.filter(value => value.code === this.province.code);
          const district = this.districts[0];
          this.districts = district.districts;
          this.districts = this.districts.sort((a, b) => a.description.localeCompare(b.description));

          if (this.client.codUbigeo) {
            this.district = this.districts.find(item => item.code === this.client.codUbigeo);
          }
        }
      },
      error => this.logger.error(error),
    );
  }

  loadUbigeo(ubigeo: string) {
    if (ubigeo) {
      this.loadDepartaments();
    } else {
      if (this.client && this.client.id) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 30000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          }
        });

        Toast.fire({
          icon: 'warning',
          title: 'Cliente no tiene ubigeo'
        });
      }
    }
  }

  filterDepartments(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.department === 'string' ? this.department : this.department.description;
    }

    this.filteredDepartments = of(value && value.trim().length > 0 ?
      this.departments.filter(item => item.description.toLowerCase().includes(value.toLowerCase())) : this.departments.slice());
  }

  filterProvinces(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.province === 'string' ? this.province : this.province.description;
    }

    this.filteredProvices = of(value && value.trim().length > 0 ?
      this.provinces.filter(item => item.description.toLowerCase().includes(value.toLowerCase())) : this.provinces.slice());
  }

  filterDistricts(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.district === 'string' ? this.district : this.district.description;
    }

    this.filteredDistricts = of(value && value.trim().length > 0 ?
      this.districts.filter(item => item.description.toLowerCase().includes(value.toLowerCase())) : this.districts.slice());
  }

  resetIdentificationType() {
    this.identificationType = null;
  }

  resetDepartment() {
    this.department = null;
  }

  resetProvince() {
    this.province = null;
  }

  resetDistrict() {
    this.district = null;
  }

  save(form: NgForm) {
    if (form.invalid) {
      this.utilService.markFormGroupTouched(form.form);
      return;
    }

    this.saving = true;
    this.client.codUbigeo = this.district != null ? this.district.code.toString() : null;
    this.logger.debug('passed form validation', this.client);

    const request = this.isRepresentative ? this.clientService.updateCiente(this.client) : this.clientService.saveClient(this.client);

    request.subscribe(
      (response: any) => {
        this.client = response;

        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Guardando...',
        });

        Swal.showLoading();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `Se guardaron los datos correctamente`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.dialogRef.close(this.client);
      }, error => {
        this.logger.error(error);
        this.dialogRef.close();
        Swal.fire({
          icon: 'error',
          title: 'Lo sentimos...',
          text: 'Error al guardar los datos del cliente'
        });
      }
    );
  }

}
