import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { OrdersModels } from "../../models/orders.models";
import { environment } from '../../../environments/environment';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) {
  }

  loadAllIncidents() {
    return this.http.get(`${environment.API_INCIDENCE}/incidence/v1/allIncidencias`)
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }

  obtainOrderByDocument(numDoc: string, codCompany: string, fromDate?: string, toDate?: string) {
    let headers = new HttpHeaders();
    headers = headers.set(environment.sensediaKey, environment.sensediaValue);

    let queryDates = '';
    if (fromDate && fromDate.length > 0) {
      queryDates += `&date-from=${fromDate}`;
    }

    if (toDate && toDate.length > 0) {
      queryDates += `&date-to=${toDate}`;
    }

    return this.http.get(`${environment.URL_API_ORDERS}/v1/${codCompany}/orders?document=${numDoc}${queryDates}`)
      .pipe(
        map((resp: any) => {
          return resp.data;
        })
      );
  }

  obtainOrderById(orders: OrdersModels, codCompany: string) {
    const headers = new Headers();
    headers.append(environment.sensediaKey, environment.sensediaValue);

    const url = `${environment.URL_API_ORDERS}/v1/${codCompany}/orders/${orders.orderId}?order-type=${orders.orderType}`;
    const request = new Request(url, {
      method: 'GET',
      mode: 'cors',
    });

    return fetch(request);
  }

  obtainDepartamentos() {
    return this.http.get(`${environment.API_UBIGEO}/v1/departaments`)
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }

  obtainProvincias(cod: string) {
    return this.http.get(`${environment.API_UBIGEO}/v1/provinces?departament=${cod}`)
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }
 
  obtainDeparmentProviciaDistricByUbigeo(ubigeo: string) {
    return this.http.get(`${environment.API_UBIGEO}/v1/search/ubigeo?district=${ubigeo}`)
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }

  loadOstBrands(companyCode: string) {
    return this.http.get(`${environment.API_INCIDENCE}/incidence/v1/serviceTecnic/brands?companyCode=${companyCode}`)
      .pipe(
        map(resp => {
          return resp;
        })
      );
  }

}
