import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { NewIncidence } from "../../models/newIncidence.models";

// SERVICIOS
import { StoreService } from "../../services/stores/store.service";
import { ParametersService } from "../../services/parameters/parameters.service";
import { IncidenceService } from "../../services/incidencia/incidence.service";
import Swal from "sweetalert2";
import { Observable, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_PARAMETER } from 'src/app/app.restangular.config';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ContactsModels } from 'src/app/models/contacts.models';
import { Overlay } from '@angular/cdk/overlay';
import { ModalDestinatariosComponent } from '../modal-destinatarios/modal-destinatarios.component';
import { ModalOstRegisterComponent } from '../modal-ost-register/modal-ost-register.component';
import { AppendFilesComponent } from 'src/app/shared/append-files-module/append-files.component';
import { FileObject } from 'src/app/models/file-object.model';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/service.index';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';

const I18N_VALUES = {
  'es': {
    weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  },
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-modal-update-incidence',
  templateUrl: './modal-update-incidence.component.html',
  styleUrls: ['./modal-update-incidence.component.scss'],
  providers: [
    NGXLogger,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class ModalUpdateIncidenceComponent implements OnInit {
  newIncidence: NewIncidence = new NewIncidence();
  observations: string;
  logo = '';

  disabledCbo = false;
  disabledCboArea2 = true;

  public oculto: string = '';
  company = '';

  stores: any[] = [];
  sellerCenter: any[] = [];

  listMotivos: any[] = [];
  listSubMotivos: any[] = [];
  listSubM: any[] = [];
  listFormat: any[] = [];
  listDeliveryMethod: any[] = [];
  listArea1: any[] = [];
  listArea2: any[] = [];
  sources: any[] = [];
  requestTypes: any[] = [];
  divisionTypes: any[] = [];

  viewSPSA = false;

  store: any;
  subStore: any;
  reason: any;
  subReason: any;
  area1: any;
  area2: any;
  requestType: any;
  format: any;
  deliveryMethod: any;
  source: any;
  clientNotify: boolean = true;
  divisionType: any;
  filteredStores: Observable<any[]>;
  filteredSubStores: Observable<any[]>;
  filteredReasons: Observable<any[]>;
  filteredSubReasons: Observable<any[]>;
  filteredAreas1: Observable<any[]>;
  filteredAreas2: Observable<any[]>;
  filteredFormats: Observable<any[]>;
  filteredDeliveryMethods: Observable<any[]>;
  filteredSources: Observable<any[]>;
  filteredRequestTypes: Observable<any[]>;
  filteredDivisionTypes: Observable<any[]>;

  parametersBasePath: any;
  saving: boolean = false;

  trackingIncidence: any;
  isProcessed: boolean = false;
  states = environment.commons.states;

  contacts: ContactsModels;
  fileList: FileObject[];
  idIncidence: number;
  client: any;
  order: any;
  code: string;
  orderId: string;
  products: any[];
  objectToCompare: any;

  reasonsEnvironment: any;
  registerDateObj: NgbDate | null;
  registerDateInputModel;

  constructor(
    public storeService: StoreService,
    public parametersService: ParametersService,
    public incidenceService: IncidenceService,
    public logger: NGXLogger,
    @Inject(RESTANGULAR_PARAMETER) public restangularBase,
    public dialogRef: MatDialogRef<ModalUpdateIncidenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private overlay: Overlay,
    public utilService: UtilService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
  ) {
    this.parametersBasePath = this.restangularBase.all('parameter').all('parameters').all('v1');
  }

  ngOnInit() {
    this.reasonsEnvironment = environment.commons.reasons;
    this.newIncidence = new NewIncidence();
    this.company = this.data.incidence.company.toLowerCase();
    this.logo = this.company;
    this.fileList = [];
    this.contacts = new ContactsModels();
    
    this.idIncidence = this.data.incidence.codIncidence;

    this.code = this.data.incidence.codIncidence;
    this.client = this.data.client;
    this.order = this.data.order;
    this.products = this.data.products;

    this.logger.debug('form data to load', this.data);
    this.setFormObjectValues(this.data.incidenceData);

    this.orderId = this.data.orderId || '-';
    this.registerDateObj = this.calendar.getToday();

    if (!this.data.incidenceData) {
      this.clearList();
    }

    this.loadDetails2()
    
  
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando detalles',
    });
    Swal.showLoading();

  }
  
  async loadDetails2() {
    try {
      const response = await this.incidenceService.obtainDetails(this.data.incidence.id).toPromise();
      this.logger.debug('incidence details', response);
      this.trackingIncidence = response;
      const check = this.trackingIncidence.states.filter(item => item.state.id === this.states.PROCESSED);
      this.isProcessed = check.length > 0;

      
      this.store = this.trackingIncidence.incidence.store;
      this.subStore = {codigoTienda: this.trackingIncidence.incidence.idSubstore, 
        nombreTienda: this.trackingIncidence.incidence.substoreName, 
        address: '',
        id: 0,
        name: this.trackingIncidence.incidence.substoreName,
        ubigeo: null,
        horario: ''
       }
      this.reason = this.trackingIncidence.incidence.reason;
      this.subReason = this.trackingIncidence.incidence.subReason;

      this.objectToCompare = {
        storeId: this.store.id,
        store: this.store.name,
        subStoreId: this.subStore.codigoTienda,
        subStore: this.subStore.nombreTienda,
        reasonId: this.reason.id,
        reason: this.reason.name,
        subReasonId: this.subReason.id,
        subReason: this.subReason.name,
      }

      this.loadMotivos();
      this.loadFormato();
     
    } catch (e) {
      this.logger.error(e)
    }
    
       
  }

  evaluateBeforeToSend(dataToSend: any) {
    const data: any = {};
    if (this.objectToCompare.storeId !== dataToSend.storeId) {
      data.storeId = dataToSend.storeId;
      data.store = dataToSend.store;
    }

    if (this.objectToCompare.subStoreId !== dataToSend.subStoreId) {
      data.subStoreId = dataToSend.subStoreId;
      data.subStore = dataToSend.subStore;
    }

    if (this.objectToCompare.reasonId !== dataToSend.reasonId) {
      data.reasonId = dataToSend.reasonId;
      data.reason = dataToSend.reason;
    }

    if (this.objectToCompare.subReasonId !== dataToSend.subReasonId) {
      data.subReasonId = dataToSend.subReasonId;
      data.subReason = dataToSend.subReason;
    }
    data.comment = dataToSend.comment;
    data.user = dataToSend.user;
    return data;
  }

  loadDetails() {
    Swal.close();
  }

  clearList() {
    this.resetStore();
    this.resetSubStore();
    this.resetReason();
    this.resetSubReason();
    this.resetArea1();
    this.resetArea2();
    this.resetFormat();
    this.resetDeliveryMethod();
    this.resetSource();
    this.resetRequestType();
    this.resetDivisionType();
  }

  saveIncidence(form: NgForm) {
    if (form.invalid) {
      this.utilService.markFormGroupTouched(form.form);
      return;
    }

    this.logger.debug('passed form validation');

    Swal.fire({
      text: '¿Estás seguro que deseas actualizar la incidencia?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this.newIncidence = this.getIncidenceValues();

        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: `Actualizando la incidencia`
        });
        Swal.showLoading();

        this.logger.debug(this.subStore)
        const data: any = {
          storeId: this.store.id,
          store: this.store.name,
          subStoreId: this.subStore.id,
          subStore: this.subStore.name,
          reasonId: this.reason.id,
          reason: this.reason.name,
          subReasonId: this.subReason.id,
          subReason: this.subReason.name,
          comment: this.observations,
          user: localStorage.getItem('username')
        }
        
        this.logger.debug(this.evaluateBeforeToSend(data))

        this.incidenceService.patchIncidence(this.evaluateBeforeToSend(data), this.data.incidence.id).subscribe(
          response => {
            this.logger.debug('incidence updated', response)
            Swal.close();
            this.showSuccessMessage(response);
            this.dialogRef.close(true);

          }, 
          error => {
            this.logger.error(error);
            Swal.close();
            this.showErrorMessage();
            this.dialogRef.close();
          }
        ) 
      }
    });
  }

  getIncidenceValues(): any {
    const newIncidence: any = {};
    newIncidence.id = this.idIncidence;
    newIncidence.codIncidence = this.code;
    newIncidence.area1 = this.area1 ? this.area1.id : null;
    newIncidence.area2 = this.area2 ? this.area2.id : null;
    newIncidence.client = this.client;
    newIncidence.codRequest = this.order ? this.orderId : null;
    newIncidence.store = this.store ? this.store.id : null;
    newIncidence.subStore = this.subStore ? this.subStore.id : null;
    newIncidence.subStoreName = this.subStore ? this.subStore.name : null;
    newIncidence.reason = this.reason ? this.reason.id : null;
    newIncidence.subReason = this.subReason ? this.subReason.id : null;
    newIncidence.format = this.format ? this.format.id : null;
    newIncidence.deliveryMethod = this.deliveryMethod ? this.deliveryMethod.id : null;
    newIncidence.user = localStorage.getItem('username');
    newIncidence.company = localStorage.getItem('company').toUpperCase();
    newIncidence.observations = this.observations;
    newIncidence.clientNotify = this.reasonsWithoutNotify(this.clientNotify, this.reason ? this.reason.id : null);
    newIncidence.source = this.source ? this.source.id : null;
    newIncidence.paymentOrder = this.order ? this.order.paymentOrder : null;
    newIncidence.saleNote = this.order ? this.order.saleNote : null;
    newIncidence.fromWebTracking = true;
    newIncidence.requestType = this.requestType ? this.requestType.id : null;
    newIncidence.registerDateObj = this.registerDateObj;
    newIncidence.registerDate = moment({ year: this.registerDateObj.year, month: this.registerDateObj.month - 1, day: this.registerDateObj.day }).format('YYYY-MM-DD');
    newIncidence.divisionType = this.divisionType ? this.divisionType.id : null;

    if (this.contacts && this.contacts.to && this.contacts.to.length > 0) {
      newIncidence.contactsTO = this.contacts.to;
    }

    if (this.contacts && this.contacts.cc && this.contacts.cc.length > 0) {
      newIncidence.contactsCC = this.contacts.cc;
    }

    return newIncidence;
  }

  getIncidenceObjectValues(): any {
    const newIncidence: any = {};
    newIncidence.id = this.idIncidence;
    newIncidence.codIncidence = this.code;
    newIncidence.area1 = this.area1;
    newIncidence.area2 = this.area2;
    newIncidence.client = this.client;
    newIncidence.codRequest = this.order ? this.orderId : null;
    newIncidence.store = this.store;
    newIncidence.subStore = this.subStore;
    newIncidence.subStoreName = this.subStore ? this.subStore.name : null;
    newIncidence.reason = this.reason;
    newIncidence.subReason = this.subReason;
    newIncidence.format = this.format;
    newIncidence.deliveryMethod = this.deliveryMethod;
    newIncidence.observations = this.observations;
    newIncidence.clientNotify = this.reasonsWithoutNotify(this.clientNotify, this.reason ? this.reason.id : null);
    newIncidence.fileList = this.fileList;
    newIncidence.source = this.source;
    newIncidence.requestType = this.requestType;
    newIncidence.registerDateObj = this.registerDateObj;
    newIncidence.divisionType = this.divisionType;

    if (this.contacts && this.contacts.to && this.contacts.to.length > 0) {
      newIncidence.contactsTO = this.contacts.to;
    }

    if (this.contacts && this.contacts.cc && this.contacts.cc.length > 0) {
      newIncidence.contactsCC = this.contacts.cc;
    }

    return newIncidence;
  }

  setFormObjectValues(incidenceData: any) {
    this.logger.debug('setFormObjectValues', incidenceData);
    if (incidenceData && incidenceData.incidence) {
      this.area1 = incidenceData.incidence.area1;
      this.area2 = incidenceData.incidence.area2;
      this.client = incidenceData.incidence.client;
      this.store = incidenceData.incidence.store;
      this.subStore = incidenceData.incidence.subStore;
      this.reason = incidenceData.incidence.reason;
      this.subReason = incidenceData.incidence.subReason;
      this.format = incidenceData.incidence.format;
      this.deliveryMethod = incidenceData.incidence.deliveryMethod;
      this.source = incidenceData.incidence.source;
      this.requestType = incidenceData.incidence.requestType;
      this.registerDateObj = incidenceData.incidence.registerDateObj;
      this.divisionType = incidenceData.incidence.divisionType;

      this.contacts = new ContactsModels();
      this.contacts.to = incidenceData.incidence.contactsTO ? incidenceData.incidence.contactsTO : '';
      this.contacts.cc = incidenceData.incidence.contactsCC ? incidenceData.incidence.contactsCC : '';
      this.observations = incidenceData.incidence.observations;
      this.clientNotify = incidenceData.incidence.clientNotify;
      this.fileList = incidenceData.incidence.fileList ? incidenceData.incidence.fileList : [];
    }
  }

  showSuccessMessage(response) {
    this.dialogRef.close(response);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Se actualizó con éxito la incidencia N° ${response.data.codIncidence}`,
      showConfirmButton: true,
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
      timer: 3000,
    });
  }

  showErrorMessage() {
    Swal.fire({
      icon: 'error',
      title: 'Lo sentimos...',
      text: 'No se pudo actualizar la Incidencia!'
    });
  }

  loadStoresByType() {
    if (this.store && Number(this.store.id) > 0) {
      const currentStore = this.stores.find(item => item.id === this.store.id);
      this.logger.debug('currentStore', currentStore);

      if (currentStore.name.trim().toLowerCase() === 'Tiendas físicas'.toLowerCase() ||
        currentStore.name.trim().toLowerCase() === 'Plaza Vea Online'.toLowerCase() ||
        currentStore.name.trim().toLowerCase() === 'Vivanda Online'.toLowerCase() ||
        currentStore.name.trim().toLowerCase() === 'TVO (204)'.toLowerCase() ||
        currentStore.name.trim().toLowerCase() === 'Promart.pe'.toLowerCase() 
      ) {
        this.loadAllStores();
        this.disabledCbo = false;
      } else {
        if (!(currentStore.company && currentStore.company.code && currentStore.marketPlaces.length > 0)) {
          this.disabledCbo = true;
        } else {
          this.disabledCbo = false;
        }

        this.loadSellerCenter();
      }
    } else {
      this.disabledCbo = true;
      this.resetSubStore();
    }
  }

  verifyCompany() {
    if (this.company === 'HPSA' || this.company === 'TPSA') {
      this.oculto = 'oculto';
    } else {
      this.oculto = '';
    }
  }

  loadAllStores() {
    this.storeService.obtainStores(this.format && this.format.code ? this.format.code : this.company).subscribe(
      (stores: any[]) => {
        // Hide some stores
        const hiddenStores = environment.commons.hiddenStores[this.company];
        this.sellerCenter = hiddenStores ? stores.filter(store => hiddenStores.includes(+store.codigoTienda) === false) : stores;
        this.sellerCenter = this.sellerCenter.map(store => ({ ...store, id: +store.codigoTienda, name: store.nombreTienda }));
        this.sellerCenter.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        this.logger.debug('loadAllStores', this.sellerCenter, this.deliveryMethod);
        this.loadDetails();
      },
      error => this.logger.error(error)
    );
  }

  loadFirstStoresByCompany() {
    if (this.format && Number(this.format.id) > 0) {
      this.parametersBasePath.all('stores').all('search').one('by-format', this.format.id).getList().subscribe(
        stores => {
          this.stores = stores;

          if (this.order && this.orderId && this.order.orderType === 'TD') {
            this.store = this.stores.find(item => item.name.trim().toLowerCase() === 'Tiendas físicas'.toLowerCase());

            this.subStore = {
              id: +this.order.saleEntityCode,
              name: this.order.saleEntity,
            };
          }

          if (this.order && this.orderId && this.order.orderType === 'EC') {
            this.store = this.stores.find(item => item.name.trim().toLowerCase() === 'Plaza Vea Online'.toLowerCase() ||
            item.name.trim().toLowerCase() === 'Vivanda Online'.toLowerCase());
          }

          this.loadStoresByType();
          this.loadMotivos();
        },
        error => this.logger.error(error),
      );
    } else {
      this.parametersBasePath.all('stores').all('search').one('by-company', this.company).getList().subscribe(
        stores => {
          this.stores = stores;
          this.logger.debug('stores', this.stores);

          if (this.order && this.orderId && this.order.orderType === 'TD') {
            this.store = this.stores.find(item => item.name.trim().toLowerCase() === 'Tiendas físicas'.toLowerCase());

            this.subStore = {
              id: +this.order.saleEntityCode,
              name: this.order.saleEntity,
            };
          }

          if (this.order && this.orderId && this.order.orderType === 'EC') {
            this.store = this.stores.find(item => item.name.trim().toLowerCase() === 'TVO (204)'.toLowerCase() ||
            item.name.trim().toLowerCase() === 'Promart.pe'.toLowerCase());
          }

          this.loadStoresByType();
          this.loadMotivos();
        },
        error => this.logger.error(error),
      );
    }
  }

  filterStores(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.store === 'string' ? this.store : this.store.name;
    }

    this.filteredStores = of(value && value.trim().length > 0 ?
      this.stores.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.stores.slice());
  }

  loadMotivos() {
    let online = false;

    if (this.store && this.store.id && this.utilService.isSpsa(this.company)) {
      online = this.store.name.toLowerCase()  === 'marketplace' || this.store.name.toLowerCase().indexOf('online') >= 0;
    }

    this.parametersBasePath.all('reasons').all('search').one('by-company').getList(this.company, {online}).subscribe(
      response => {
        this.listMotivos = response;
        this.listMotivos = this.listMotivos.filter(item =>
          [
            environment.commons.reasons.SELLERCENTER_RETURNS,
            environment.commons.reasons.RETURN_REQUEST,
            environment.commons.reasons.CHANGE_REQUEST,
          ].includes(item.id) === false);

          this.loadSubMotivos()
      },
      error => this.logger.error(error),
    );
  }

  displayNamePropertyFn(item: any): string {
    return item && item.name ? item.name : '';
  }

  filterReasons(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.reason === 'string' ? this.reason : this.reason.name;
    }

    this.logger.debug('value', this.reason, value);
    this.filteredReasons = of(value && value.trim().length > 0 ?
      this.listMotivos.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.listMotivos.slice());
  }

  loadSubMotivos() {
    if (this.reason && Number(this.reason.id) > 0) {
      this.listSubMotivos = this.listMotivos.filter(value => value.id === this.reason.id).map(resp => {
        return resp.subreasons;
      });

      this.listSubM = this.listSubMotivos[0];
    } else {
      this.listSubM = [];
      this.resetSubReason();
    }
  }

  filterSubReasons(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.subReason === 'string' ? this.subReason : this.subReason.name;
    }

    this.filteredSubReasons = of(value && value.trim().length > 0 ?
      this.listSubM.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.listSubM.slice());
  }

  loadSellerCenter() {
    const seller = this.stores.find(value => value.id === this.store.id);

    if (seller) {
      this.logger.debug('seller', seller);
      this.sellerCenter = seller.marketPlaces;

      if (this.store && this.store.name === environment.commons.sellerCenterName) {
        this.subStore = this.sellerCenter[0];
        this.disabledCbo = true;

        this.setResponsibleSellerCenter();
      }
      this.loadDetails();
    }
  }

  setResponsibleSellerCenter() {
    if (this.contacts.disabled) {
      this.contacts.disabled = false;
      this.contacts.to = [];
      this.contacts.cc = [];
    }

    if (this.store && this.store.name === environment.commons.sellerCenterName && this.reason) {

        this.incidenceService.getResponsiblesSellerCenter(this.reason.id, 'REASON').subscribe(
          response => {
            this.logger.debug('getResponsiblesSellerCenter', response);

            if (response && response.length > 0) {
              this.contacts.to = response.map(item => item.email);
              this.contacts.cc = [];
              this.contacts.disabled = true;
            }
          },
          error => this.logger.error(error),
        );
    }

    this.loadDetails();
  }

  filterSubStores(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.subStore === 'string' ? this.subStore : this.subStore.nombreTienda;
    }

    this.filteredSubStores = of(value && value.trim().length > 0 ?
    this.sellerCenter.filter(item => item.nombreTienda.toLowerCase().includes(value.toLowerCase())) : this.sellerCenter.slice());
  }

  loadFormato() {
    this.parametersBasePath.all('formats').getList().subscribe(
      formats => {
        this.listFormat = formats;
        this.format = this.listFormat.find(item => item.code === this.company);
        // this.loadFirstStoresByCompany();

        if (this.format && this.order && this.orderId) {
          const orderDeliveryMethod = +this.order.typeDeliveryId;
          const isPvea = this.company === 'pvea';

          switch (orderDeliveryMethod) {
            case environment.commons.deliveryMethods.HOME_DELIVERY:
              this.deliveryMethod = this.format.deliveryMethods.find(item => item.id === (isPvea ? 5 : 10));
              break;

            case environment.commons.deliveryMethods.STORE_PICKUP:
              this.deliveryMethod = this.format.deliveryMethods.find(item => item.id === (isPvea ? 6 : 11));
              break;

            case environment.commons.deliveryMethods.CAR_PICKUP:
              this.deliveryMethod = this.format.deliveryMethods.find(item => item.id === 7);
              break;

            case environment.commons.deliveryMethods.DELIVERY_EXPRESS:
              this.deliveryMethod = this.format.deliveryMethods.find(item => item.id === (isPvea ? 4 : 9));
              break;
          }

          if (this.order.orderType === 'TD') {
            this.deliveryMethod = this.format.deliveryMethods.find(item => item.id === (isPvea ? 8 : 12));
          }
        }
      },
      error => this.logger.error(error),
      () => this.loadFirstStoresByCompany(),
    );
  }

  filterFormats(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.format === 'string' ? this.format : this.format.name;
    }

    this.filteredFormats = of(value && value.trim().length > 0 ?
      this.listFormat.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.listFormat.slice());
  }

  filterDeliveryMethods(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.deliveryMethod === 'string' ? this.deliveryMethod : this.deliveryMethod.value;
    }

    this.filteredDeliveryMethods = of(value && value.trim().length > 0 ?
      this.format.deliveryMethods.filter(item => item.value.toLowerCase().includes(value.toLowerCase())) : this.format.deliveryMethods.slice());
  }

  loadAreas() {
    this.parametersService.getAreasbyCompany(this.company).subscribe(
      resp => {
        this.listArea1 = resp;
      },
      error => this.logger.error(error),
    );
  }

  filterAreas1(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.area1 === 'string' ? this.area1 : this.area1.name;
    }

    this.filteredAreas1 = of(value && value.trim().length > 0 ?
      this.listArea1.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.listArea1.slice());
  }

  loadAreas2() {
    if (this.area1 && Number(this.area1.id) > 0) {
      this.disabledCboArea2 = false;
      this.listArea2 = this.listArea1.filter(value => value.id !== this.area1.id);
    } else {
      this.listArea2 = [];
      this.disabledCboArea2 = true;
    }
  }

  filterAreas2(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.area2 === 'string' ? this.area2 : this.area2.name;
    }

    this.filteredAreas2 = of(value && value.trim().length > 0 ?
      this.listArea2.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.listArea2.slice());
  }

  loadSources() {
    this.parametersBasePath.all('sources').all('search').one('by-company').getList(this.company).subscribe(
      response => this.sources = response,
      error => this.logger.error(error),
    );
  }

  filterSources(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.source === 'string' ? this.source : this.source.name;
    }

    this.filteredSources = of(value && value.trim().length > 0 ?
      this.sources.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.sources.slice());
  }

  loadRequestType() {
    this.parametersBasePath.all('request-types').getList().subscribe(
      response => this.requestTypes = response,
      error => this.logger.error(error),
    );
  }

  filterRequestTypes(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.requestType === 'string' ? this.requestType : this.requestType.name;
    }

    this.filteredRequestTypes = of(value && value.trim().length > 0 ?
      this.requestTypes.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.requestTypes.slice());
  }

  loadDivisionType() {
    this.parametersBasePath.all('division-types').getList().subscribe(
      response => this.divisionTypes = response,
      error => this.logger.error(error),
    );
  }

  filterDivisionTypes(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.divisionType === 'string' ? this.divisionType : this.divisionType.name;
    }

    this.filteredDivisionTypes = of(value && value.trim().length > 0 ?
      this.divisionTypes.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.divisionTypes.slice());
  }

  resetStore() {
    this.store = null;
  }

  resetSubStore() {
    this.subStore = null;
  }

  resetReason() {
    this.reason = null;
    this.resetSubReason();
  }

  resetSubReason() {
    this.subReason = null;
  }

  resetArea1() {
    this.area1 = null;
  }

  resetArea2() {
    this.area2 = null;
  }

  resetFormat() {
    this.format = null;
  }

  resetDeliveryMethod() {
    this.deliveryMethod = null;
  }

  resetSource() {
    this.source = null;
  }

  resetRequestType() {
    this.requestType = null;
  }

  resetDivisionType() {
    this.divisionType = null;
  }

  openRecipientsDialog() {
    if (!this.store || !this.reason) {
      Swal.fire({
        text: 'Por favor elija la tienda y el motivo antes de asignar responsables',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
      });

      return;
    }

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalDestinatariosComponent, {
      width: '500px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        contacts: this.contacts,
        store: this.store,
        subStore: this.subStore,
      }
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        if (dialogResponse.data) {
          this.contacts = dialogResponse.data;
        } else {
          this.clientNotify = false;
        }
      }
    });
  }

  openAppendFileDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(AppendFilesComponent, {
      width: '500px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.fileList = dialogResponse;
        this.logger.debug('fileList', this.fileList);
      }
    });
  }

  openOrderDetailsDialog() {
    const incidence = this.getIncidenceObjectValues();
    const data = {
      client: this.client,
      order: this.order,
      code: this.idIncidence,
      incidence,
    };

    this.dialogRef.close({ backToProducts: true, data });
  }

  reasonsWithoutNotify(clientNotify: boolean, idReason) {
    if (idReason !== null && idReason !== undefined && (idReason === this.reasonsEnvironment.TECHNICAL_SERVICE_PILOT)) {
      return false;
    }

    return clientNotify;
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    this.logger.debug('validateInput', currentValue, input);
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : null;
  }

  openRegisterOstDialog(form: NgForm){
    if (form.invalid) {
      this.utilService.markFormGroupTouched(form.form);
      return;
    }

    this.logger.debug('passed form validation');

    if (!this.utilService.isSpsa(this.company) && this.contacts.to.length <= 0) {
      Swal.fire({
        icon: 'info',
        text: `No se ha asignado responsable. Recuerda que es obligatorio asignar por lo menos uno`,
        confirmButtonColor: '#20a8d8',
        confirmButtonText: 'Ok',
      });

      return;
    }

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalOstRegisterComponent, {
      width: '1000px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        newIncidence : this.getIncidenceValues(),
        fileList: this.fileList,
        subStores: this.sellerCenter,
        company : this.company,
        format: this.format
      }
    });

    /*dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
          if(!dialogResponse.cancel){
            this.dialogRef.close(dialogResponse);
          }
      }
    });*/
  }
}

