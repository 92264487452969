import {Component, OnInit} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { UtilService } from 'src/app/services/util/util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  modules: any[] = [
    {
      name: 'Incidencias',
      icon: 'icon-incidences',
      submenu: [
      ],
      selected: true,
      show: true,
    },
    {
      name: 'Libro de reclamaciones',
      icon: 'icon-claims',
      submenu: [
      ],
      selected: false,
      show: false,
    },
  ];

  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user: any) => {
      this.logger.debug('Sidebar component. User:', user);

      if (user.roles.some(rol => environment.commons.externalRoles.indexOf(rol) !== -1)) {
        this.modules[0].submenu = [
          { name: 'Seguimiento', url: '/pages/tracing', icon: 'icon-tracking' },
        ];

        if (this.utilService.isHpsa(user.company)) {
          this.modules[0].submenu.unshift(
            { name: 'Buscar', url: '/pages/external/search', icon: 'icon-search' },
          );
        }

        this.modules[1].show = false; // Hide LR module
      }

      if (user.roles.some(rol => environment.commons.internalRoles.indexOf(rol) !== -1)) {
        this.modules[0].submenu = [
          { name: 'Buscar', url: '/pages/search', icon: 'icon-search' },
          // { name: 'Ingresar', url: '/registry', icon: 'icon-register' },
          { name: 'Seguimiento', url: '/pages/tracing', icon: 'icon-tracking' },
          { name: 'Resultados', url: '/pages/results', icon: 'icon-reports' },
        ];
      }

      if (user.roles.some(rol => environment.commons.adminRoles.indexOf(rol) !== -1)) {
        this.modules[0].submenu.push({
          name: 'Personas', url: '/pages/users', icon: 'icon-users',
        });
      }

      if (user.roles.some(rol => environment.commons.claimsRoles.indexOf(rol) !== -1)) {
        this.modules[0].selected = false;
        this.modules[1].selected = true;
        this.modules[1].show = true;
        this.modules[1].submenu.push(
          { name: 'Ingresar', url: '/pages/claims/register', icon: 'icon-register' },
          { name: 'Seguimiento', url: '/pages/claims/tracing', icon: 'icon-tracking' },
          { name: 'Resultados', url: '/pages/claims/reports', icon: 'icon-reports' },
        );
      }
    });
  }

}
