import { Overlay } from '@angular/cdk/overlay';
import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_PARAMETER } from 'src/app/app.restangular.config';
import { IncidenceService } from 'src/app/services/incidencia/incidence.service';
import { ParametersService } from 'src/app/services/parameters/parameters.service';
import { StoreService } from 'src/app/services/stores/store.service';
import { UtilService } from 'src/app/services/util/util.service';
import moment from 'moment';
import { FileObject } from 'src/app/models/file-object.model';
import { AppendFilesComponent } from 'src/app/shared/append-files-module/append-files.component';
import Swal from 'sweetalert2';
import { ModalDestinatariosComponent } from '../modal-destinatarios/modal-destinatarios.component';
import { ContactsModels } from 'src/app/models/contacts.models';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { FileSystemFileEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-modal-incidence-external',
  templateUrl: './modal-incidence-external.component.html',
  styleUrls: ['./modal-incidence-external.component.scss'],
})
export class ModalIncidenceExternalComponent implements OnInit {
  saving: boolean = false;
  company: string;
  incidence: any = {};
  client: any;
  serviceOrders: any[] = [];

  contacts: ContactsModels;
  fileList: FileObject[];

  textAreaMaxLength: number = environment.textAreaMaxLength;

  stores: any[];
  subStores: any[];
  reasons: any[];
  subReasons: any[];
  areas: any[];

  filteredStores: Observable<any[]>;
  filteredSubStores: Observable<any[]>;
  filteredReasons: Observable<any[]>;
  filteredSubReasons: Observable<any[]>;
  filteredAreas1: Observable<any[]>;

  parametersBasePath: any;

  constructor(
    public storeService: StoreService,
    public parametersService: ParametersService,
    public incidenceService: IncidenceService,
    public logger: NGXLogger,
    @Inject(RESTANGULAR_PARAMETER) public restangularBase,
    public dialogRef: MatDialogRef<ModalIncidenceExternalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private overlay: Overlay,
    public utilService: UtilService,
  ) {
    this.parametersBasePath = this.restangularBase.all('parameter').all('parameters').all('v1');
  }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.fileList = [];
    this.contacts = new ContactsModels();

    this.incidence.id = this.data.id;
    this.incidence.code = this.data.code;
    this.client = this.data.client;
    this.serviceOrders = this.data.serviceOrders;
    this.logger.debug('serviceOrders', this.serviceOrders);

    this.loadStores();
    this.loadReasons();
    this.loadAreas();
  }

  displayNamePropertyFn(item: any): string {
    return item && item.name ? item.name : '';
  }

  loadStores() {
    this.parametersBasePath.all('stores').all('search').one('by-company', this.company).getList().subscribe(
      stores => {
        const hiddenStores = environment.commons.externalStores[this.company];
        this.stores = hiddenStores ? stores.filter(store => hiddenStores.includes(store.id) === true) : stores;
      },
      error => this.logger.error(error),
    );
  }

  filterStores(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.incidence.store === 'string' ? this.incidence.store : this.incidence.store.name;
    }

    this.filteredStores = of(value && value.trim().length > 0 ?
      this.stores.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.stores.slice());
  }

  loadSubStores() {
    this.storeService.obtainStores(this.company).subscribe(
      (stores: any[]) => {
        // Hide some stores
        const hiddenSubStores = environment.commons.hiddenStores[this.company];
        this.subStores = hiddenSubStores ? stores.filter(store => hiddenSubStores.includes(+store.codigoTienda) === false) : stores;
        this.subStores = this.subStores.map(store => ({ ...store, id: +store.codigoTienda, name: store.nombreTienda }));
        this.subStores.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      },
      error => this.logger.error(error),
    );
  }

  filterSubStores(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.incidence.subStore === 'string' ? this.incidence.subStore : this.incidence.subStore.nombreTienda;
    }

    this.filteredSubStores = of(value && value.trim().length > 0 ?
      this.subStores.filter(item => item.nombreTienda.toLowerCase().includes(value.toLowerCase())) : this.subStores.slice());
  }

  loadReasons() {
    const online = false;

    this.parametersBasePath.all('reasons').all('search').one('by-company').getList(this.company, {online}).subscribe(
      reasons => {
        const allowReasons = environment.commons.externalReasons[this.company];
        this.reasons = allowReasons ? reasons.filter(item => allowReasons.includes(item.id) === true) : reasons;
      },
      error => this.logger.error(error),
    );
  }

  filterReasons(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.incidence.reason === 'string' ? this.incidence.reason : this.incidence.reason.name;
    }

    this.filteredReasons = of(value && value.trim().length > 0 ?
      this.reasons.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.reasons.slice());
  }

  loadSubReasons() {
    if (this.incidence.reason && Number(this.incidence.reason.id) > 0) {
      const subReasons = this.reasons.filter(reason => reason.id === this.incidence.reason.id).map(reason => reason.subreasons);
      this.subReasons = subReasons[0];
      const hiddenSubReasons = environment.commons.externalHideSubReasons[this.company];
      this.subReasons = hiddenSubReasons ? this.subReasons.filter(item => hiddenSubReasons.includes(item.id) !== true) : subReasons;
    } else {
      this.subReasons = [];
      this.resetSubReason();
    }
  }

  filterSubReasons(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.incidence.subReason === 'string' ? this.incidence.subReason : this.incidence.subReason.name;
    }

    this.filteredSubReasons = of(value && value.trim().length > 0 ?
      this.subReasons.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.subReasons.slice());
  }

  loadAreas() {
    this.parametersService.getAreasbyCompany(this.company).subscribe(
      resp => {
        this.areas = resp;
      },
      error => this.logger.error(error),
    );
  }

  filterAreas(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.incidence.area === 'string' ? this.incidence.area : this.incidence.area.name;
    }

    this.filteredAreas1 = of(value && value.trim().length > 0 ?
      this.areas.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.areas.slice());
  }

  resetStore() {
    this.incidence.store = null;
  }

  resetSubStore() {
    this.incidence.subStore = null;
  }

  resetReason() {
    this.incidence.reason = null;
    this.resetSubReason();
  }

  resetSubReason() {
    this.incidence.subReason = null;
  }

  resetArea() {
    this.incidence.area = null;
  }

  saveIncidence(form: NgForm) {
    if (form.invalid) {
      this.utilService.markFormGroupTouched(form.form);
      return;
    }

    if (!this.utilService.isSpsa(this.company) && this.contacts.to.length <= 0) {
      Swal.fire({
        icon: 'info',
        text: `No se ha asignado responsable. Recuerda que es obligatorio asignar por lo menos uno`,
        confirmButtonColor: '#20a8d8',
        confirmButtonText: 'Ok',
      });

      return;
    }

    Swal.fire({
      text: '¿Estás seguro que deseas registrar la incidencia?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        const newIncidence = this.getIncidenceValues();
        this.logger.debug('newIncidence values', newIncidence);

        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: `Generando la incidencia`
        });

        Swal.showLoading();

        this.incidenceService.nuevaIncidencia(newIncidence).subscribe(
          response => {
            this.logger.debug('saved incidence', response);
            if (response) {

              // Upload files
              if (this.fileList && this.fileList.length > 0) {
                Swal.fire({
                  allowOutsideClick: false,
                  icon: 'info',
                  text: 'Subiendo archivos'
                });

                Swal.showLoading();

                let document: any;
                let formData: FormData;
                let count = 0;

                this.fileList.forEach((item: FileObject, index) => {
                  if (item.file.isFile) {
                    const fileEntry = item.file as FileSystemFileEntry;

                    fileEntry.file((file: File) => {
                      document = {};
                      document.name = item.name;
                      document.modifiedDate = item.lastModifiedDate;
                      document.contentType = item.type;
                      document.size = item.size;

                      // Prepare formdata to upload
                      formData = new FormData();
                      formData.append('file', file);

                      const url = `${environment.API_UPLOADFILE}/files/v1/upload/${response[0].codIncidence}`;
                      const request = new Request(url, {
                        method: 'POST',
                        mode: 'cors',
                        body: formData,
                      });

                      // Upload to server
                      (async () => {
                        const responseFetch = await fetch(request);
                        const json = await responseFetch.json();
                        this.logger.debug('Uploaded data', json);
                        count++;

                        if (count === this.fileList.length) {
                          this.showSuccessMessage(response);
                        }
                      })();
                    });
                  }
                });
              } else {
                this.showSuccessMessage(response);
              }
            } else {
              this.dialogRef.close();
              this.showErrorMessage();
            }
          },
          error => {
            this.logger.error(error);
            this.dialogRef.close();
            this.showErrorMessage();
          },
        );
      }
    });
  }

  getIncidenceValues(): any {
    const newIncidence: any = {};
    newIncidence.id = this.incidence.id;
    newIncidence.codIncidence = this.incidence.code;
    newIncidence.area1 = this.incidence.area ? this.incidence.area.id : null;
    newIncidence.client = this.client;
    newIncidence.codRequest = this.incidence.orderId ? this.incidence.orderId : null;
    newIncidence.store = this.incidence.store ? this.incidence.store.id : null;
    newIncidence.subStore = this.incidence.subStore ? this.incidence.subStore.id : null;
    newIncidence.subStoreName = this.incidence.subStore ? this.incidence.subStore.name : null;
    newIncidence.reason = this.incidence.reason ? this.incidence.reason.id : null;
    newIncidence.subReason = this.incidence.subReason ? this.incidence.subReason.id : null;
    newIncidence.user = localStorage.getItem('username');
    newIncidence.company = localStorage.getItem('company').toUpperCase();
    newIncidence.observations = this.incidence.observations;
    newIncidence.clientNotify = false;
    newIncidence.fromWebTracking = true;
    newIncidence.registerDate = moment().format('YYYY-MM-DD');

    if (this.contacts && this.contacts.to && this.contacts.to.length > 0) {
      newIncidence.contactsTO = this.contacts.to;
    }

    if (this.contacts && this.contacts.cc && this.contacts.cc.length > 0) {
      newIncidence.contactsCC = this.contacts.cc;
    }

    return newIncidence;
  }

  showSuccessMessage(response) {
    this.dialogRef.close(response);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Se generó con éxito la incidencia N° ${response[0].codIncidence}`,
      showConfirmButton: true,
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
      timer: 3000,
    });
  }

  showErrorMessage() {
    Swal.fire({
      icon: 'error',
      title: 'Lo sentimos...',
      text: 'No se pudo registrar la Incidencia!'
    });
  }

  openAppendFileDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(AppendFilesComponent, {
      width: '500px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {},
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.fileList = dialogResponse;
        this.logger.debug('fileList', this.fileList);
      }
    });
  }

  openRecipientsDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalDestinatariosComponent, {
      width: '500px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        contacts: this.contacts,
        fromExternalProvider: true,
      }
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        if (dialogResponse.data) {
          this.contacts = dialogResponse.data;
        }
      }
    });
  }
}
