import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(private http: HttpClient) {
  }

  obtainStores(codCompany: string) {
    return this.http.get(`${environment.API_STORES}/v1/${codCompany}/store?point=trkg`)
      .pipe(
        map((resp: any) => {
          return resp.data;
        })
      );
  }

  obtainStoresForClaim(codCompany: string) {
    return this.http.get(`${environment.API_STORES}/v1/${codCompany}/store?point=claim`)
      .pipe(
        map((resp: any) => {
          return resp.data;
        })
      );
  }

  ontainStoreByCompany(codCompany) {
    return this.http.get(`${environment.API_PARAMETERS}/parameter/parameters/v1/store/${codCompany}`)
      .pipe(map((resp: any) => {
        return resp.data;
      }));
  }

}
