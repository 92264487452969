export class IncidenceModels {

  id: number;
  idTracking: number;
  codIncidence: string;
  createdOn: string;
  nameReason: string;
  nameSubReason: string;
  status: string;
  recontact: number;
}
