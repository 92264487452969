import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { NGXLogger } from 'ngx-logger';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import Swal from 'sweetalert2';
import { IncidenceService } from 'src/app/services/service.index';
import { ModalIncidenceComponent } from '../modal-incidence/modal-incidence.component';
import { environment } from 'src/environments/environment';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-modal-detalle',
  templateUrl: './modal-detalle.component.html',
  styleUrls: ['./modal-detalle.component.scss'],
  providers: [
    NGXLogger,
  ],
})
export class ModalDetalleComponent implements OnInit {
  company = '';
  displayedColumns = [
    'select',
    'productCode', 'productName', 'measurementUnit', 'originProduct', 'listPriceUnit',
    'pickingQuantity', 'discount', 'totalPrice', 'seller',
  ];
  dataSource;
  selection = new SelectionModel<any>(true, []);
  order: any;
  products: any[];
  fromIncidenceDialog: boolean = false;
  incidenceData: any;
  searchDocument: string;
  viewProducts: boolean = false;
  currentSeller: string;
  hasMultiSeller: boolean = false;

  constructor(
    public incidenceService: IncidenceService,
    public dialogRef: MatDialogRef<ModalDetalleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private overlay: Overlay,
    public logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.order = this.data.order;
    this.hasMultiSeller = this.order.multiseller && this.order.multiseller.length > 1;
    this.searchDocument = this.data.document,
    this.viewProducts = this.data.viewProducts || false;
    this.order.lstDetail.map(item => item.selected = false);
    this.dataSource = new MatTableDataSource(this.order.lstDetail);
    this.selection.clear();
    this.isAllSelected();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.dataSource.data.forEach(row => row.selected = false);
    } else {
      this.dataSource.data.forEach(row => {
        row.selected = true;
        this.selection.select(row);
      });
    }
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  generateIncidence() {
    this.logger.debug('selection', this.selection.selected);
    this.products = this.products || [];

    this.selection.selected.forEach(product => {
      if (!environment.commons.productExcludeCodes.includes(product.productCode)) {
        this.products.push({
          orderId: product.orderIdChild,
          origin: product.originProduct,
          seller: product.seller,
          quantity: +product.pickingQuantity,
          listPriceUnit: +product.listPriceUnit,
          discount: +product.discount,
          totalPrice: +product.totalPrice,
          product: {
            code: product.productCode,
            name: product.productName,
          },
        });
      }
    });

    Swal.fire({
      title: '¿Deseas generar una incidencia?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this.logger.debug('products for incidence', this.products);

        this.incidenceService.getCodNewIncidence(this.data.client.numDoc, this.company, this.products, environment.commons.associateType.PRODUCT).subscribe(
          response => {
            const id = +response.id;
            const code = response.code;
            this.logger.debug('new code', id, code);

            const scrollStrategy = this.overlay.scrollStrategies.block();
            const dialogIncidenceRef = this.dialog.open(ModalIncidenceComponent, {
              width: '1010px',
              maxHeight: '100vh',
              disableClose: true,
              autoFocus: false,
              scrollStrategy,
              data: {
                id,
                code,
                client: this.data.client,
                order: this.data.order,
                orderId: this.products.length > 0 ? this.products[0].orderId : null,
                products: this.products,
              },
            });

            dialogIncidenceRef.afterClosed().subscribe(dialogResponse => {
              this.logger.debug('inside dialog detalle response', dialogResponse);
              if (dialogResponse) {
                if (dialogResponse.backToProducts) {
                  this.fromIncidenceDialog = true;
                  this.incidenceData = dialogResponse.data;
                  this.logger.debug('backToProducts', dialogResponse.data);
                } else {
                  this.dialogRef.close(true);
                }
              } else {
                this.dialogRef.close(false);
                this.incidenceService.deleteCodIncidencia(id).subscribe(
                  deleteResponse => this.logger.debug('Deleted incidence', deleteResponse),
                  error => this.logger.error(error),
                );
              }
            });
          },
          error => this.logger.error(error),
        );
      }
    });
  }

  openIncidenceDialog() {
    this.logger.debug('selection', this.selection.selected);
    this.products = [];

    this.selection.selected.forEach(product => {
      if (!environment.commons.productExcludeCodes.includes(product.productCode)) {
        this.products.push({
          orderId: product.orderIdChild,
          origin: product.originProduct,
          seller: product.seller,
          quantity: +product.pickingQuantity,
          listPriceUnit: +product.listPriceUnit,
          discount: +product.discount,
          totalPrice: +product.totalPrice,
          product: {
            code: product.productCode,
            name: product.productName,
          },
        });
      }
    });

    // Update incidence products
    this.logger.debug('incindeceData', this.incidenceData);
    this.incidenceService.updateIncidenceProductos(this.incidenceData.incidence.id, this.products).subscribe(
      response => {
        const scrollStrategy = this.overlay.scrollStrategies.block();
        const dialogIncidenceRef = this.dialog.open(ModalIncidenceComponent, {
          width: '1010px',
          maxHeight: '100vh',
          disableClose: true,
          autoFocus: false,
          scrollStrategy,
          data: {
            id: this.incidenceData.incidence.id,
            code: this.incidenceData.incidence.codIncidence,
            client: this.incidenceData.client,
            order: this.incidenceData.order,
            orderId: this.products.length > 0 ? this.products[0].orderId : null,
            products: this.products,
            incidenceData: this.incidenceData,
          },
        });

        dialogIncidenceRef.afterClosed().subscribe(dialogResponse => {
          this.logger.debug('inside dialog response', dialogResponse);
          if (dialogResponse) {
            if (dialogResponse.backToProducts) {
              this.fromIncidenceDialog = true;
              this.incidenceData = dialogResponse.data;
              this.logger.debug('backToProducts', dialogResponse.data);
            } else {
              this.dialogRef.close(true);
            }
          } else {
            this.dialogRef.close(false);
            this.incidenceService.deleteCodIncidencia(this.incidenceData.incidence.id).subscribe(
              deleteResponse => this.logger.debug('Deleted incidence', deleteResponse),
              error => this.logger.error(error),
            );
          }
        });
      },
      error => {
        this.logger.error(error);

        Swal.fire({
          icon: 'error',
          title: 'Lo sentimos...',
          text: 'Ocurrió un error al actualizar los productos de la incidencia.'
        });
      }
    );
  }

  checkSeller(row) {
    // const hasMultiSeller = this.order.multiseller && this.order.multiseller.length > 1;

    if (row.seller !== this.currentSeller && this.selection.isEmpty()) {
      this.currentSeller = row.seller;
    }

    if (row.seller !== this.currentSeller) {
      Swal.fire({
        icon: 'info',
        title: 'Solo se puede generar una incidencia por Seller.',
        text: 'Por favor, selecciona solo productos del mismo Seller o crea una incidencia por cada uno.',
        showConfirmButton: true,
        confirmButtonColor: '#20a8d8',
        confirmButtonText: 'Ok',
        customClass: {
          title: 'custom-title-seller',
          content: 'custom-text-seller',
        },
      });

      row.selected = false;
      this.selection.deselect(row);
    } else {
      row.selected = !row.selected;
      if (row.selected) {
        this.selection.select(row);
      } else {
        this.selection.deselect(row);
      }
    }

    this.logger.debug('row', row, this.selection.isSelected(row));
    return true;
  }
}
