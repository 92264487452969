import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../services/auth/auth-service.service";
import { NotificationService } from 'src/app/services/fcm/notification.service';
import { NGXLogger } from 'ngx-logger';
import { IncidenceService } from 'src/app/services/service.index';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ModalDetalleIncComponent } from 'src/app/components/modal-detalle-inc/modal-detalle-inc.component';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { decode } from 'js-base64';
import { ModalPasswordChangeComponent } from 'src/app/components/modal-password-change/modal-password-change.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: any;
  notifications: any[] = [];
  notificationCounter: number = 0;

  constructor(
    public authService: AuthService,
    private notificationService: NotificationService,
    private incidenceService: IncidenceService,
    private dialog: MatDialog,
    private overlay: Overlay,
    private logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user: any) => {
      this.user = user;

      // Request device permission with current user
      this.notificationService.requestPermission(user.id);
      // Receive messages from FCM
      this.notificationService.receiveMessage();

      // Subscribe for new messages
      this.notificationService.currentMessage.subscribe(
        message => {
          this.logger.debug('message', message);
          if (message) {
            // Add message info to notifications list
            if (message.data.reset) {
              this.notificationCounter = 0;
            } else if (message.data.visited) {
              this.notifications = this.notifications.filter(item => item.id !== +message.data.id);
              this.notificationCounter--;
            } else {
              const filtered: any[] = this.notifications.filter(item => item.hash === message.data.hash);

              if (filtered.length === 0) {
                this.notifications.unshift({
                  id: +message.data.id,
                  stateId: +message.data.stateId,
                  trackingId: +message.data.trackingId,
                  code: message.data.code,
                  message: decode(message.data.message),
                  hash: message.data.hash,
                });

                this.notificationCounter++;
              }
            }
          }
        },
      );

      const filters = {
        idTab: 2,
        username: this.user.username,
        visited: false,
        allDetails: true,
        includeAllColumns: true,
      };

      const pageInfo = {
        orderDir: 'DESC',
        orderColumn: 'CREATED_ON',
        pageNumber: 1,
        pageSize: 50,
      };

      this.incidenceService.obtainIncidencesByFilters(filters, pageInfo).subscribe(
        (incidences: any[]) => {
          this.logger.debug('incidences no visited', incidences);
          if (incidences.length > 0) {
            incidences.forEach(element => {
              this.notifications.push({
                id: element.idIncidence,
                code: element.codIncidence,
                message: element.message,
                stateId: element.stateId,
                trackingId: element.id,
              });
            });

            this.notificationCounter = incidences.length;
          }
        },
      );
    });
  }

  async openIncidenceDetailDialog(incidence: any) {
    if (incidence.stateId === environment.commons.states.REGISTERED) {
      Swal.fire({
        allowOutsideClick: false,
        icon: 'info',
        text: 'Actualizando estado',
      });

      Swal.showLoading();

      await this.incidenceService.changeState({
        trackingIncidence: { id: incidence.trackingId },
        state: { id: environment.commons.states.IN_REVIEW },
        username: this.user.username,
      })
      .toPromise()
      .then(response => this.logger.debug('response', response))
      .catch(error => this.logger.error(error))
      ;

      Swal.close();
    }

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalDetalleIncComponent, {
      width: '800px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        incidence: {id: incidence.trackingId},
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        // TODO: Event to load some data
        this.incidenceService.updateIncidenceVisitedValue(incidence.id).subscribe(
          response => {
            this.logger.debug('update validated response', response);
            this.notifications = this.notifications.filter(item => item.id !== incidence.id);
            this.notificationService.visitedNotification(incidence.id);
          },
        );
      }
    });
  }

  ressetCounter() {
    this.notificationCounter = 0;
    this.notificationService.resetNotifications();
  }

  clearNotifications() {
    let counter = 0;
    this.notifications.forEach(async incidence => {
      await this.incidenceService.updateIncidenceVisitedValue(incidence.id).toPromise().then(
        response => {
          this.logger.debug('update validated response', response);
          this.notificationService.visitedNotification(incidence.id);
          counter++;

          if (counter === this.notifications.length) {
            this.notifications = [];
            this.notificationCounter = 0;
          }
        },
      );
    });
  }

  openUserPasswordChangeDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalPasswordChangeComponent, {
      width: '412px',
      height: '517px',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      position: {top: '71px', right: '10px'},
      hasBackdrop: false,
    });
  }
}
