import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NGXLogger } from 'ngx-logger';
import { Restangular } from 'ngx-restangular';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { RESTANGULAR_USER } from 'src/app/app.restangular.config';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-password-change',
  templateUrl: './modal-password-change.component.html',
  styleUrls: ['./modal-password-change.component.scss'],
})
export class ModalPasswordChangeComponent implements OnInit {
  currentPassword: string;
  newPassword1: string;
  newPassword2: string;
  showCurrentPassword: boolean = false;
  showNewPassword1: boolean = false;
  showNewPassword2: boolean = false;
  currentPasswordError: boolean = false;
  password1Error: boolean = false;
  password2Error: boolean = false;
  saving: boolean = false;

  user: any;
  usersBasePath: Restangular;

  term$ = new Subject<string>();
  term1$ = new Subject<string>();
  term2$ = new Subject<string>();
  private checkPasswordSubscription: Subscription;
  private checkPassword1Subscription: Subscription;
  private checkPassword2Subscription: Subscription;

  constructor(
    @Inject(RESTANGULAR_USER) public restangularUserBase,
    private authService: AuthService,
    public logger: NGXLogger,
    public dialogRef: MatDialogRef<ModalPasswordChangeComponent>,
  ) {
    this.usersBasePath = this.restangularUserBase.all('auth').all('v1');

    this.checkPasswordSubscription = this.term$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => {
        this.checkPassword(term);
        return term;
      })
    ).subscribe();

    this.checkPassword1Subscription = this.term1$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => {
        this.password1Error = this.currentPassword === this.newPassword1;
        return term;
      })
    ).subscribe();

    this.checkPassword2Subscription = this.term2$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => {
        this.password2Error = this.newPassword1 !== this.newPassword2;
        return term;
      })
    ).subscribe();
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user: any) => {
      this.user = user;
    });
  }

  save() {
    this.saving = true;
    const data = {
      username: this.user.username,
      password: this.newPassword1,
      sessionUser: this.user.username,
      systemCode: environment.commons.systemCode,
    };

    this.usersBasePath.all('users').all('password').customPUT(data).subscribe(
      response => {
        this.logger.debug('response', response);

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `<strong class='modal-swal-title'>Contraseña cambiada correctamente</strong>`,
          showConfirmButton: false,
          timer: 3000,
        });

        this.saving = false;
        this.dialogRef.close(true);
      },
      error => {
        this.logger.error(error);

        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `<strong class='modal-swal-title'>Error al cambiar la contraseña</strong>`,
          showConfirmButton: false,
          timer: 3000,
        });

        this.saving = false;
        this.dialogRef.close();
      },
    );
  }

  checkPassword(currentPassword) {
    const data = {
      username: this.user.username,
      password: currentPassword,
      sessionUser: this.user.username,
      systemCode: environment.commons.systemCode,
    };

    this.usersBasePath.all('users').all('password').all('check').post(data).subscribe(
      response => {
        this.logger.debug('response', response);
        this.currentPasswordError = !response.data;
      },
      error => {
        this.logger.error(error);
        this.currentPasswordError = true;
      },
    );
  }
}
