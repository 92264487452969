import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from './auth-service.service';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        public router: Router,
        private logger: NGXLogger,
    ) {
    }

    canActivate(
        snapshot: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | Observable<boolean> {
            this.logger.debug('state', state);
            this.logger.debug('snapshot', snapshot);

            const isAuthenticated = this.authService.isAuthenticated();
            const expectedRoles: any[] = snapshot.data.expectedRoles || [];

            if (isAuthenticated) {
                return this.authService.getUser().pipe(
                    map((user: any) => expectedRoles.length > 0 ? user.roles.some(rol => expectedRoles.indexOf(rol) !== -1) : true),
                ).pipe(
                    map(value => value ? true : this.authService.logout({forbidden: true})),
                );
            } else {
                return this.authService.logout();
            }
    }
}
