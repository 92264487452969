import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { UtilService, IncidenceService } from 'src/app/services/service.index';
import { RESTANGULAR_PARAMETER } from 'src/app/app.restangular.config';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import moment from 'moment';

@Component({
  selector: 'app-modal-technical-service',
  templateUrl: './modal-technical-service.component.html',
  styleUrls: ['./modal-technical-service.component.scss']
})
export class ModalTechnicalServiceComponent implements OnInit {

  
  trackinIncidenceState: any;
  trackingIncidence: any;
  reasons: any[];
  user: any;
  states = environment.commons.states;
  codeState : any;

  basePath: any;
  textAreaMinRows: number = environment.textAreaMinRows;
  textAreaMaxRows: number = environment.textAreaMaxRows;
  reasonsCommon = environment.commons.reasons;
  company;
  constructor(
    public incidenciaService: IncidenceService,
    @Inject(RESTANGULAR_PARAMETER) public restangularBase,
    public dialogRef: MatDialogRef<ModalTechnicalServiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utilService: UtilService,
    public logger: NGXLogger,
  ) {
    this.basePath = this.restangularBase.all('parameter').all('parameters').all('v1');
  }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.trackinIncidenceState = {};
    this.trackingIncidence = this.data.trackingIncidence;
    this.codeState = this.data.codeState;
    this.user = localStorage.getItem('username');
    this.logger.debug('trackingIncidence', this.trackingIncidence);

    this.basePath.all('state-reasons').getList().subscribe(
      response => {
        this.logger.debug('response', response);
        this.reasons = response;
      },
      error => this.logger.error(error),
    );
  }

  save(){
    this.trackinIncidenceState.trackingIncidence = { id: this.trackingIncidence.id };
    this.trackinIncidenceState.state = { id: this.codeState };
    this.trackinIncidenceState.username = this.user;

    this.logger.debug('state', this.trackinIncidenceState);
    Swal.fire({
        allowOutsideClick: false,
        icon: 'info',
        text: 'Cargando...'
      });
    Swal.showLoading();
    this.incidenciaService.changeState(this.trackinIncidenceState).subscribe(
      response => {
        Swal.close();
        this.logger.debug('response', response);
        const finalizeData = {
          id: this.trackingIncidence.id,
          proceeded: false,
          closingEndDate:  moment().format('YYYY-MM-DD'),
          returnAmount: null,
          responseMedium: null,
        };
  
        this.logger.debug('Calling to updateFinalizeData method');
        this.updateFinalizeData(this.trackingIncidence.id, finalizeData);
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: `El estado de la incidencia se actualizo correctamente`,
          showConfirmButton: false,
          timer: 1500
        });
        this.dialogRef.close(true);
      },
      error => {
        this.logger.error(error);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `No se pudo actualizar el estado de la incidencia.`,
          showConfirmButton: false,
          timer: 1500
        });
        this.dialogRef.close();
      });

  }

  private async updateFinalizeData(id: number, data: any) {
    this.logger.debug('Inside updateFinalizeData method');
    const response = await this.incidenciaService.updateIncidenceFinalizeValues(id, data);
    const json = await response.json();
    this.logger.debug('updateFinalizeData response', json);
  }

}
