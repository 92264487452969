import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { IncidenceService } from 'src/app/services/service.index';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_USER } from 'src/app/app.restangular.config';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, map, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-modal-user-search',
  templateUrl: './modal-user-search.component.html',
  styleUrls: ['./modal-user-search.component.scss'],
})
export class ModalUserSearchComponent implements OnInit {
  company = '';
  basePath: any;
  userTypeahead: any;
  searching: boolean = false;
  searchFailed: boolean = false;
  users: any[] = [];
  usersSrc: any[] = [];

  constructor(
    public incidenceService: IncidenceService,
    public dialogRef: MatDialogRef<ModalUserSearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RESTANGULAR_USER) public restangularBase,
    public logger: NGXLogger,
  ) {
    this.basePath = this.restangularBase.all('auth').all('v1');
    this.usersSrc = this.data.users || [];

    this.logger.debug('users src', this.usersSrc);
  }

  ngOnInit() {
    this.logger.debug('data', this.data);
    this.company = localStorage.getItem('company');
  }

  searchUsers = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(keyword => keyword.length < 3 ? [] :
        this.basePath.all('users').all('search').all('by-company').all(this.company).getList({keyword}).pipe(
          map((response: any[]) => response.length > 0 ? response : [null]),
          tap(() => {
            this.searchFailed = false;
          }),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }),
        ),
      ),
      tap(() => this.searching = false),
    )

  formatterUser = (user: { email: string }) => user.email;

  selectUser($event) {
    $event.preventDefault();
    let user: any = {};
    this.logger.debug('selectUser', $event, this.userTypeahead);

    if ($event.item) {
      user = $event.item;
    }

    if (user.email) {
      const findSrc = this.usersSrc.find(item => item.id === user.id);

      if (findSrc) {
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: `<strong class='swal2-title'>El usuario ya se encuentra en el equipo</strong>`,
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 3000,
        });
      } else {
        const findCurrent = this.users.find(item => item.id === user.id);

        if (!findCurrent) {
          this.users.push(user);
        }
      }
    }

    this.userTypeahead = '';
  }

  dropItemTo(index: any) {
    this.users.splice(index, 1);
  }

  save(form: NgForm) {
    this.dialogRef.close(this.users);
  }

}
