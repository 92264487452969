import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { UtilService, IncidenceService } from 'src/app/services/service.index';
import { RESTANGULAR_PARAMETER } from 'src/app/app.restangular.config';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-invalidate-incidence',
  templateUrl: './modal-invalidate-incidence.component.html',
  styleUrls: ['./modal-invalidate-incidence.component.scss'],
})
export class ModalInvalidateIncidenceComponent implements OnInit {
  trackinIncidenceState: any;
  trackingIncidence: any;
  reasons: any[];
  user: any;
  states = environment.commons.states;

  basePath: any;
  textAreaMinRows: number = environment.textAreaMinRows;
  textAreaMaxRows: number = environment.textAreaMaxRows;
  reasonsCommon = environment.commons.reasons;
  company;
  saving: boolean = false;

  constructor(
    public incidenciaService: IncidenceService,
    @Inject(RESTANGULAR_PARAMETER) public restangularBase,
    public dialogRef: MatDialogRef<ModalInvalidateIncidenceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utilService: UtilService,
    public logger: NGXLogger,
  ) {
    this.basePath = this.restangularBase.all('parameter').all('parameters').all('v1');
  }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.trackinIncidenceState = {};
    this.trackingIncidence = this.data.trackingIncidence;
    this.user = localStorage.getItem('username');
    this.logger.debug('trackingIncidence', this.trackingIncidence);

    this.basePath.all('state-reasons').getList().subscribe(
      response => {
        this.logger.debug('response', response);
        this.reasons = response;
      },
      error => this.logger.error(error),
    );
  }

  save() {
    this.saving = true;
    this.trackinIncidenceState.trackingIncidence = { id: this.trackingIncidence.id };
    this.trackinIncidenceState.state = { id: this.states.INVALIDATED };
    this.trackinIncidenceState.username = this.user;

    this.logger.debug('state', this.trackinIncidenceState);

    this.incidenciaService.changeState(this.trackinIncidenceState).subscribe(
      response => {
        this.logger.debug('response', response);
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: `La incidencia se anuló correctamente`,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 1500
        });

        this.dialogRef.close(true);
      },
      error => {
        this.logger.error(error);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `No se pudo anular la incidencia.`,
          showConfirmButton: false,
          timer: 1500
        });
        this.dialogRef.close();
      });
  }
}
