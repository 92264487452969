import { NgxLoggerLevel } from 'ngx-logger';
import { commons } from './environment.commons';

export const environment = {
  production: false,
  URL_API_ORDERS: 'https://order-prd.cc.cloudintercorpretail.pe/api/customer-care',
  API_UBIGEO: 'https://ubigeo-dev.cc.cloudintercorpretail.pe/api/customer-care',
  API_STORES: 'https://store-dev.cc.cloudintercorpretail.pe/api/customer-care',
  API_SERVICES: 'https://services-prd.cc.cloudintercorpretail.pe/api/customer-care',
  API_CLIENT: 'https://client-dev.cc.cloudintercorpretail.pe/api/customer-care',
  API_LOGIN: 'https://login-dev.cc.cloudintercorpretail.pe/api/customer-care',
  API_PARAMETERS: 'https://parameters-dev.cc.cloudintercorpretail.pe/api/customer-care',
  API_UPLOADFILE: 'https://files-dev.cc.cloudintercorpretail.pe/api/customer-care',
  API_INCIDENCE: 'https://incidence-dev.cc.cloudintercorpretail.pe/api/customer-care',
  API_FCM: 'https://incidence-fcm-dev.cc.cloudintercorpretail.pe',
  API_REPORTS: 'https://incidence-reports-dev.cc.cloudintercorpretail.pe',
  API_CLAIMS: 'https://claims-dev.cc.cloudintercorpretail.pe',
  loggerLevel: NgxLoggerLevel.DEBUG,
  sensediaKey: 'X-sensedia-client-id',
  sensediaValue: 'd962242f-bd82-3067-bfee-2393cf2131ec',
  maxFileSizeMB: +10,
  textAreaMinRows: +5,
  textAreaMaxRows: +10,
  textAreaMaxLength: +1000,
  fcmDB: 'fcmTokens-dev',
  swWorkerEnable: true,
  timeForCheckUpdates: 60000 * 1,
  commons,
};

