import { Component, OnInit, Inject } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { UtilService, SearchIncidenceService } from 'src/app/services/service.index';
import { RESTANGULAR_CLIENT } from 'src/app/app.restangular.config';
import Swal from "sweetalert2";
import { OrderTracking } from 'src/app/models/order-tracking.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-order-register',
  templateUrl: './modal-order-register.component.html',
  styleUrls: ['./modal-order-register.component.scss'],
  providers: [
    NGXLogger,
  ],
})
export class ModalOrderRegisterComponent implements OnInit {
  order: any = {
    type: { id: 1, name: 'Online', value: 'Online' },
  };
  orderTypes: any[] = [
    { id: 1, name: 'Online', value: 'Online' },
    { id: 2, name: 'Offline', value: 'Offline' },
  ];
  client: any;

  basePath: any;

  constructor(
    @Inject(RESTANGULAR_CLIENT) public restangularBase,
    public utilService: UtilService,
    public searchIncidenceService: SearchIncidenceService,
    public dialogRef: MatDialogRef<ModalOrderRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public logger: NGXLogger,
  ) {
    this.basePath = this.restangularBase.all('orders-tracking').all('v1');
  }

  ngOnInit() {
    this.client = this.data.client;
  }

  saveOrder() {
    this.logger.debug("order", this.order);

    Swal.fire({
      text: '¿Estás seguro que quieres registrar el pedido? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        const order = {
          orderId: this.order.orderId,
          type: this.order.type.name,
          store: this.order.store,
          dateSale: this.order.dateSale,
          client: { id: this.client.id },
        };

        this.basePath.post(order).subscribe(
          (response: OrderTracking) => {
            this.logger.debug('save order response', response);
            Swal.fire(`Se registró el pedido N° ${response.orderId}`);

            response.numberDocumentIdentity = this.client.numDoc;
            response.nameCustomer =  this.client.name;
            this.searchIncidenceService.ordersTracking.push(response);
            this.dialogRef.close();
          },
          error => {
            this.logger.error(error);
            Swal.fire('Error', 'Ocurrió un error al intentar guardar el pedido', 'error');
            this.dialogRef.close();
          },
        );
      }
    });
  }
}
