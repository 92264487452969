import { Component, OnInit, Inject, Injectable, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

// models
import { SearchModel } from '../../models/search.model';
import { OrdersModels } from '../../models/orders.models';
import { ClienteModels } from '../../models/cliente.models';
import { Incidenciasearch } from '../../models/incidenciasearch.models';

// services
import { SearchService } from '../../services/incidencia/search.service';
import { ClienteService } from '../../services/incidencia/cliente.service';
import { IncidenceService } from '../../services/incidencia/incidence.service';

// library
import Swal from 'sweetalert2';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_CLIENT, RESTANGULAR_INCIDENCE } from 'src/app/app.restangular.config';
import { OrderTracking } from 'src/app/models/order-tracking.model';
import { MatDialog } from '@angular/material/dialog';
import { ModalIncidenceComponent } from 'src/app/components/modal-incidence/modal-incidence.component';
import { Overlay } from '@angular/cdk/overlay';
import { ModalDetalleComponent } from 'src/app/components/modal-detalle/modal-detalle.component';
import { ModalDetalleIncComponent } from 'src/app/components/modal-detalle-inc/modal-detalle-inc.component';
import { ModalOrderRegisterComponent } from 'src/app/components/modal-order-register/modal-order-register.component';
import { UtilService } from 'src/app/services/util/util.service';
import { MatTableDataSource } from '@angular/material/table';
import { NgbCalendar, NgbDateParserFormatter, NgbDate, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import users from './users.json';

import moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { AuthService } from 'src/app/services/service.index';
import { ModalClaimDetailComponent } from '../claims/modals/modal-claim-detail/modal-claim-detail.component';
import { environment } from 'src/environments/environment';

const I18N_VALUES = {
  'es': {
    weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  },
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-search-incidence',
  templateUrl: './search-incidence.component.html',
  styleUrls: ['./search-incidence.component.scss'],
  providers: [
    NGXLogger,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class SearchIncidenceComponent implements OnInit {
  company: any = null;
  listOrders: any[] = [];
  ordersTracking: any[] = [];

  listIncidences: any[] = [];
  searchModel: SearchModel = new SearchModel();
  incidenciaSearch: Incidenciasearch = new Incidenciasearch();

  orders: OrdersModels;
  client: any;

  listDeps: any[] = [];
  listProv: any[] = [];
  listProv2: any[] = [];
  listDist: any[] = [];
  distrito: any;

  itemDepartamento: any;
  itemProvincias: any;
  itemDistrict: any;

  optionCombo: any[] = [
    {
      id: 1,
      name: 'N° Doc. Identidad',
    },
    {
      id: 2,
      name: 'N° Pedido',
      documentType: 'NP',
      orderType: 'EC',
    },
    {
      id: 3,
      name: 'N° Nota de Venta',
      documentType: 'NV',
      orderType: 'TD',
    },
    {
      id: 4,
      name: 'N° OP',
      documentType: 'OP',
      orderType: 'TD',
    },
  ];
  select: any;
  provincia = "0";
  district = "0";
  departamento = "0";
  clientsBasePath: any;
  ordersTrackingBasePath: any;
  incidenceBasePath: any;

  companies: any[] = [
    {
      code: 'ecmx',
      name: 'Economax',
    },
    {
      code: 'mass',
      name: 'Mass',
    },
    {
      code: 'tpsa',
      name: 'Oechsle',
    },
    {
      code: 'pvea',
      name: 'Plaza Vea',
    },
    {
      code: 'hpsa',
      name: 'Promart',
    },
    {
      code: 'rplaza',
      name: 'Real Plaza',
    },
    {
      code: 'viv',
      spsaCode: 'viv',
      name: 'Vivanda',
    },
    /*{
      code: 'mkr',
      name: 'Makro',
    },*/
  ];

  identificationType: any = '';
  identificationTypes: any[] = [];

  displayedOrderColumns = [
    'orderId', 'store', 'orderType', 'statusOrder', 'dateSale', 'create', 'details',
  ];
  orderDataSource;

  incidenceDataSource = new MatTableDataSource([]);

  loadingOrders: boolean = false;
  loadingIncidences: boolean = false;

  ordersFromDate: NgbDate | null;
  ordersToDate: NgbDate | null;
  hoveredOrdersDate: NgbDate | null = null;

  incidencesFromDate: NgbDate | null;
  incidencesToDate: NgbDate | null;
  hoveredIncidencesDate: NgbDate | null = null;
  filterIncidenceCode: string;

  filteredOrdersData: boolean = false;
  filteredIncidencesData: boolean = false;

  isFullSearch: boolean = false;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('sortIncidence', {static: true}) sortIncidence: MatSort;

  constructor(
    public searchService: SearchService,
    public clienteService: ClienteService,
    public incidenceService: IncidenceService,
    public utilService: UtilService,
    @Inject(RESTANGULAR_CLIENT) public restangularBase,
    @Inject(RESTANGULAR_INCIDENCE) public restangularIncidenceBase,
    private dialog: MatDialog,
    private overlay: Overlay,
    public logger: NGXLogger,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private authService: AuthService,
  ) {
    this.clientsBasePath = this.restangularBase.all('client').all('v1');
    this.ordersTrackingBasePath = this.restangularBase.all('orders-tracking').all('v1');
    this.incidenceBasePath = this.restangularIncidenceBase.all('incidence').all('v1');

    // this.ordersFromDate = calendar.getNext(calendar.getToday(), 'd', -30);
    // this.ordersToDate = calendar.getToday();
  }

  ngOnInit(): void {
    this.select = 0;
    this.client = new ClienteModels();
    this.listDeps = [];
    this.listProv = [];
    this.listProv2 = [];
    this.listDist = [];

    this.authService.getUser().subscribe(user => {
      this.searchModel.companycod = user.company;
      this.company = this.companies.find(item => item.code === user.company);
      this.updateCompany();
    });

    this.loadDepartamentos();
    this.loadIdentificationTypes();
  }

  clearLists() {
    this.listOrders = [];
    this.listIncidences = [];
    this.ordersTracking = [];
    this.identificationType = '';
    this.departamento = '0';
    this.provincia = '0';
    this.district = '0';

    this.orderDataSource = new MatTableDataSource([]);
    this.incidenceDataSource = new MatTableDataSource([]);
  }

  searchByNumDoc(form: NgForm) {
    this.logger.debug('identificationType', this.identificationType);
    const selectValue = +this.select;

    // valida combo tipo doc
    if (selectValue === 0) {
      this.msgTipoBusqueda();
      return;
    }

    // validamos si el campo doc esta lleno
    if (form.invalid) {
      const text = selectValue === 1 ? 'documento de identidad' : 'número de pedido';
      this.msgWarning(`Ingresa un ${text} para realizar la búsqueda`);
      return;
    }

    // limpiamos las listas y el cliente
    this.client = new ClienteModels();
    this.clearLists();

    this.msgSearching();

    this.searchModel.type = selectValue;

    if (selectValue === 1) { // busqueda por numero de documento
      this.isFullSearch = true;
      // primero buscamos en nuestra BD
      this.clienteService.obtainCliente(this.searchModel.numdoc).subscribe((client: any) => {
        this.logger.debug('client', client);

        if (client) { // si no es nulo existe en la BD
          this.client = client;

          if (client.lastname && this.client.lastname.length > 0) {
            this.client.name =  `${this.client.name} ${client.lastname}`;
            this.client.lastname = null;
          }

          if (this.client.identificationType) {
            this.identificationType = this.client.identificationType.id;
          }

          this.loadUbigeo(this.client.codUbigeo);

          // Search incidences
          this.searchIncidencesByClient();

          // Search orders
          this.obtainOrdersByNumDoc(this.client.numDoc, false);
        } else { // no existe en la BD y debemos de crear y guardar el registro
          this.obtainOrdersByNumDoc(this.searchModel.numdoc, true);
        }
      },
      error => {
        this.logger.error(error);
        // no existe en la BD y debemos de crear y guardar el registro
        this.obtainOrdersByNumDoc(this.searchModel.numdoc, true);
      });
    } else if (selectValue === 2 || selectValue === 3 || selectValue === 4) { // BUSQUEDA POR NUMERO DE PEDIDO | NV | OP
      this.isFullSearch = false;
      const selectObj = this.optionCombo.find(item => item.id === selectValue);
      this.orders = new OrdersModels();
      this.orders.orderType = selectObj.orderType;
      this.orders.documentType = selectObj.documentType;
      this.orders.orderId = this.searchModel.numdoc;
      this.logger.debug('orders', this.orders);
      this.obtainDataClientByCodPedido(this.orders);
      Swal.close();
    }
  }

  obtainOrdersByNumDoc(numDoc: string, exist: boolean) {  // OBTENEMOS ORDENES POR NUM DOC
    this.loadingOrders = true;
    this.loadingIncidences = true;
    this.searchService.obtainOrderByDocument(numDoc, this.company.code).subscribe(
      orders => {
        this.logger.debug('obtainOrdersByNumDoc', orders);
        this.listOrders = orders;
      },
      error => {
        this.logger.error('Error in obtainOrdersByNumDoc', error);
      },
    )
    .add(() => {
      if (this.listOrders && this.listOrders.length > 0) {
        this.obtainOrdenesDetalle(exist);
        Swal.close();
      } else {
        this.obtainOrdersFromTracking();
        this.loadingOrders = false;
      }
    })
    ;
  }

  obtainOrdenesDetalle(exist?: boolean) {  // OBTENEMOS DETALLE DE PEDIDOS
    this.logger.debug('start');
    let order;
    let count = 0;

    this.listOrders.forEach(async val => {
      order = new OrdersModels();
      order.orderId = val.orderId;
      order.orderType = val.orderType;

      const response = await this.searchService.obtainOrderById(order, this.company.code);
      const json = await response.json();
      this.logger.debug('obtainOrderById data', json);

      const data = json.data;

      if (data) {
        const dateValue = moment(data.dateOrder, 'DD/MM/YYYY');
        data.dateSale = dateValue;
        data.statusOrder = this.company.code.toLowerCase() === 'rplaza' ? data.statusOrder : data.statusOrderOld;
        data.store = data.saleEntity;
        data.multiseller = data.lstDetail ? data.lstDetail.map(item => item.seller).filter((value, index, self) => self.indexOf(value) === index) : [];
        Object.assign(val, data);

        if (exist) {
          this.obtainDataClient(data);
          exist = false;
        }
      }

      count++;
      this.logger.debug('count', count);

      if (count === this.listOrders.length) {
        this.logger.debug('final data', this.listOrders);
        this.obtainOrdersFromTracking();
        this.logger.debug('end');
      }
    });
  }

  obtainOrdersFromTracking() {
    if (this.client && this.client.id) {
      this.ordersTrackingBasePath.all('search').all('by-client').one('document', this.client.numDoc).getList().subscribe(
        (response: OrderTracking[]) => {
          this.logger.debug('orders', response);
          if (response.length > 0) {
            response.map((item: any) => {
              item.fromTracking = true;
              return item;
            });

            this.ordersTracking = response;
            this.listOrders = this.listOrders || [];
            this.listOrders.push(...this.ordersTracking);
            this.logger.debug('full order list', this.listOrders);
            Swal.close();
          } else {
            this.showNoDataMessage();
          }
        },
        error => {
          this.logger.error('ERROR', error);
          this.showNoDataMessage();
        },
        () => {
          if (this.listOrders && this.listOrders.length > 0) {
            this.orderDataSource = new MatTableDataSource(this.listOrders);
            this.orderDataSource.sort = this.sort;
          }

          this.loadingOrders = false;
          this.logger.debug('The request has been completed');
        }
      );
    } else {
      this.showNoDataMessage();
    }
  }

  showNoDataMessage() {
    Swal.close();
    if (!this.listOrders || this.listOrders.length <= 0) {
      this.msgWarning(`No encontramos ${this.client.id ? 'pedidos' : 'datos'} del cliente`);
    } else {
      this.orderDataSource = new MatTableDataSource(this.listOrders);
      this.orderDataSource.sort = this.sort;
    }

    this.loadingOrders = false;
    this.loadingIncidences = false;
  }

  obtainDataClient(data: any) { // OBTENEMOS DATOS DE CLIENTE A PARTIR DE DETALLE DE CLIENTES Y GUARDAMOS
    if (!this.client.id) {
      this.getClient(data);
    }
  }

  async obtainDataClientByCodPedido(orders: OrdersModels) { // OBTENEMOS DATOS DE CLIENTE A PARTIR DE DETALLE DE CLIENTES Y GUARDAMOS
    this.loadingOrders = true;
    const response = await this.searchService.obtainOrderById(orders, this.company.code);
    const json = await response.json();
    this.logger.debug('obtainOrderById data', json);

    const data = json.data;

    if (data) {
      if (this.listOrders && this.listOrders.length <= 0) {
        this.listOrders = [];
      }

      const dateValue = moment(data.dateOrder, 'DD/MM/YYYY');
      data.dateSale = dateValue;
      data.statusOrder = this.company.code.toLowerCase() === 'rplaza' ? data.statusOrder : data.statusOrderOld;
      data.store = data.saleEntity;
      data.multiseller = data.lstDetail ? data.lstDetail.map(item => item.seller).filter((value, index, self) => self.indexOf(value) === index) : [];

      this.listOrders.push(data);
      this.orderDataSource = new MatTableDataSource(this.listOrders);
      this.orderDataSource.sort = this.sort;
      this.client.numDoc = data.numberDocumentIdentity;
      this.loadingOrders = false;
      this.getClient(data);
    } else {
      this.showNoOrdersMessage();
    }
  }

  getClient(data) {
    if (data.corporateDocument && data.corporateDocument.trim().length > 0) {
      this.client.numDoc = data.corporateDocument;
    }

    this.clienteService.obtainCliente(this.client.numDoc).subscribe(
      (client: any) => {
        this.logger.debug('obtainCliente response', client);

        if (client) {
          this.client = client;
          this.loadUbigeo(this.client.codUbigeo);
          this.searchIncidencesByClient();
        } else {
          this.createClient(data);
        }
      },
      error => {
        this.createClient(data);
      },
    );
  }

  createClient(data) {
    const client: any = {};
    client.name = data.nameCustomer;
    client.email = data.emailCustomer;
    client.phone = data.phoneCustomer;
    client.address = data.addressDelivery;
    client.typeDoc = data.documentType;
    client.numDoc = data.numberDocumentIdentity;

    if (data.corporateDocument && data.corporateDocument.trim().length > 0) {
      client.name = data.corporateName;
      client.numDoc = data.corporateDocument;
    }

    this.clienteService.newCiente(client).subscribe(
      (saved: any) => {
        this.logger.debug('saved client', saved);
        this.client = saved;
        this.loadUbigeo(this.client.codUbigeo);
        this.searchIncidencesByClient();
      },
      error => this.logger.error(error),
    );
  }

  showNoOrdersMessage() {
    this.loadingOrders = false;
    this.msgWarning('No se encontraron compras relacionadas al pedido');
  }

  searchIncidencesByClient() {
    this.loadingIncidences = true;

    if (this.client && this.client.numDoc) {
      const request = +this.select === 1 ?
        this.incidenceService.obtainAllDataByClient(this.client.numDoc) :
        this.incidenceService.obtainIncidencesByClient(this.client.numDoc);

      request.subscribe(
        incidences => {
          this.logger.debug('response obtainIncidencesByClient', incidences);
          this.listIncidences = incidences;
          this.incidenceDataSource = new MatTableDataSource(this.listIncidences);
          this.incidenceDataSource.sort = this.sortIncidence;
          this.loadingIncidences = false;
        },
        error => {
          this.loadingIncidences = false;
          this.logger.error(error);
        }
      );
    } else {
      this.loadingIncidences = false;
    }
  }

  getDisplayedColumns(): string[] {
    return this.isFullSearch ?
      ['prioritize', 'code', 'type', 'createdDate', 'orders', 'reasonName', 'subReasonName', 'createdBy', 'currentStatusName', 'recontact', 'options'] :
      ['prioritize', 'code', 'createdDate', 'orders', 'reasonName', 'subReasonName', 'createdBy', 'currentStatusName', 'recontact', 'options'];
  }

  guardarDatos(form: NgForm) {
    if (form.invalid) {
      Swal.fire({
        allowOutsideClick: false,
        icon: 'warning',
        text: 'Debe llenar todos los campos',
      });
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando...',
    });

    Swal.showLoading();

    this.client.codUbigeo = this.district != null ? this.district.toString() : null;
    this.client.typeDoc = 'DNI';
    this.client.numDoc = this.client.numDoc || this.searchModel.numdoc;

    const select = +this.select;
    if (select === 1 && Number(this.identificationType) > 0) {
      this.client.identificationType = { id: +this.identificationType };
    }

    this.clienteService.saveClient(this.client).subscribe((response: any) => {
      this.client = response;

      Swal.fire({
        position: 'center',
        icon: 'success',
        title: `Se guardaron los datos correctamente`,
        showConfirmButton: false,
        timer: 1500,
      });
    }, error => this.logger.error(error));
  }

  msgWarning(text: string) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text,
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
    });
  }

  loadDepartamentos() {
    return this.searchService.obtainDepartamentos().subscribe((resp: any) => {
      this.listDeps = resp.data;
    }, error => this.logger.debug(error));
  }

  loadProvincias() {
    return this.searchService.obtainProvincias(this.departamento.toString()).subscribe((resp: any) => {
      this.listProv = resp.data;
    }, error => this.logger.error(error));
  }

  loadDistritos() {
    return this.searchService.obtainProvincias(this.departamento.toString()).subscribe(
      (resp: any) => {
        this.listProv2 = resp.data;
        if (this.listProv2 && this.listProv2.length > 0) {
          this.listDist = this.listProv2.filter(value => value.code === this.provincia);
          this.distrito = this.listDist[0];
          this.listDist = this.distrito.districts;
        }
      },
      error => this.logger.error(error),
    );
  }

  loadUbigeo(ubigeo: string) {
    if (ubigeo) {
      const dep = ubigeo.substr(0, 2);
      this.loadDepartamentos();
      this.departamento = dep;

      const prov = ubigeo.substr(0, 4);
      this.loadProvincias();
      this.provincia = prov;

      const dist = ubigeo;
      this.loadDistritos();
      this.district = dist;
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 30000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });

      Toast.fire({
        icon: 'warning',
        title: 'Cliente no tiene ubigeo'
      });
    }
  }

  msgTipoBusqueda() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Selecciona un tipo de búsqueda',
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
    });
  }

  msgSearching() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Buscando...'
    });
    Swal.showLoading();
  }

  updateCompany() {
    this.logger.debug('company', this.company);

    if (this.company) {
      localStorage.setItem('company', this.company.code);
      localStorage.setItem('companyFull', JSON.stringify(this.company));
    }
  }

  openIncidenceDialog(order?: any, products?: any[]) {
    if (!this.client || !this.client.id) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Debe ingresar un cliente antes de crear incidencias',
        showConfirmButton: true,
        confirmButtonColor: '#20a8d8',
        confirmButtonText: 'Ok',
        timer: 3000,
      });

      return;
    }

    Swal.fire({
      title: '¿Deseas generar una incidencia?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        products = products || [];

        if (products.length <= 0 && order) {
          this.logger.debug('order', order);

          if (order.lstDetail) {
            order.lstDetail.forEach(product => {
              if (!environment.commons.productExcludeCodes.includes(product.productCode)) {
                products.push({
                  orderId: product.orderIdChild,
                  origin: product.originProduct,
                  seller: product.seller,
                  quantity: +product.pickingQuantity,
                  listPriceUnit: +product.listPriceUnit,
                  discount: +product.discount,
                  totalPrice: +product.totalPrice,
                  product: {
                    code: product.productCode,
                    name: product.productName,
                  },
                });
              }
            });
          }
        }

        this.logger.debug('products for incidence', products);

        this.incidenceService.getCodNewIncidence(this.client.numDoc, this.company.code, products, products.length > 0 ? environment.commons.associateType.ORDER : null)
        .subscribe(
          response => {
            const id = +response.id;
            const code = response.code;
            this.logger.debug('new code', id, code);

            const scrollStrategy = this.overlay.scrollStrategies.block();
            const dialogRef = this.dialog.open(ModalIncidenceComponent, {
              width: '1010px',
              maxHeight: '100vh',
              disableClose: true,
              autoFocus: false,
              scrollStrategy,
              data: {
                id,
                code,
                client: this.client,
                order,
                orderId: products.length > 0 ? products[0].orderId : null,
              },
            });

            dialogRef.afterClosed().subscribe(dialogResponse => {
              this.logger.debug('inside dialog search response', dialogResponse);
              if (dialogResponse) {
                this.searchIncidencesByClient();
              } else {
                this.incidenceService.deleteCodIncidencia(id).subscribe(
                  deleteResponse => this.logger.debug('Deleted incidence', deleteResponse),
                  error => this.logger.error(error),
                );
              }
            });
          },
          error => this.logger.error(error),
        );
      }
    });
  }

  openOrderDetailsDialog(order, viewProducts?: boolean) {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalDetalleComponent, {
      width: '800px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        order,
        client: this.client,
        document: this.searchModel.numdoc,
        viewProducts,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.searchIncidencesByClient();
      }
    });
  }

  openIncidenceDetailDialog(element) {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando detalles',
    });
    Swal.showLoading();

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalDetalleIncComponent, {
      width: '800px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        incidence: element,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.searchIncidencesByClient();
      }
    });
  }

  openClaimDetailDialog(element) {
    element.company = this.company.code;
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando detalles',
    });
    Swal.showLoading();

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalClaimDetailComponent, {
      width: '986px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        claim: element,
        fromIncidenceModule: true,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.searchIncidencesByClient();
      }
    });
  }

  openOrderRegisterDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalOrderRegisterComponent, {
      width: '800px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        client: this.client,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.searchIncidencesByClient();
      }
    });
  }

  loadIdentificationTypes() {
    this.clientsBasePath.all('identification-types').getList().subscribe(
      response => this.identificationTypes = response,
      error => this.logger.error(error),
    );
  }

  onOrderDateSelection(date: NgbDate) {
    if (!this.ordersFromDate && !this.ordersToDate) {
      this.ordersFromDate = date;
    } else if (this.ordersFromDate && !this.ordersToDate && date && date.after(this.ordersFromDate)) {
      this.ordersToDate = date;
    } else {
      this.ordersToDate = null;
      this.ordersFromDate = date;
    }

    // Filter orders datatable
    this.logger.debug('from', this.ordersFromDate, 'to', this.ordersToDate);

    this.orderDataSource.filterPredicate = (data, filter) => {
      if (this.ordersFromDate && this.ordersToDate) {
        return moment(data.dateSale).isBetween(
          moment({ year: this.ordersFromDate.year, month: this.ordersFromDate.month - 1, day: this.ordersFromDate.day }),
          moment({ year: this.ordersToDate.year, month: this.ordersToDate.month - 1, day: this.ordersToDate.day }),
          'days',
          '[]',
        );
      }

      return true;
    };

    this.orderDataSource.filter = '' + Math.random();
    this.filteredOrdersData = true;
  }

  clearFromOrdersFilters() {
    this.ordersFromDate = null;
    this.ordersToDate = null;
    this.orderDataSource.filter = '' + Math.random();
    this.filteredOrdersData = false;
  }

  clearToOrdersFilters() {
    this.ordersToDate = null;
  }

  isHoveredOrdersDate(date: NgbDate) {
    return this.ordersFromDate && !this.ordersToDate && this.hoveredOrdersDate && date.after(this.ordersFromDate) && date.before(this.hoveredOrdersDate);
  }

  isInsideOrders(date: NgbDate) {
    return this.ordersToDate && date.after(this.ordersFromDate) && date.before(this.ordersToDate);
  }

  isRangeOrders(date: NgbDate) {
    return date.equals(this.ordersFromDate) || (this.ordersToDate && date.equals(this.ordersToDate)) || this.isInsideOrders(date) || this.isHoveredOrdersDate(date);
  }

  onIncidenceDateSelection(date: NgbDate) {
    if (!this.incidencesFromDate && !this.incidencesToDate) {
      this.incidencesFromDate = date;
    } else if (this.incidencesFromDate && !this.incidencesToDate && date && date.after(this.incidencesFromDate)) {
      this.incidencesToDate = date;
    } else {
      this.incidencesToDate = null;
      this.incidencesFromDate = date;
    }

    // Filter incidence datatable
    this.logger.debug('from', this.incidencesFromDate, 'to', this.incidencesToDate);

    this.incidenceDataSource.filterPredicate = (data, filter) => {
      if (this.incidencesFromDate && this.incidencesToDate) {
        return moment(data.createdOn).isBetween(
          moment({ year: this.incidencesFromDate.year, month: this.incidencesFromDate.month - 1, day: this.incidencesFromDate.day }),
          moment({ year: this.incidencesToDate.year, month: this.incidencesToDate.month - 1, day: this.incidencesToDate.day }),
          'days',
          '[]',
        ) && (this.filterIncidenceCode && this.filterIncidenceCode.trim().length > 0 ? data.codIncidence.includes(this.filterIncidenceCode) : true);
      }

      return true;
    };

    this.incidenceDataSource.filter = '' + Math.random();
    this.filteredIncidencesData = true;
  }

  clearFromIncidencesFilters() {
    this.incidencesFromDate = null;
    this.incidencesToDate = null;
    this.incidenceDataSource.filter = '' + Math.random();
    this.filteredIncidencesData = false;
  }

  clearToIncidencesFilters() {
    this.incidencesToDate = null;
  }

  isHoveredIncidencesDate(date: NgbDate) {
    return this.incidencesFromDate && !this.incidencesToDate && this.hoveredIncidencesDate && date.after(this.incidencesFromDate) && date.before(this.hoveredIncidencesDate);
  }

  isInsideIncidences(date: NgbDate) {
    return this.incidencesToDate && date.after(this.incidencesFromDate) && date.before(this.incidencesToDate);
  }

  isRangeIncidences(date: NgbDate) {
    return date.equals(this.incidencesFromDate) || (this.incidencesToDate && date.equals(this.incidencesToDate)) || this.isInsideIncidences(date) || this.isHoveredIncidencesDate(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    this.logger.debug('validateInput', currentValue, input);
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : null;
  }

  filterIncidencesByCode() {
    if (this.filterIncidenceCode && this.filterIncidenceCode.trim().length > 0) {
      this.incidenceDataSource.filterPredicate = (data, filter) => {
        if (this.incidencesFromDate && this.incidencesToDate) {
          return moment(data.createdOn).isBetween(
            moment({ year: this.incidencesFromDate.year, month: this.incidencesFromDate.month - 1, day: this.incidencesFromDate.day }),
            moment({ year: this.incidencesToDate.year, month: this.incidencesToDate.month - 1, day: this.incidencesToDate.day }),
            'days',
            '[]',
          ) && data.codIncidence.includes(this.filterIncidenceCode);
        } else {
          return data.codIncidence.includes(this.filterIncidenceCode);
        }

        return true;
      };

      this.incidenceDataSource.filter = '' + Math.random();
      this.filteredIncidencesData = true;
    }
  }

  checkClearIncidencesSearch() {
    if (this.filterIncidenceCode.trim().length === 0) {
      this.incidenceDataSource.filter = '' + Math.random();
      this.filteredIncidencesData = false;
    }
  }

  saveUsersToBD(): void {
    const data = users.users;
    this.logger.debug('promart', data);

    const url = 'https://login-prd.cc.cloudintercorpretail.pe/api/customer-care/auth/v1/users';
    let count = 0;

    data.forEach(user => {
      user.email = user.email.trim();
      user.names = user.names.trim();
      user.username = user.username.trim();
      user.password = user.password.trim();

      const request = new Request(url, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify(user),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      (async () => {
        const response = await fetch(request);
        const json = await response.json();
        this.logger.debug('response data', json);
        count++;

        if (count === data.length) {
          this.logger.debug('Load promart user has finished.');
        }
     })();
    });
  }

}
