import { ApplicationRef, Component } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NGXLogger } from 'ngx-logger';
import { interval } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { SharedService } from './services/shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Tracking de Incidencias';

  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private logger: NGXLogger,
    private sharedService: SharedService,
  ) {
    this.appRef.isStable.pipe(
      first(stable => stable),
      tap(stable => {
        this.logger.debug('App is stable now', stable);

        this.sharedService.setUpdatedLater(false);
        const updated = localStorage.getItem('updated') == 'true';

        if (updated) {
          this.activatedMessage();
        }
      }),
      switchMap(() => interval(environment.timeForCheckUpdates)),
    ).subscribe(counter => {
      this.logger.debug(`enabled ${counter}`, this.updates.isEnabled);
      const token = localStorage.getItem('token');

      if (this.updates.isEnabled && token) {
        this.updates.checkForUpdate();
      }
    });
  }

  activatedMessage() {
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: 'Se realizó la actualización de la plataforma correctamente',
      showCancelButton: false,
      confirmButtonColor: '#49a2b6',
      confirmButtonText: 'Ok',
      customClass: {
        title: 'sweet-promptuser-title-updated',
        content: 'sweet-promptuser-content',
        confirmButton: 'sweet-promptuser-confirmbtn',
        cancelButton: 'sweet-promptuser-cancelbtn',
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then(() => {
      localStorage.setItem('updated', 'false');
      this.sharedService.setUpdatedLater(false);
    });
  }
}
