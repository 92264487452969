import { Inject, Injectable } from '@angular/core';
import { ClienteModels } from "../../models/cliente.models";
import { RESTANGULAR_CLIENT, RESTANGULAR_SERVICES } from 'src/app/app.restangular.config';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  clientsBasePath: any;
  clientServiceBasePath: any;

  constructor(
    @Inject(RESTANGULAR_CLIENT) public restangularClientBase,
    @Inject(RESTANGULAR_SERVICES) public restangularServiceBase,
  ) {
    this.clientsBasePath = this.restangularClientBase.all('client').all('v1');
    this.clientServiceBasePath = this.restangularServiceBase.all('v1');
  }

  obtainCliente(numdoc: string) {
    return this.clientsBasePath.one('', numdoc).get();
  }

  saveClient(client: ClienteModels) {
    if (client.id && Number(client.id) > 0) {
      return this.updateCiente(client);
    } else {
      return this.newCiente(client);
    }
  }

  newCiente(client: ClienteModels) {
    return this.clientsBasePath.customPOST(client);
  }

  updateCiente(client: ClienteModels) {
    return this.clientsBasePath.all('update').customPOST(client);
  }

  obtainClientFromServices(company: string, document: string) {
    return this.clientServiceBasePath.one('', company).all('services').getList({document});
  }

}
