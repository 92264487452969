import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private filters$ = new Subject();
  private showIncidenceDigalog$ = new Subject();
  private showClaimDigalog$ = new Subject();
  private showUpdateIncidenceDialog$ = new Subject();
  private updatedLater$ = new Subject();
  private productInfo$ = new Subject();
  private showReactivateIncidenceDialog$ = new Subject;
  filters: any = {};
  productInfo : any = [];
  flagProductInfo: boolean = false;

  constructor(
  ) { }

  searchByFilters(filters: any) {
    this.filters = filters;
    this.filters$.next(filters);
  }

  setProductInfo(productInfo: any) {
    this.productInfo = productInfo
  }

  setFlagProductInfo(flagProductInfo: any) {
    this.flagProductInfo = flagProductInfo
  }

  showIncidenceDialog(incidence: any) {
    this.showIncidenceDigalog$.next(incidence);
  }

  showUpdateIncidenceDialog(incidence: any) {
    this.showUpdateIncidenceDialog$.next(incidence);
  }

  showClaimDialog(claim: any) {
    this.showClaimDigalog$.next(claim);
  }

  setUpdatedLater(value: boolean) {
    localStorage.setItem('updated-later', value ? 'true' : 'false');
    this.updatedLater$.next(value);
  }

  showReactivateIncidenceDialog(incidence: any) {
    this.showReactivateIncidenceDialog$.next(incidence);
  }

  getFlagProductInfo() {
    return this.flagProductInfo;
  }

  getFilters() {
      return this.filters$;
  }

  getProductInfo() {
    return this.productInfo$;
  }

  getShowIncidenceDigalog() {
    return this.showIncidenceDigalog$;
  }

  getShowUpdateIncidenceDialog() {
    return this.showUpdateIncidenceDialog$;
  }

  getShowClaimDigalog() {
    return this.showClaimDigalog$;
  }

  getUpdatedLater() {
    return this.updatedLater$;
  }

  getShowReactivateIncidenceDialog() {
    return this.showReactivateIncidenceDialog$;
  }
}
