import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NewIncidence } from "../../models/newIncidence.models";

// SERVICIOS
import { StoreService } from "../../services/stores/store.service";
import { ParametersService } from "../../services/parameters/parameters.service";
import { IncidenceService } from "../../services/incidencia/incidence.service";
import Swal from "sweetalert2";
import { Observable, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_PARAMETER } from 'src/app/app.restangular.config';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ContactsModels } from 'src/app/models/contacts.models';
import { Overlay } from '@angular/cdk/overlay';
import { ModalDestinatariosComponent } from '../modal-destinatarios/modal-destinatarios.component';
import { ModalOstRegisterComponent } from '../modal-ost-register/modal-ost-register.component';
import { AppendFilesComponent } from 'src/app/shared/append-files-module/append-files.component';
import { FileObject } from 'src/app/models/file-object.model';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { environment } from 'src/environments/environment';
import { UtilService } from 'src/app/services/service.index';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { SharedService } from 'src/app/services/shared/shared.service';

const I18N_VALUES = {
  'es': {
    weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  },
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-modal-product-info',
  templateUrl: './modal-product-info.component.html',
  styleUrls: ['./modal-product-info.component.scss'],
  providers: [
    NGXLogger,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class ModalProductInfoComponent implements OnInit {
  newIncidence: NewIncidence = new NewIncidence();
  observations: string;
  logo = '';

  disabledCbo = false;
  disabledCboArea2 = true;

  company = '';

  stores: any[] = [];
  sellerCenter: any[] = [];

  listMotivos: any[] = [];
  listSubMotivos: any[] = [];
  listSubM: any[] = [];
  listFormat: any[] = [];
  listDeliveryMethod: any[] = [];
  listArea1: any[] = [];
  listArea2: any[] = [];
  sources: any[] = [];
  requestTypes: any[] = [];
  divisionTypes: any[] = [];
  zones: any[] = [];

  viewSPSA = false;

  store: any;
  subStore: any;
  reason: any;
  subReason: any;
  area1: any;
  area2: any;
  requestType: any;
  format: any;
  deliveryMethod: any;
  source: any;
  clientNotify: boolean = true;
  divisionType: any;
  zone: any;
  flagShowZones: boolean = false;

  filteredStores: Observable<any[]>;
  filteredSubStores: Observable<any[]>;
  filteredReasons: Observable<any[]>;
  filteredSubReasons: Observable<any[]>;
  filteredAreas1: Observable<any[]>;
  filteredAreas2: Observable<any[]>;
  filteredFormats: Observable<any[]>;
  filteredDeliveryMethods: Observable<any[]>;
  filteredSources: Observable<any[]>;
  filteredRequestTypes: Observable<any[]>;
  filteredDivisionTypes: Observable<any[]>;
  filteredZones: Observable<any[]>;

  parametersBasePath: any;
  saving: boolean = false;

  contacts: ContactsModels;
  fileList: FileObject[];
  idIncidence: number;
  client: any;
  order: any;
  code: string;
  orderId: string;
  products: any[];
  flagWithProducts: boolean = false;
  productForm;
  flagDisabled: boolean = false;
  counter = 0;

  productsInfo: any[] = [
  ];

  reasonsEnvironment: any;
  registerDateObj: NgbDate | null;
  registerDateInputModel;

  constructor(
    public storeService: StoreService,
    public parametersService: ParametersService,
    public incidenceService: IncidenceService,
    public logger: NGXLogger,
    @Inject(RESTANGULAR_PARAMETER) public restangularBase,
    public dialogRef: MatDialogRef<ModalProductInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private overlay: Overlay,
    public utilService: UtilService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
  ) {
    this.parametersBasePath = this.restangularBase.all('parameter').all('parameters').all('v1');
  }

  ngOnInit() {
    this.reasonsEnvironment = environment.commons.reasons;
    this.newIncidence = new NewIncidence();
    this.company = localStorage.getItem('company');
    this.logo = this.company;
    this.fileList = [];
    this.contacts = new ContactsModels();

    this.idIncidence = this.data.id;
    this.code = this.data.code;
    this.client = this.data.client;
    this.order = this.data.order;
    this.products = this.data.products;

    this.logger.debug('form data to load', this.data);
    this.setFormObjectValues(this.data.incidenceData);

    this.registerDateObj = this.calendar.getToday();

    if (!this.data.incidenceData) {
      this.clearList();
    }
    let auxOrder = [];
    
    if (this.sharedService.getFlagProductInfo()) {
      this.productsInfo = this.sharedService.productInfo;

      if (this.data.data.order) {
        this.flagWithProducts = true;
      }
      
    } else {
      if (this.data.data.order) {
        this.flagWithProducts = true; 
        this.data.data.order.lstDetail = this.data.data.order.lstDetail.filter(detail => !environment.commons.productExcludeCodes.includes(detail.productCode));

        let dataToForm;
        if (this.data.data.products && this.data.data.products.length > 0) {

          for (let item of this.data.data.order.lstDetail) {
            for (let product of this.data.data.products) {
              if (product.product.code === item.productCode) {
                auxOrder.push({
                  counter: this.counter++,
                  code: item.productCode,
                  product: item.productName,
                  quantity: item.requestedQuantity,
                  date: '',
                  loteNumber: ''
                });
              }
            }  
          }

          dataToForm = auxOrder;
        } else {
          dataToForm = this.data.data.order.lstDetail.map(detail => {
            return {
              counter: this.counter++,
              code: detail.productCode,
              product: detail.productName,
              quantity: detail.requestedQuantity,
              date: '',
              loteNumber: ''
            }
          });
        }
        
        this.productsInfo = dataToForm;
      } else {
        this.productsInfo = [
          {
            counter: this.counter++,
            code: '',
            product: '',
            quantity: '',
            date: '',
            loteNumber: ''
          }
        ]
      }
    }

    this.commentFormValidation();
  }

  clearList() {
    this.resetStore();
    this.resetSubStore();
    this.resetReason();
    this.resetSubReason();
    this.resetArea1();
    this.resetArea2();
    this.resetFormat();
    this.resetDeliveryMethod();
    this.resetSource();
    this.resetRequestType();
    this.resetDivisionType();
    this.resetZone();
  }

  commentFormValidation() {
    this.productForm = this.formBuilder.group({
        //comment: ['', [Validators.required, Validators.minLength(8)]]
    });

    let i=0;

    this.productsInfo.forEach((post) => {
        this.productForm.addControl(
            'loteNumber' + String(post.counter),
            new FormControl(
                this.productsInfo[i++].loteNumber, [Validators.required, Validators.pattern('[A-Za-z0-9 ]*')]
            )
        );
    });
}
  addProductInfo() {
    this.productsInfo.push(
      {
        counter: this.counter++,
        code: '',
        product: '',
        quantity: '',
        date: '',
        loteNumber: ''
      }
    )

    this.productForm.addControl('loteNumber' + String(this.productsInfo.length - 1), new FormControl(
      this.productsInfo[this.productsInfo.length - 1].loteNumber, [Validators.required, Validators.pattern('[A-Za-z0-9 ]*')]))
  }

  removeItem(counter, index){
    this.productsInfo.splice(index, 1)
    this.productForm.removeControl('loteNumber' + String(counter));
  }


  disableForm() {
    let items = document.getElementsByClassName('product-items') as HTMLCollectionOf<HTMLDivElement>;
    let flagDisabled = true;
    Array.from(items).map(element  => {
      const product = element.children[1].children[0].children[0].children[0].children[2].children[0] as HTMLInputElement;
      const loteNumber = element.children[4].children[0].children[0].children[0].children[2].children[0] as HTMLInputElement;
      
      if (loteNumber.value === '') {
        flagDisabled = false;
      }
    });
    this.flagDisabled = flagDisabled;
  }

  saveProducsInfo() {
    let items = document.getElementsByClassName('product-items') as HTMLCollectionOf<HTMLDivElement>;
    const itemsBuilded = [];
    Array.from(items).map(element  => {
      const code = element.children[0].children[0].children[0].children[0].children[2].children[0] as HTMLInputElement;
      const product = element.children[1].children[0].children[0].children[0].children[2].children[0] as HTMLInputElement;
      const quantity = element.children[2].children[0].children[0].children[0].children[2].children[0] as HTMLInputElement;
      const date = element.children[3].children[0].children[0].children[0].children[2].children[0] as HTMLInputElement;
      const loteNumber = element.children[4].children[0].children[0].children[0].children[2].children[0] as HTMLInputElement;
      
      itemsBuilded.push({
        counter: loteNumber.getAttribute('name').split('-')[1],
        code: code.value,
        product: product.value,
        quantity: quantity.value,
        date: date.value,
        loteNumber: loteNumber.value,
      })
    });
    
    this.sharedService.setProductInfo(itemsBuilded);
    this.sharedService.setFlagProductInfo(true);
    Swal.fire({
      position: 'center',
      icon: 'success',
      html: '<b>Los datos de los productos se guardaron <br> satisfactoriamente</b>',
      showConfirmButton: true,
      timer: 1500
    });
    this.dialogRef.close();
  }

  removeProductsInfo() {
    this.sharedService.setFlagProductInfo(false);
  }

  checkLength(item) {
    if(item.value.length==100) return false;
  }

  saveIncidence(form: NgForm) {
    if (form.invalid) {
      this.utilService.markFormGroupTouched(form.form);
      return;
    }

    this.logger.debug('passed form validation');

    if (!this.utilService.isSpsa(this.company) && this.contacts.to.length <= 0) {
      Swal.fire({
        icon: 'info',
        text: `No se ha asignado responsable. Recuerda que es obligatorio asignar por lo menos uno`,
        confirmButtonColor: '#20a8d8',
        confirmButtonText: 'Ok',
      });

      return;
    }

    Swal.fire({
      text: '¿Estás seguro que deseas registrar la incidencia?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this.newIncidence = this.getIncidenceValues();

        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: `Generando la incidencia`
        });
        Swal.showLoading();

        this.incidenceService.nuevaIncidencia(this.newIncidence).subscribe(
          response => {
            this.logger.debug('saved incidence', response);
            if (response) {

              // Upload files
              if (this.fileList && this.fileList.length > 0) {
                Swal.fire({
                  allowOutsideClick: false,
                  icon: 'info',
                  text: 'Subiendo archivos'
                });

                Swal.showLoading();

                let document: any;
                let formData: FormData;
                let count = 0;

                this.fileList.forEach((item: FileObject, index) => {
                  if (item.file.isFile) {
                    const fileEntry = item.file as FileSystemFileEntry;

                    fileEntry.file((file: File) => {
                      document = {};
                      document.name = item.name;
                      document.modifiedDate = item.lastModifiedDate;
                      document.contentType = item.type;
                      document.size = item.size;

                      // Prepare formdata to upload
                      formData = new FormData();
                      formData.append('file', file);

                      const url = `${environment.API_UPLOADFILE}/files/v1/upload/${response[0].codIncidence}`;
                      const request = new Request(url, {
                        method: 'POST',
                        mode: 'cors',
                        body: formData,
                      });

                      // Upload to server
                      (async () => {
                        const responseFetch = await fetch(request);
                        const json = await responseFetch.json();
                        this.logger.debug('Uploaded data', json);
                        count++;

                        if (count === this.fileList.length) {
                          this.showSuccessMessage(response);
                        }
                      })();
                    });
                  }
                });
              } else {
                this.showSuccessMessage(response);
              }
            } else {
              this.dialogRef.close();
              this.showErrorMessage();
            }
          },
          error => {
            this.logger.error(error);
            this.dialogRef.close();
            this.showErrorMessage();
          },
        );
      }
    });
  }

  getIncidenceValues(): any {
    const newIncidence: any = {};
    newIncidence.id = this.idIncidence;
    newIncidence.codIncidence = this.code;
    newIncidence.area1 = this.area1 ? this.area1.id : null;
    newIncidence.area2 = this.area2 ? this.area2.id : null;
    newIncidence.client = this.client;
    newIncidence.codRequest = this.order ? this.orderId : null;
    newIncidence.store = this.store ? this.store.id : null;
    newIncidence.subStore = this.subStore ? this.subStore.id : null;
    newIncidence.subStoreName = this.subStore ? this.subStore.name : null;
    newIncidence.reason = this.reason ? this.reason.id : null;
    newIncidence.subReason = this.subReason ? this.subReason.id : null;
    newIncidence.format = this.format ? this.format.id : null;
    newIncidence.deliveryMethod = this.deliveryMethod ? this.deliveryMethod.id : null;
    newIncidence.user = localStorage.getItem('username');
    newIncidence.company = localStorage.getItem('company').toUpperCase();
    newIncidence.observations = this.observations;
    newIncidence.clientNotify = this.reasonsWithoutNotify(this.clientNotify, this.reason ? this.reason.id : null);
    newIncidence.source = this.source ? this.source.id : null;
    newIncidence.paymentOrder = this.order ? this.order.paymentOrder : null;
    newIncidence.saleNote = this.order ? this.order.saleNote : null;
    newIncidence.fromWebTracking = true;
    newIncidence.requestType = this.requestType ? this.requestType.id : null;
    newIncidence.registerDateObj = this.registerDateObj;
    newIncidence.registerDate = moment({ year: this.registerDateObj.year, month: this.registerDateObj.month - 1, day: this.registerDateObj.day }).format('YYYY-MM-DD');
    newIncidence.divisionType = this.divisionType ? this.divisionType.id : null;
    newIncidence.zoneId = this.zone ? this.zone.id : null;

    if (this.contacts && this.contacts.to && this.contacts.to.length > 0) {
      newIncidence.contactsTO = this.contacts.to;
    }

    if (this.contacts && this.contacts.cc && this.contacts.cc.length > 0) {
      newIncidence.contactsCC = this.contacts.cc;
    }

    return newIncidence;
  }

  getIncidenceObjectValues(): any {
    const newIncidence: any = {};
    newIncidence.id = this.idIncidence;
    newIncidence.codIncidence = this.code;
    newIncidence.area1 = this.area1;
    newIncidence.area2 = this.area2;
    newIncidence.client = this.client;
    newIncidence.codRequest = this.order ? this.orderId : null;
    newIncidence.store = this.store;
    newIncidence.subStore = this.subStore;
    newIncidence.subStoreName = this.subStore ? this.subStore.name : null;
    newIncidence.reason = this.reason;
    newIncidence.subReason = this.subReason;
    newIncidence.format = this.format;
    newIncidence.deliveryMethod = this.deliveryMethod;
    newIncidence.observations = this.observations;
    newIncidence.clientNotify = this.reasonsWithoutNotify(this.clientNotify, this.reason ? this.reason.id : null);
    newIncidence.fileList = this.fileList;
    newIncidence.source = this.source;
    newIncidence.requestType = this.requestType;
    newIncidence.registerDateObj = this.registerDateObj;
    newIncidence.divisionType = this.divisionType;
    newIncidence.zone = this.zone;

    if (this.contacts && this.contacts.to && this.contacts.to.length > 0) {
      newIncidence.contactsTO = this.contacts.to;
    }

    if (this.contacts && this.contacts.cc && this.contacts.cc.length > 0) {
      newIncidence.contactsCC = this.contacts.cc;
    }

    return newIncidence;
  }

  setFormObjectValues(incidenceData: any) {
    this.logger.debug('setFormObjectValues', incidenceData);
    if (incidenceData && incidenceData.incidence) {
      this.area1 = incidenceData.incidence.area1;
      this.area2 = incidenceData.incidence.area2;
      this.client = incidenceData.incidence.client;
      this.store = incidenceData.incidence.store;
      this.subStore = incidenceData.incidence.subStore;
      this.reason = incidenceData.incidence.reason;
      this.subReason = incidenceData.incidence.subReason;
      this.format = incidenceData.incidence.format;
      this.deliveryMethod = incidenceData.incidence.deliveryMethod;
      this.source = incidenceData.incidence.source;
      this.requestType = incidenceData.incidence.requestType;
      this.registerDateObj = incidenceData.incidence.registerDateObj;
      this.divisionType = incidenceData.incidence.divisionType;
      this.zone = incidenceData.incidence.zone;

      this.contacts = new ContactsModels();
      this.contacts.to = incidenceData.incidence.contactsTO ? incidenceData.incidence.contactsTO : '';
      this.contacts.cc = incidenceData.incidence.contactsCC ? incidenceData.incidence.contactsCC : '';
      this.observations = incidenceData.incidence.observations;
      this.clientNotify = incidenceData.incidence.clientNotify;
      this.fileList = incidenceData.incidence.fileList ? incidenceData.incidence.fileList : [];
      this.showZones();
    }
  }

  showSuccessMessage(response) {
    this.dialogRef.close(response);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Se generó con éxito la incidencia N° ${response[0].codIncidence}`,
      showConfirmButton: true,
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
      timer: 3000,
    });
  }

  showErrorMessage() {
    Swal.fire({
      icon: 'error',
      title: 'Lo sentimos...',
      text: 'No se pudo registrar la Incidencia!'
    });
  }

  loadStoresByType() {
    if (this.store && Number(this.store.id) > 0) {
      const currentStore = this.stores.find(item => item.id === this.store.id);
      this.logger.debug('currentStore', currentStore);

      if (currentStore.name.trim().toLowerCase() === 'Tiendas físicas'.toLowerCase() ||
        currentStore.name.trim().toLowerCase() === 'Plaza Vea Online'.toLowerCase() ||
        currentStore.name.trim().toLowerCase() === 'Vivanda Online'.toLowerCase() ||
        currentStore.name.trim().toLowerCase() === 'TVO (204)'.toLowerCase() ||
        currentStore.name.trim().toLowerCase() === 'Promart.pe'.toLowerCase()
      ) {
        this.loadAllStores();
        this.disabledCbo = false;
      } else {
        if (!(currentStore.company && currentStore.company.code && currentStore.marketPlaces.length > 0)) {
          this.disabledCbo = true;
        } else {
          this.disabledCbo = false;
        }

        this.loadSellerCenter();
      }
    } else {
      this.disabledCbo = true;
      this.resetSubStore();
    }
  }

  loadAllStores() {
    this.storeService.obtainStores(this.format && this.format.code ? this.format.code : this.company).subscribe(
      (stores: any[]) => {
        // Hide some stores
        const hiddenStores = environment.commons.hiddenStores[this.company];
        this.sellerCenter = hiddenStores ? stores.filter(store => hiddenStores.includes(+store.codigoTienda) === false) : stores;
        this.sellerCenter = this.sellerCenter.map(store => ({ ...store, id: +store.codigoTienda, name: store.nombreTienda }));
        this.sellerCenter.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        this.logger.debug('loadAllStores', this.sellerCenter, this.deliveryMethod);
      },
      error => this.logger.error(error),
    );
  }

  loadFirstStoresByCompany() {
    if (this.format && Number(this.format.id) > 0) {
      this.parametersBasePath.all('stores').all('search').one('by-format', this.format.id).getList().subscribe(
        stores => {
          this.stores = stores;

          if (this.order && this.orderId && this.order.orderType === 'TD') {
            this.store = this.stores.find(item => item.name.trim().toLowerCase() === 'Tiendas físicas'.toLowerCase());

            this.subStore = {
              id: +this.order.saleEntityCode,
              name: this.order.saleEntity,
            };
          }

          if (this.order && this.orderId && this.order.orderType === 'EC') {
            this.store = this.stores.find(item => item.name.trim().toLowerCase() === 'Plaza Vea Online'.toLowerCase() ||
            item.name.trim().toLowerCase() === 'Vivanda Online'.toLowerCase());
          }

          this.loadStoresByType();
          this.loadMotivos();
          this.showZones();
        },
        error => this.logger.error(error),
      );
    } else {
      this.parametersBasePath.all('stores').all('search').one('by-company', this.company).getList().subscribe(
        stores => {
          this.stores = stores;
          this.logger.debug('stores', this.stores);

          if (this.order && this.orderId && this.order.orderType === 'TD') {
            this.store = this.stores.find(item => item.name.trim().toLowerCase() === 'Tiendas físicas'.toLowerCase());

            this.subStore = {
              id: +this.order.saleEntityCode,
              name: this.order.saleEntity,
            };
          }

          if (this.order && this.orderId && this.order.orderType === 'EC') {
            this.store = this.stores.find(item => item.name.trim().toLowerCase() === 'TVO (204)'.toLowerCase() ||
            item.name.trim().toLowerCase() === 'Promart.pe'.toLowerCase());
          }

          this.loadStoresByType();
          this.loadMotivos();
          this.showZones();
        },
        error => this.logger.error(error),
      );
    }
  }

  filterStores(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.store === 'string' ? this.store : this.store.name;
    }

    this.filteredStores = of(value && value.trim().length > 0 ?
      this.stores.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.stores.slice());
  }

  loadMotivos() {
    let online = false;

    if (this.store && this.store.id && this.utilService.isSpsa(this.company)) {
      online = this.store.name.toLowerCase()  === 'marketplace' || this.store.name.toLowerCase().indexOf('online') >= 0;
    }

    this.parametersBasePath.all('reasons').all('search').one('by-company').getList(this.company, {online}).subscribe(
      response => {
        this.listMotivos = response;
        this.listMotivos = this.listMotivos.filter(item =>
          [
            environment.commons.reasons.SELLERCENTER_RETURNS,
            environment.commons.reasons.RETURN_REQUEST,
            environment.commons.reasons.CHANGE_REQUEST,
          ].includes(item.id) === false);

        if ((this.store && this.store.name.toLowerCase()) === 'sellercenter' && (this.format && ['pvea', 'viv'].includes(this.format.code))) {
          this.listMotivos = this.listMotivos.filter(reason => [118, 113].includes(reason.id))
        }
      },
      error => this.logger.error(error),
    );
  }

  displayNamePropertyFn(item: any): string {
    return item && item.name ? item.name : '';
  }

  filterReasons(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.reason === 'string' ? this.reason : this.reason.name;
    }

    this.logger.debug('value', this.reason, value);
    this.filteredReasons = of(value && value.trim().length > 0 ?
      this.listMotivos.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.listMotivos.slice());
  }

  loadSubMotivos() {
    if (this.reason && Number(this.reason.id) > 0) {
      this.listSubMotivos = this.listMotivos.filter(value => value.id === this.reason.id).map(resp => {
        return resp.subreasons;
      });

      this.listSubM = this.listSubMotivos[0];
    } else {
      this.listSubM = [];
      this.resetSubReason();
    }
  }

  filterSubReasons(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.subReason === 'string' ? this.subReason : this.subReason.name;
    }

    this.filteredSubReasons = of(value && value.trim().length > 0 ?
      this.listSubM.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.listSubM.slice());
  }

  loadSellerCenter() {
    const seller = this.stores.find(value => value.id === this.store.id);

    if (seller) {
      this.logger.debug('seller', seller);
      this.sellerCenter = seller.marketPlaces;

      if (this.store && this.store.name === environment.commons.sellerCenterName) {
        this.subStore = this.sellerCenter[0];
        this.disabledCbo = true;

        this.setResponsibleSellerCenter();
      }
    }
  }

  setResponsibleSellerCenter() {
    if (this.contacts.disabled) {
      this.contacts.disabled = false;
      this.contacts.to = [];
      this.contacts.cc = [];
    }

    if (this.store && this.store.name === environment.commons.sellerCenterName && this.reason) {

        this.incidenceService.getResponsiblesSellerCenter(this.reason.id, 'REASON').subscribe(
          response => {
            this.logger.debug('getResponsiblesSellerCenter', response);

            if (response && response.length > 0) {
              this.contacts.to = response.map(item => item.email);
              this.contacts.cc = [];
              this.contacts.disabled = true;
            }
          },
          error => this.logger.error(error),
        );
    }
  }

  filterSubStores(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.subStore === 'string' ? this.subStore : this.subStore.nombreTienda;
    }

    this.filteredSubStores = of(value && value.trim().length > 0 ?
      this.sellerCenter.filter(item => item.nombreTienda.toLowerCase().includes(value.toLowerCase())) : this.sellerCenter.slice());
  }

  loadFormato() {
    this.parametersBasePath.all('formats').getList().subscribe(
      formats => {
        this.listFormat = formats;
        this.format = this.listFormat.find(item => item.code === this.company);
        // this.loadFirstStoresByCompany();

        if (this.format && this.order && this.orderId) {
          const orderDeliveryMethod = +this.order.typeDeliveryId;
          const isPvea = this.company === 'pvea';

          switch (orderDeliveryMethod) {
            case environment.commons.deliveryMethods.HOME_DELIVERY:
              this.deliveryMethod = this.format.deliveryMethods.find(item => item.id === (isPvea ? 5 : 10));
              break;

            case environment.commons.deliveryMethods.STORE_PICKUP:
              this.deliveryMethod = this.format.deliveryMethods.find(item => item.id === (isPvea ? 6 : 11));
              break;

            case environment.commons.deliveryMethods.CAR_PICKUP:
              this.deliveryMethod = this.format.deliveryMethods.find(item => item.id === 7);
              break;

            case environment.commons.deliveryMethods.DELIVERY_EXPRESS:
              this.deliveryMethod = this.format.deliveryMethods.find(item => item.id === (isPvea ? 4 : 9));
              break;
          }

          if (this.order.orderType === 'TD') {
            this.deliveryMethod = this.format.deliveryMethods.find(item => item.id === (isPvea ? 8 : 12));
          }
        }
      },
      error => this.logger.error(error),
      () => this.loadFirstStoresByCompany(),
    );
  }

  filterFormats(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.format === 'string' ? this.format : this.format.name;
    }

    this.filteredFormats = of(value && value.trim().length > 0 ?
      this.listFormat.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.listFormat.slice());
  }

  filterDeliveryMethods(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.deliveryMethod === 'string' ? this.deliveryMethod : this.deliveryMethod.value;
    }

    this.filteredDeliveryMethods = of(value && value.trim().length > 0 ?
      this.format.deliveryMethods.filter(item => item.value.toLowerCase().includes(value.toLowerCase())) : this.format.deliveryMethods.slice());
  }

  loadAreas() {
    this.parametersService.getAreasbyCompany(this.company).subscribe(
      resp => {
        this.listArea1 = resp;
      },
      error => this.logger.error(error),
    );
  }

  filterAreas1(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.area1 === 'string' ? this.area1 : this.area1.name;
    }

    this.filteredAreas1 = of(value && value.trim().length > 0 ?
      this.listArea1.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.listArea1.slice());
  }

  loadAreas2() {
    if (this.area1 && Number(this.area1.id) > 0) {
      this.disabledCboArea2 = false;
      this.listArea2 = this.listArea1.filter(value => value.id !== this.area1.id);
    } else {
      this.listArea2 = [];
      this.disabledCboArea2 = true;
    }
  }

  filterAreas2(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.area2 === 'string' ? this.area2 : this.area2.name;
    }

    this.filteredAreas2 = of(value && value.trim().length > 0 ?
      this.listArea2.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.listArea2.slice());
  }

  loadSources() {
    this.parametersBasePath.all('sources').all('search').one('by-company').getList(this.company).subscribe(
      response => this.sources = response,
      error => this.logger.error(error),
    );
  }

  filterSources(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.source === 'string' ? this.source : this.source.name;
    }

    this.filteredSources = of(value && value.trim().length > 0 ?
      this.sources.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.sources.slice());
  }

  loadRequestType() {
    this.parametersBasePath.all('request-types').getList().subscribe(
      response => this.requestTypes = response,
      error => this.logger.error(error),
    );
  }

  filterRequestTypes(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.requestType === 'string' ? this.requestType : this.requestType.name;
    }

    this.filteredRequestTypes = of(value && value.trim().length > 0 ?
      this.requestTypes.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.requestTypes.slice());
  }

  loadDivisionType() {
    this.parametersBasePath.all('division-types').getList().subscribe(
      response => this.divisionTypes = response,
      error => this.logger.error(error),
    );
  }

  filterDivisionTypes(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.divisionType === 'string' ? this.divisionType : this.divisionType.name;
    }

    this.filteredDivisionTypes = of(value && value.trim().length > 0 ?
      this.divisionTypes.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.divisionTypes.slice());
  }

  loadZones() {
    this.parametersBasePath.all('zones').all('search').one('by-company').getList(this.company).subscribe(
      response => this.zones = response,
      error => this.logger.error(error),
    );
  }

  filterZones(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.zone === 'string' ? this.zone : this.zone.name;
    }

    this.filteredZones = of(value && value.trim().length > 0 ?
      this.zones.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.zones.slice());
  }

  showZones() {
    if (this.store && Number(this.store.id) > 0) {
      const currentStore = this.store; //s.find(item => item.id === this.store.id);

      if (
        currentStore.name.trim().toLowerCase() === 'Plaza Vea Online'.toLowerCase() ||
        currentStore.name.trim().toLowerCase() === 'Vivanda Online'.toLowerCase() ||
        currentStore.name.trim().toLowerCase() === 'Tiendas físicas'.toLowerCase()
      ) {
        this.flagShowZones = true;
      } else {
        this.flagShowZones = false;
      }
    } else {
      this.flagShowZones = false;
    }

    if (!this.flagShowZones) {
      this.resetZone();
    }
  }

  resetStore() {
    this.store = null;
  }

  resetSubStore() {
    this.subStore = null;
  }

  resetReason() {
    this.reason = null;
    this.resetSubReason();
  }

  resetSubReason() {
    this.subReason = null;
  }

  resetArea1() {
    this.area1 = null;
  }

  resetArea2() {
    this.area2 = null;
  }

  resetFormat() {
    this.format = null;
  }

  resetDeliveryMethod() {
    this.deliveryMethod = null;
  }

  resetSource() {
    this.source = null;
  }

  resetRequestType() {
    this.requestType = null;
  }

  resetDivisionType() {
    this.divisionType = null;
  }

  resetZone() {
    this.zone = null;
  }

  addProductsInfo() {
   
    this.logger.debug('passed form validation');

    if (!this.utilService.isSpsa(this.company) && this.contacts.to.length <= 0) {
      Swal.fire({
        icon: 'info',
        text: `No se ha asignado responsable. Recuerda que es obligatorio asignar por lo menos uno`,
        confirmButtonColor: '#20a8d8',
        confirmButtonText: 'Ok',
      });

      return;
    }

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalOstRegisterComponent, {
      width: '1000px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        newIncidence : this.getIncidenceValues(),
        fileList: this.fileList,
        subStores: this.sellerCenter,
        company : this.company,
        format: this.format
      }
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
          if(!dialogResponse.cancel){
            this.dialogRef.close(dialogResponse);
          }
      }
    });
  }

  openRecipientsDialog() {
    if (!this.store || !this.reason) {
      Swal.fire({
        text: 'Por favor elija la tienda y el motivo antes de asignar responsables',
        icon: 'error',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
      });

      return;
    }

    if (this.company.toUpperCase() === 'HPSA') {
      if (this.subReason && this.subStore && this.area1) {
        const idsList = [
          { id: this.subReason.id, type: 'SUBREASON'},
          { id: this.subStore.id, type: 'SUBSTORE'} ,
          { id: this.area1.id, type: 'AREA'}
        ];

        const notMatchesId = [750, 804, 36, 924, 952];


        const result = idsList.map(idObject => {
          if (notMatchesId.includes(idObject.id)) {
            return idObject;
          }
        })
        .filter(idObject => idObject !== undefined)

        if (result.length > 1) {
          Swal.fire({
            text: 'Error al asignar responsable automático. Revisa la selección',
            icon: 'error',
            showConfirmButton: true,
            confirmButtonColor: '#3085d6',
          });

          return;
        } else if (result.length === 1) {
          this.contacts.disabled = false;
          this.contacts.to = [];
          this.contacts.cc = [];
          

          this.incidenceService.getResponsiblesSellerCenter(result[0].id, result[0].type).subscribe(
            response => {
              this.logger.debug('getResponsiblesSellerCenter', response);

              if (response && response.length > 0) {
                this.contacts.to = response.map(item => item.email);
                this.contacts.cc = [];
                this.contacts.disabled = true;

                const scrollStrategy = this.overlay.scrollStrategies.block();
                const dialogRef = this.dialog.open(ModalDestinatariosComponent, {
                  width: '500px',
                  maxHeight: '100vh',
                  disableClose: true,
                  autoFocus: false,
                  scrollStrategy,
                  data: {
                    contacts: this.contacts,
                    store: this.store,
                    subStore: this.subStore,
                  }
                });

                dialogRef.afterClosed().subscribe(dialogResponse => {
                  this.logger.debug('inside dialog response', dialogResponse);
                  if (dialogResponse) {
                    if (dialogResponse.data) {
                      this.contacts = dialogResponse.data;
                    } else {
                      this.clientNotify = false;
                    }
                  }
                });
              }
            },
            error => this.logger.error(error),
          );
        } else {
          if (this.contacts.disabled) {
            this.contacts.disabled = false;
            this.contacts.to = [];
            this.contacts.cc = [];
          }

          const scrollStrategy = this.overlay.scrollStrategies.block();
          const dialogRef = this.dialog.open(ModalDestinatariosComponent, {
            width: '500px',
            maxHeight: '100vh',
            disableClose: true,
            autoFocus: false,
            scrollStrategy,
            data: {
              contacts: this.contacts,
              store: this.store,
              subStore: this.subStore,
            }
          });

          dialogRef.afterClosed().subscribe(dialogResponse => {
            this.logger.debug('inside dialog response', dialogResponse);
            if (dialogResponse) {
              if (dialogResponse.data) {
                this.contacts = dialogResponse.data;
              } else {
                this.clientNotify = false;
              }
            }
          });
        }
      }
    } else {
      const scrollStrategy = this.overlay.scrollStrategies.block();
      const dialogRef = this.dialog.open(ModalDestinatariosComponent, {
        width: '500px',
        maxHeight: '100vh',
        disableClose: true,
        autoFocus: false,
        scrollStrategy,
        data: {
          contacts: this.contacts,
          store: this.store,
          subStore: this.subStore,
        }
      });

      dialogRef.afterClosed().subscribe(dialogResponse => {
        this.logger.debug('inside dialog response', dialogResponse);
        if (dialogResponse) {
          if (dialogResponse.data) {
            this.contacts = dialogResponse.data;
          } else {
            this.clientNotify = false;
          }
        }
      });
    }

  }

  openAppendFileDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(AppendFilesComponent, {
      width: '500px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.fileList = dialogResponse;
        this.logger.debug('fileList', this.fileList);
      }
    });
  }

  openOrderDetailsDialog() {
    const incidence = this.getIncidenceObjectValues();
    const data = {
      client: this.client,
      order: this.order,
      code: this.idIncidence,
      incidence,
    };

    this.dialogRef.close({ backToProducts: true, data });
  }

  reasonsWithoutNotify(clientNotify: boolean, idReason) {
    if (idReason !== null && idReason !== undefined && (idReason === this.reasonsEnvironment.TECHNICAL_SERVICE_PILOT)) {
      return false;
    }

    return clientNotify;
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    this.logger.debug('validateInput', currentValue, input);
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : null;
  }

  openRegisterOstDialog(form: NgForm){
    if (form.invalid) {
      this.utilService.markFormGroupTouched(form.form);
      return;
    }

    this.logger.debug('passed form validation');

    if (!this.utilService.isSpsa(this.company) && this.contacts.to.length <= 0) {
      Swal.fire({
        icon: 'info',
        text: `No se ha asignado responsable. Recuerda que es obligatorio asignar por lo menos uno`,
        confirmButtonColor: '#20a8d8',
        confirmButtonText: 'Ok',
      });

      return;
    }

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalOstRegisterComponent, {
      width: '1000px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        newIncidence : this.getIncidenceValues(),
        fileList: this.fileList,
        subStores: this.sellerCenter,
        company : this.company,
        format: this.format
      }
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
          if(!dialogResponse.cancel){
            this.dialogRef.close(dialogResponse);
          }
      }
    });
  }
}

