import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-modal-user-import-error',
  templateUrl: './modal-user-import-error.component.html',
  styleUrls: ['./modal-user-import-error.component.scss'],
  providers: [
    NGXLogger,
  ],
})
export class ModalUserImportErrorComponent implements OnInit {
  displayedColumns = [
    'numDoc', 'lastname', 'secondLastname', 'name', 'phone', 'email', 'username', 'company', 'role',
  ];
  dataSource;

  constructor(
    public logger: NGXLogger,
    public dialogRef: MatDialogRef<ModalUserImportErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.logger.debug('ModalUserImportErrorComponent', this.data);
    this.dataSource = new MatTableDataSource(this.data.data);
  }

  hasErrors(element: any, property: string): boolean {
    const errors: [] = element.errors ? element.errors.filter((item: any) => item.column === property) : [];
    return errors.length > 0;
  }

  showErrors(element: any, property: string): string {
    const errors: [] = element.errors ? element.errors.filter((item: any) => item.column === property) : [];
    return errors.map((item: any) => item.errors).join(' ');
  }
}
