import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { LoggerModule } from 'ngx-logger';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register.component';
import { FormsModule } from "@angular/forms";
import { PagesModule } from "./pages/pages.module";
import { ServiceModule } from "./services/service.module";
import { SharedModule } from "./shared/shared.module";
import { environment } from 'src/environments/environment';

import { registerLocaleData, AsyncPipe } from '@angular/common';
// import localeEn from '@angular/common/locales/en';
import localeEsPe from '@angular/common/locales/es-PE';
import localeEsPeExtra from '@angular/common/locales/extra/es-PE';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Restangular, RestangularModule } from 'ngx-restangular';
import { RestangularConfigFactory, RESTANGULAR_CLIENT, RestangularClientFactory, RESTANGULAR_PARAMETER, RestangularParameterFactory, RESTANGULAR_INCIDENCE, RestangularIncidenceFactory, RESTANGULAR_USER, RestangularUserFactory, RestangularFCMFactory, RESTANGULAR_FCM, RESTANGULAR_REPORTS, RestangularReportsFactory, RESTANGULAR_CLAIMS, RestangularClaimsFactory, RESTANGULAR_SERVICES, RestangularServicesFactory } from './app.restangular.config';
import { AuthGuard } from './services/service.index';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationService } from './services/fcm/notification.service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { CustomFormsModule } from 'ngx-custom-validators';
import { ServiceWorkerModule } from '@angular/service-worker';

// registerLocaleData(localeEn, 'en');
registerLocaleData(localeEsPe, 'es', localeEsPeExtra);

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    {prefix: './assets/i18n/general/', suffix: '.json'}
  ]);
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PagesModule,
    FormsModule,
    HttpClientModule,
    ServiceModule,
    SharedModule,
    LoggerModule.forRoot({ level: environment.loggerLevel }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RestangularModule.forRoot(RestangularConfigFactory),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.commons.firebase),
    CustomFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.swWorkerEnable }),
  ],
  providers: [
    AuthGuard,
    NotificationService,
    AsyncPipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: RESTANGULAR_INCIDENCE, useFactory:  RestangularIncidenceFactory, deps: [Restangular] },
    { provide: RESTANGULAR_CLIENT, useFactory:  RestangularClientFactory, deps: [Restangular] },
    { provide: RESTANGULAR_PARAMETER, useFactory:  RestangularParameterFactory, deps: [Restangular] },
    { provide: RESTANGULAR_USER, useFactory: RestangularUserFactory, deps: [Restangular] },
    { provide: RESTANGULAR_FCM, useFactory: RestangularFCMFactory, deps: [Restangular] },
    { provide: RESTANGULAR_REPORTS, useFactory: RestangularReportsFactory, deps: [Restangular] },
    { provide: RESTANGULAR_CLAIMS, useFactory: RestangularClaimsFactory, deps: [Restangular] },
    { provide: RESTANGULAR_SERVICES, useFactory: RestangularServicesFactory, deps: [Restangular] },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>,
  ) {
    translateService.addLangs(['es']);
    translateService.setDefaultLang('es');
    translateService.use('es');
    dateAdapter.setLocale('es');
  }
}
