import {Component, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import {SearchService} from "../../services/incidencia/search.service";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  listIncidencias: any = [];

  constructor(private search: SearchService) {

  }

  ngOnInit(): void {
    this.loadingdata();

  }

  loadingdata() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Espere por favor ... '
    });
    this.search.loadAllIncidents().subscribe(resp =>{
      this.listIncidencias = resp;
      Swal.close();
    }, error => {
      Swal.fire({
        icon: 'error',
        title: 'Error al cargar datos',
        text: ''
      });
    })

  }



}
