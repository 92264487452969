import { Component, OnInit, Inject } from '@angular/core';
import { IncidenceService } from "../../services/incidencia/incidence.service";
import Swal from "sweetalert2";
import { ReconctactResponse } from "../../models/ReconctactResponse";
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { ModalAssignComponent } from '../modal-assign/modal-assign.component';
import { ModalCommentComponent } from '../modal-comment/modal-comment.component';
import { ModalInvalidateIncidenceComponent } from '../modal-invalidate-incidence/modal-invalidate-incidence.component';
import { ModalRejectedReturnComponent } from '../modal-rejected-return/modal-rejected-return.component';
import { ModalRejectedRequestComponent } from '../modal-rejected-request/modal-rejected-request.component';
import { UtilService } from 'src/app/services/util/util.service';
import { ModalStateConfirmComponent } from '../modal-state-confirm/modal-state-confirm.component';
import { ModalProgramDeliveryComponent } from '../modal-program-delivery/modal-porgram-delivery.component';
import { ModalTechnicalServiceComponent } from '../modal-technical-service/modal-technical-service.component';
import { ModalRejectedServiceComponent } from '../modal-rejected-service/modal-rejected-service.component';
import { ModalAttentionComponent } from '../modal-attention/modal-attention.component';
import { ModalCancelComponent } from '../modal-cancel/modal-cancel.component';
import moment from 'moment';
import { State } from 'src/environments/environment.commons';
import { ModalOstRegisterComponent } from '../modal-ost-register/modal-ost-register.component';
import { StoreService } from 'src/app/services/stores/store.service';
import { OstModel } from 'src/app/models/ost.models';
@Component({
  selector: 'app-modal-detalle-inc',
  templateUrl: './modal-detalle-inc.component.html',
  styleUrls: ['./modal-detalle-inc.component.scss'],
})
export class ModalDetalleIncComponent implements OnInit {
  logo = '';
  user = '';
  trackingIncidence: any;
  recontactResp: ReconctactResponse;
  downloadEndpoint = environment.API_INCIDENCE;
  company;
  states = environment.commons.states;
  reasons = environment.commons.reasons;
  isProcessed: boolean = false;
  statesWithComment: number[] = [
    environment.commons.states.INCIDENCE_RETURNED,
    environment.commons.states.CLIENT_REPLICA,
    environment.commons.states.PROCESSED,
    environment.commons.states.COMPLETED,
  ];

  constructor(
    public incidenciaService: IncidenceService,
    public dialogRef: MatDialogRef<ModalDetalleIncComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private overlay: Overlay,
    public logger: NGXLogger,
    public utilService: UtilService,
    public storeService: StoreService,
  ) {
  }

  ngOnInit() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Cargando detalles',
    });
    Swal.showLoading();
    this.company = localStorage.getItem('company');
    this.logo = this.company;
    this.user = localStorage.getItem('username');
    this.logger.debug('data from parent component', this.data);
    this.loadDetails();
  }

  loadDetails() {
    this.incidenciaService.obtainDetails(this.data.incidence.id).subscribe(
      response => {
        this.logger.debug('incidence details', response);
        this.trackingIncidence = response;
        const check = this.trackingIncidence.states.filter(item => item.state.id === this.states.PROCESSED);
        this.isProcessed = check.length > 0;
        Swal.close();
      },
      error => this.logger.error(error),
    );
  }

  priorizarIncidencia(cod: string) {
    const data = {
      idIncidence: +cod,
      user: this.user,
    };

    this.incidenciaService.priorizarIncidencia(data).subscribe(
      value => {
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Priorizando...'
        });
        Swal.showLoading();

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `Incidencia priorizada`,
          showConfirmButton: false,
          timer: 1500
        });

        this.dialogRef.close(true);
      },
      error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `No se pudo priorizar la incidencia`,
          showConfirmButton: false,
          timer: 1500
        });
        this.logger.error(error);
        this.dialogRef.close();
      },
    );
  }

  updateRecontacto(trackingIncidence: any) {
    Swal.fire({
      text: '¿Estás seguro que deseas dar re contacto?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this.incidenciaService.recontacto(trackingIncidence.incidence.id, trackingIncidence.id, this.user).subscribe(
          value => {
            this.recontactResp = value;
            this.loadDetails();
          },
          error => this.logger.error(error),
        );
      }
    });
  }


  changeState(state: number, idTracking: number, idIncidence?: number) {
    if (this.statesWithComment.includes(state)) {
      this.openStateCommentDialog(state);
      return;
    }

    Swal.fire({
      text: (state !== this.states.REFUSAL_REJECTED) ? ' ¿Estás seguro de cambiar el estado de la incidencia? ' : ' ¿Estás seguro de cambiar estado a: "Devolucion rechazada"?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        if (state !== this.states.REFUSAL_REJECTED) {
          const data = {
            trackingIncidence: { id: idTracking },
            state: { id: state },
            username: this.user,
          };
          Swal.fire({
            allowOutsideClick: false,
            icon: 'info',
            text: 'Cargando...'
          });
          Swal.showLoading();
          this.incidenciaService.changeState(data).subscribe(
            response => {
              this.logger.debug('response', response);
              if (state === this.states.REQUEST_FINISHED_R_CH_TVO || state === this.states.TECHNICAL_SERVICE_REQUEST_CLOSED || state === this.states.REQUEST_FINISHED_R_R_TVO || state === this.states.TECHNICAL_SERVICE_REQUEST_CLOSED || state === this.states.RETURN_ACCEPTED || state === this.states.REQUEST_CLOSED_DONT_ANSWER_CUSTOMER_R_CH || state === this.states.REQUEST_CLOSED_ABSENT_CUSTOMER_R_CH || state === this.states.REQUEST_CLOSED_R_CH || state === this.states.REQUEST_CLOSED_ABSENT_CUSTOMER_R_R || state === this.states.REQUEST_CLOSED_DONT_ANSWER_CUSTOMER_R_R || state === this.states.REQUEST_CLOSED_R_R || state === this.states.RETURNED_REFUND_R_R) {
                const finalizeData = {
                  id: this.trackingIncidence.id,
                  proceeded: true,
                  closingEndDate: moment().format('YYYY-MM-DD'),
                  returnAmount: 0,
                  responseMedium: { id: 4 },
                };

                this.logger.debug('Calling to updateFinalizeData method');
                this.updateFinalizeData(this.trackingIncidence.id, finalizeData);
              }
              this.loadDetails();
              Swal.close();
            },
            error => {
              this.logger.error(error);
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: `No se pudo cambiar el estado a la incidencia.`,
                showConfirmButton: false,
                timer: 1500
              });
            });
        } else {
          this.openRejectedReturnDialog();
        }
      }
    });
  }

  changeStateRejected(state: number, idTracking: number, idIncidence?: number) {
    Swal.fire({
      text: ' ¿Estás seguro de cambiar estado a: "Solicitud rechazada"?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this.openRejectedRequestDialog(state);
      }
    });
  }

  changeStateRejectedService(state: number, idTracking: number, idIncidence?: number) {
    Swal.fire({
      text: ' ¿Estás seguro de cambiar estado a: "Solicitud rechazada"?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this.openRejectedServiceDialog(state);
      }
    });
  }

  changeStatusProgramDelivery(state: number, idTracking: number, idIncidence?: number) {
    this.openProgramDeliveryDialog(state);
  }

  openAssignDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalAssignComponent, {
      width: '740px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }

  openCommentDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalCommentComponent, {
      width: '500px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }

  openInvalidateIncidenceDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalInvalidateIncidenceComponent, {
      width: '700px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }

  openRejectedReturnDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalRejectedReturnComponent, {
      width: '700px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }

  openRejectedRequestDialog(state: number) {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalRejectedRequestComponent, {
      width: '700px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
        codeState: state,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }

  changeTechnicalService(state: number, idTracking: number, idIncidence?: number) {
    Swal.fire({
      text: '¿Estás seguro de cambiar el estado de la incidencia? ',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        const data = {
          trackingIncidence: { id: idTracking },
          state: { id: state },
          username: this.user,
        };

        this.openTechnicalServiceDialog(state);
      }
    });
  }

  openTechnicalServiceDialog(state: number) {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalTechnicalServiceComponent, {
      width: '700px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
        codeState: state,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }


  openStateCommentDialog(state: number) {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalStateConfirmComponent, {
      width: '806px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
        codeState: state,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }

  openProgramDeliveryDialog(state) {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalProgramDeliveryComponent, {
      width: '490px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
        codeState: state,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }

  openRejectedServiceDialog(state: number) {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalRejectedServiceComponent, {
      width: '700px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
        codeState: state,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }

  private async updateFinalizeData(id: number, data: any) {
    this.logger.debug('Inside updateFinalizeData method');
    const response = await this.incidenciaService.updateIncidenceFinalizeValues(id, data);
    const json = await response.json();
    this.logger.debug('updateFinalizeData response', json);
  }

  changeStateAttention(state: any, idTracking: number, idIncidence?: number) {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalAttentionComponent, {
      width: '700px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
        codeState: state,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }

  changeStateCancel(state: number, idTracking: number, idIncidence?: number) {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalCancelComponent, {
      width: '700px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        trackingIncidence: this.trackingIncidence,
        codeState: state,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }

  disabledChangeAndReturn(stateId: number) {
    if (stateId) {
      const listState: number[] = [
        State.REJECTED_REQUEST_R_R,
        State.REJECTED_REQUEST_R_CH,
        State.RETURNED_REFUND_R_R,
        State.REQUEST_CLOSED_DONT_ANSWER_CUSTOMER_R_R,
        State.REQUEST_CLOSED_ABSENT_CUSTOMER_R_R,
        State.REQUEST_CLOSED_DONT_ANSWER_CUSTOMER_R_CH,
        State.REQUEST_CLOSED_ABSENT_CUSTOMER_R_CH,
        State.CUSTOMER_REQUEST_R_R,
        State.CUSTOMER_REQUEST_R_CH,
        State.TEST_SYSTEM_R_R,
        State.TEST_SYSTEM_R_CH,
        State.NC_R_R,
        State.CHANGE_HAND_R_R,
        State.DERIVATE_S_C_R_R,
        State.DAMAGE_PIECE_R_R,
        State.INCOMPLETE_PIECE_R_R,
        State.RETURNED_REFUND_R_CH,
        State.NC_R_CH,
        State.CHANGE_HAND_R_CH,
        State.DERIVATE_S_C_R_CH,
        State.DAMAGE_PIECE_R_CH,
        State.INCOMPLETE_PIECE_R_CH,
        State.REQUEST_CANCEL_ABSENT_CUSTOMER_SDC];
      return listState.includes(stateId);
    } else {

      return false;
    }
  };


  openEditOstDialog() {

    Swal.fire({
      title: '¿Estás seguro de editar la Orden de Servicio Técnico?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: `Cargando la Orden de Servicio Técnico`
        });
        Swal.showLoading();
        const incidenceId: number = this.trackingIncidence.incidence.id;
        this.incidenciaService.getOst(incidenceId).subscribe(
          dataOst => {

            const ost = dataOst['data'] as OstModel;
            const scrollStrategy = this.overlay.scrollStrategies.block();
            this.storeService.obtainStores(ost.company.companyCode).subscribe(
              (stores: any[]) => {
                Swal.close();
                const hiddenStores = environment.commons.hiddenStores[this.company];
                let sellerCenter = hiddenStores ? stores.filter(store => hiddenStores.includes(+store.codigoTienda) === false) : stores;
                sellerCenter = sellerCenter.map(store => ({ ...store, id: +store.codigoTienda, name: store.nombreTienda }));
                sellerCenter.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                const dialogRef = this.dialog.open(ModalOstRegisterComponent, {
                  width: '1000px',
                  maxHeight: '100vh',
                  disableClose: true,
                  autoFocus: false,
                  scrollStrategy,
                  data: {
                    newIncidence: this.trackingIncidence.incidence,
                    fileList: null,
                    subStores: sellerCenter,
                    company: this.company,
                    format: ost.company.companyCode,
                    value: ost,
                    mode: 'update'
                  }
                });

                dialogRef.afterClosed().subscribe(dialogResponse => {
                  this.logger.debug('inside dialog response', dialogResponse);
                  if (dialogResponse) {
                    this.loadDetails();
                  }
                });
              },
              error => {
                Swal.close();
                this.logger.error(error);
              }
            );
          }
        )
      }
    });
  }


  showObsCD() {
    if(this.trackingIncidence && this.trackingIncidence.incidence && this.trackingIncidence.incidence.reason){
      if(this.trackingIncidence.incidence.reason.id === this.reasons.CHANGE_REQUEST || this.trackingIncidence.incidence.reason.id === this.reasons.RETURN_REQUEST 
        || this.trackingIncidence.incidence.reason.id === this.reasons.SELLERCENTER_RETURNS){
        return true;
      }else {
        return false;
      }
    } else{
      return false;
    }
  }
}
