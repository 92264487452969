import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { UtilService, IncidenceService, SearchService } from 'src/app/services/service.index';
import { RESTANGULAR_PARAMETER } from 'src/app/app.restangular.config';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import Swal from "sweetalert2";
import { NgForm, FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { FileObject } from 'src/app/models/file-object.model';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { ClienteModels } from 'src/app/models/cliente.models';
import { UbigeoDetailModels } from 'src/app/models/ubigeo.models';
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatSelectChange } from '@angular/material';
import { ClientModelOst, OstModel } from 'src/app/models/ost.models';
import { Overlay } from '@angular/cdk/overlay';
import { ModalOstContactsComponent } from '../modal-ost-contacts/modal-ost-contacts.component';


const I18N_VALUES = {
  'es': {
    weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  },
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-modal-ost-register',
  templateUrl: './modal-ost-register.component.html',
  styleUrls: ['./modal-ost-register.component.scss'],
  providers: [
    NGXLogger,
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ],
})
export class ModalOstRegisterComponent implements OnInit {

  closed: string = "";
  basePath: any;
  shopDateObj: NgbDate | null;
  client: any;

  newIncidence: any;
  fileList: any;

  sectionSelect = 'company';

  formOst: FormGroup;
  clienteOst: ClienteModels;
  ubigeoClientOst: UbigeoDetailModels;
  ubigeoNameOst: string = "";
  substoresOst: any[] = [];

  flagCheckError01 = false;
  flagCheckError02 = false;

  mode: string;

  brands: string[];

  constructor(
    public searchService: SearchService,
    public incidenciaService: IncidenceService,
    @Inject(RESTANGULAR_PARAMETER) public restangularBase,
    public dialogRef: MatDialogRef<ModalOstRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utilService: UtilService,
    public logger: NGXLogger,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private overlay: Overlay,
  ) {
    this.basePath = this.restangularBase.all('parameter').all('parameters').all('v1');

  }

  ngOnInit() {

    this.clienteOst = this.data.newIncidence.client as ClienteModels;

    if (!this.clienteOst.address) {
      this.clienteOst.address = '';
    }

    if (!this.clienteOst.phone) {
      this.clienteOst.phone = '';
    }

    if (!this.clienteOst.email) {
      this.clienteOst.email = '';
    }

    if (this.clienteOst.codUbigeo) {
      this.loadDeparmentProviciaDistricByUbigeo(this.clienteOst.codUbigeo);
    };

    this.loadBrands(this.data.company);

    this.mode = this.data.mode ? this.data.mode : 'create';

    if (this.mode == 'update') {
      const ostModel = this.data.value as OstModel;
      const substores = this.data.subStores as any[];
      this.substoresOst = substores.filter(item => item.codigoTienda == "291" || item.codigoTienda == "37");
      const claimDate = new Date(ostModel.product.claimDate);
      const shopDate = new Date(ostModel.product.shopDate);
      this.formOst = this.fb.group({
        storeStockFlag: [ostModel.storeStockFlag, [Validators.required]],
        customerReturnFlag: [ostModel.customerReturnFlag, [Validators.required]],
        company: this.fb.group({
          companyCode: [ostModel.company.companyCode, [Validators.required]],
          name: [ostModel.company.name, [Validators.required]],
          ruc: [ostModel.company.ruc, [Validators.required]],
          storeAddress: [ostModel.company.storeAddress, [Validators.required]],
          storeName: [ostModel.company.storeName, [Validators.required]],
          storeCode: [ostModel.company.storeCode, [Validators.required]],
          storeReference: [ostModel.company.storeReference, []],
          contactName: [ostModel.company.contactName, [Validators.required]],
          contactPhone: [ostModel.company.contactPhone, [Validators.required]],
          contactPhoneSecond: [ostModel.company.contactPhoneSecond, []],
          contactEmail: [ostModel.company.contactEmails? ostModel.company.contactEmails.join(';') : '', [Validators.required]],
          openingHours: [ostModel.company.openingHours, [Validators.required]],
          pickupAdress: [ostModel.company.pickupAdress, [Validators.required]],
          deliveryArea: [ostModel.company.deliveryArea, [Validators.required]]
        }),
        product: this.fb.group({
          name: [ostModel.product.name, [Validators.required]],
          brand: [ostModel.product.brand, [Validators.required]],
          onDisplay: [ostModel.product.onDisplay, [Validators.required]],
          claimDate: [{ year: claimDate.getFullYear(), month: claimDate.getMonth() + 1, day: claimDate.getDate() }, [Validators.required]],
          shopDate: [{ year: shopDate.getFullYear(), month: shopDate.getMonth() + 1, day: shopDate.getDate() }, [Validators.required]],
          claimNumber: [ostModel.product.claimNumber, []],
          mtm: [ostModel.product.mtm, []],
          ean: [ostModel.product.ean, [Validators.required]],
          imei: [ostModel.product.imei, []],
          ticket: [ostModel.product.ticket, []],
          quantity: [ostModel.product.quantity, [Validators.required]],
          model: [ostModel.product.model, [Validators.required]],
          serie: [ostModel.product.serie, [Validators.required]],
          physicalDamage: [ostModel.product.physicalDamage, []],
          descriptionFailure: [ostModel.product.descriptionFailure, []],
          descriptionAccesoriesDamage: [ostModel.product.descriptionAccesoriesDamage, []],
        })
      });

      if (!ostModel.storeStockFlag) {
        this.formOst.controls['storeStockFlag'].disable();
      }

      if (!ostModel.customerReturnFlag) {
        this.formOst.controls['customerReturnFlag'].disable();
      }

    } else {
      this.formOst = this.fb.group({
        storeStockFlag: [false, [Validators.required]],
        customerReturnFlag: [true, [Validators.required]],
        company: this.fb.group({
          companyCode: ['', [Validators.required]],
          name: ['', [Validators.required]],
          ruc: ['', [Validators.required]],
          storeAddress: ['', [Validators.required]],
          storeName: ['', [Validators.required]],
          storeCode: ['', [Validators.required]],
          storeReference: ['', []],
          contactName: ['', [Validators.required]],
          contactPhone: ['', [Validators.required]],
          contactPhoneSecond: ['', []],
          contactEmail: ['', [Validators.required]],
          openingHours: ['', [Validators.required]],
          pickupAdress: ['', [Validators.required]],
          deliveryArea: ['', [Validators.required]]
        }),
        product: this.fb.group({
          name: ['', [Validators.required]],
          brand: ['', [Validators.required]],
          onDisplay: ['', [Validators.required]],
          claimDate: ['', [Validators.required]],
          shopDate: ['', [Validators.required]],
          claimNumber: ['', []],
          mtm: ['', []],
          ean: ['', [Validators.required]],
          imei: ['', []],
          ticket: ['', []],
          quantity: ['', [Validators.required]],
          model: ['', [Validators.required]],
          serie: ['', [Validators.required]],
          physicalDamage: ['', []],
          descriptionFailure: ['', []],
          descriptionAccesoriesDamage: ['', []],
        })
      });

      this.formOst.controls['storeStockFlag'].disable();

      //291 plaza vea la curva
      //126 Plaza vea Santa Clara
      if (this.data.company == "pvea") {

        const substores = this.data.subStores as any[];
        this.substoresOst = substores.filter(item => item.codigoTienda == "291" || item.codigoTienda == "37");
        this.formOst.controls['company'].patchValue({
          name: 'Supermercados Peruanos S.A.',
          ruc: '20100070970',
          companyCode: this.data.format.code
        });
      }
    }

    this.formOst.get('company').get('name').disable();
    this.formOst.get('company').get('ruc').disable();
    this.formOst.get('company').get('ruc').disable();
    this.formOst.get('company').get('storeAddress').disable();
    this.formOst.get('company').get('openingHours').disable();
    this.formOst.get('company').get('pickupAdress').disable();

  }

  loadBrands(companyCode: string) {
    this.searchService.loadOstBrands(companyCode).subscribe(
      (result: any) => {
        const brands = result.data as any[];
        this.brands = brands.map(brand => brand.brandName);
        console.log('brands', this.brands);
      }
    )
  }

  loadDeparmentProviciaDistricByUbigeo(ubigeo: string) {
    this.searchService.obtainDeparmentProviciaDistricByUbigeo(ubigeo).subscribe(
      (result: any) => {
        this.ubigeoClientOst = result.data as UbigeoDetailModels;
        this.ubigeoNameOst = this.processUbigeo()
      },
      error => this.logger.error(error)
    )
  }

  change(event: MatSelectChange) {
    const storeCode: string = event.value;
    const store = this.substoresOst.find(item => item.codigoTienda == storeCode);
    this.formOst.controls['company'].patchValue({
      storeAddress: store.address,
      storeName: store.nombreTienda,
      openingHours: store.horario,
      pickupAdress: store.address
    });
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    // debugger;
    this.logger.debug('validateInput', currentValue, input);
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : null;
  }

  clearToIncidencesFilters() {
    this.shopDateObj = null;
  }

  openModalSingle() {
    Swal.fire({
      text: ' ¿Estás seguro de cancelar la Orden de Servicio Técnico?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this.dialogRef.close({ cancel: true });
      }
    });
  }

  showSuccessMessage(response) {
    this.dialogRef.close(response);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Se creó la Orden de Servicio Técnico satisfactoriamente el N° de incidencia es ${response[0].codIncidence}.`,
      showConfirmButton: true,
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
      timer: 3000,
    });
  }

  showSuccessMessageEdit(response) {
    this.dialogRef.close(response);
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: `Se actualizó la Orden de Servicio Técnico satisfactoriamente el N° de incidencia es ${this.data.newIncidence.codIncidence}.`,
      showConfirmButton: true,
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
      timer: 3000,
    });
  }

  showErrorMessageEdit() {
    Swal.fire({
      icon: 'error',
      title: 'Lo sentimos...',
      text: 'No se pudo actualizar la Orden de Servicio Técnico!'
    });
  }


  showErrorMessage() {
    Swal.fire({
      icon: 'error',
      title: 'Lo sentimos...',
      text: 'No se pudo registrar la Incidencia!'
    });
  }

  saveIncidenceAndOst() {
    if (this.formOst.invalid) {
      if (this.formOst.get('storeStockFlag').value == false && this.formOst.get('customerReturnFlag').value == false) {
        this.flagCheckError01 = true;
        this.flagCheckError02 = true;
      } else {
        this.flagCheckError01 = false;
        this.flagCheckError02 = false;
      }

      this.utilService.markFormGroupTouched(this.formOst);
      return;
    };

    if (this.formOst.get('storeStockFlag').value == false && this.formOst.get('customerReturnFlag').value == false) {
      this.flagCheckError01 = true;
      this.flagCheckError02 = true;
      this.utilService.markFormGroupTouched(this.formOst);
      return;
    }

    this.flagCheckError01 = false;
    this.flagCheckError02 = false;

    this.newIncidence = this.data.newIncidence;


    if (this.mode == 'update') {
      this.updateOst(this.newIncidence.id);
    } else {
      this.fileList = this.data.fileList;

      Swal.fire({
        allowOutsideClick: false,
        icon: 'info',
        text: `Generando la incidencia`
      });
      Swal.showLoading();
      this.incidenciaService.nuevaIncidencia(this.newIncidence).subscribe(
        response => {
          this.logger.debug('saved incidence', response);
          if (response) {

            this.saveOst(this.newIncidence.id);

            if (this.fileList && this.fileList.length > 0) {
              Swal.fire({
                allowOutsideClick: false,
                icon: 'info',
                text: 'Subiendo archivos'
              });
              Swal.showLoading();
              let document: any;
              let formData: FormData;
              let count = 0;
              this.fileList.forEach((item: FileObject, index) => {
                if (item.file.isFile) {
                  const fileEntry = item.file as FileSystemFileEntry;

                  fileEntry.file((file: File) => {
                    document = {};
                    document.name = item.name;
                    document.modifiedDate = item.lastModifiedDate;
                    document.contentType = item.type;
                    document.size = item.size;

                    // Prepare formdata to upload
                    formData = new FormData();
                    formData.append('file', file);

                    const url = `${environment.API_UPLOADFILE}/files/v1/upload/${response[0].codIncidence}`;
                    const request = new Request(url, {
                      method: 'POST',
                      mode: 'cors',
                      body: formData,
                    });

                    // Upload to server
                    (async () => {
                      const responseFetch = await fetch(request);
                      const json = await responseFetch.json();
                      this.logger.debug('Uploaded data', json);
                      count++;

                      if (count === this.fileList.length) {
                        this.showSuccessMessage(response);
                      }
                    })();
                  });
                }
              });
            } else {
              this.showSuccessMessage(response);
            }
          } else {
            this.dialogRef.close({ cancel: false });
            this.showErrorMessage();
          }
        },
        error => {
          this.logger.error(error);
          this.dialogRef.close({ cancel: false });
          this.showErrorMessage();
        },
      );
    }
  }

  saveOst(incidenceId: any) {
    const ostModel = this.formOst.getRawValue() as OstModel;

    ostModel.company.contactEmails = ostModel.company.contactEmail.split(';');
    ostModel.client = new ClientModelOst;
    ostModel.client.address = this.clienteOst.address
    ostModel.client.name = this.clienteOst.name;
    ostModel.client.documentNumber = this.clienteOst.numDoc;
    ostModel.client.documentType = this.clienteOst.typeDoc;
    ostModel.client.email = this.clienteOst.email;

    if (this.ubigeoClientOst) {
      ostModel.client.district = this.ubigeoClientOst.district.description;
      ostModel.client.province = this.ubigeoClientOst.province.description;
      ostModel.client.department = this.ubigeoClientOst.departament.description;
    } else {
      ostModel.client.district = '';
      ostModel.client.province = '';
      ostModel.client.department = '';
    }

    ostModel.client.phone = this.clienteOst.phone;
    ostModel.product.quantity = +ostModel.product.quantity;
    ostModel.product.shopDate = ostModel.product.shopDate ? moment({ year: ostModel.product.shopDate['year'], month: ostModel.product.shopDate['month'] - 1, day: ostModel.product.shopDate['day'] }).format('YYYY-MM-DD' + "T" + 'HH:mm:ss') : null;
    ostModel.product.claimDate = ostModel.product.claimDate ? moment({ year: ostModel.product.claimDate['year'], month: ostModel.product.claimDate['month'] - 1, day: ostModel.product.claimDate['day'] }).format('YYYY-MM-DD' + "T" + 'HH:mm:ss') : null;
    this.incidenciaService.newOst(ostModel, incidenceId).subscribe(
      response => {
      }, error => {
        this.logger.error(error);
        this.dialogRef.close({ cancel: false });
        this.showErrorMessage();
      }
    );
  }

  updateOst(incidenceId: any) {
    const ostModel = this.formOst.getRawValue() as OstModel;
    ostModel.company.contactEmails = ostModel.company.contactEmail.split(';');
    ostModel.client = new ClientModelOst;
    ostModel.client.address = this.clienteOst.address
    ostModel.client.name = this.clienteOst.name;
    ostModel.client.documentNumber = this.clienteOst.numDoc;
    ostModel.client.documentType = this.clienteOst.typeDoc;
    ostModel.client.email = this.clienteOst.email;

    const ostModelOriginal = this.data.value as OstModel;
    ostModel.id = ostModelOriginal.id;

    if (this.ubigeoClientOst) {
      ostModel.client.district = this.ubigeoClientOst.district.description;
      ostModel.client.province = this.ubigeoClientOst.province.description;
      ostModel.client.department = this.ubigeoClientOst.departament.description;
    } else {
      ostModel.client.district = '';
      ostModel.client.province = '';
      ostModel.client.department = '';
    }

    ostModel.client.phone = this.clienteOst.phone;
    ostModel.product.quantity = +ostModel.product.quantity;
    ostModel.product.shopDate = ostModel.product.shopDate ? moment({ year: ostModel.product.shopDate['year'], month: ostModel.product.shopDate['month'] - 1, day: ostModel.product.shopDate['day'] }).format('YYYY-MM-DD' + "T" + 'HH:mm:ss') : null;
    ostModel.product.claimDate = ostModel.product.claimDate ? moment({ year: ostModel.product.claimDate['year'], month: ostModel.product.claimDate['month'] - 1, day: ostModel.product.claimDate['day'] }).format('YYYY-MM-DD' + "T" + 'HH:mm:ss') : null;

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: `actualizando la incidencia`
    });
    Swal.showLoading();
    this.incidenciaService.editOst(ostModel, incidenceId).subscribe(
      response => {
        Swal.close();
        this.showSuccessMessageEdit(response);
      }, error => {
        this.logger.error(error);
        this.dialogRef.close({ cancel: false });
        this.showErrorMessageEdit();
      }
    );
  }

  processUbigeo(): string {
    let fullName = '';
    if (this.ubigeoClientOst) {
      fullName = this.ubigeoClientOst.district.description + ' / ' + this.ubigeoClientOst.province.description + ' / ' + this.ubigeoClientOst.departament.description;
    }
    return fullName;
  }

  validCheckBox($event: MatCheckboxChange, nameControl: string) {
    if ($event.checked) {
      this.formOst.controls[nameControl].setValue(false);
      this.formOst.controls[nameControl].disable();
      //this.formOst.get('storeStockFlag').disable();
    } else {
      this.formOst.controls[nameControl].enable();
    }
  }


  validCheckBoxCustomerReturn($event: MatCheckboxChange) {
    if ($event.checked) {
      this.formOst.controls['storeStockFlag'].setValue(false);
      this.formOst.controls['storeStockFlag'].disable();
      this.flagCheckError02 = false;
      this.flagCheckError01 = false;
      //this.formOst.get('storeStockFlag').disable();
    } else {
      this.formOst.controls['storeStockFlag'].enable();
    }
  }

  validCheckBoxStoreStock($event: MatCheckboxChange) {
    if ($event.checked) {
      this.formOst.controls['customerReturnFlag'].setValue(false);
      this.formOst.controls['customerReturnFlag'].disable();
      this.flagCheckError01 = false;
      this.flagCheckError02 = false;
      this.flagCheckError01 = false;
      //this.formOst.get('storeStockFlag').disable();
    } else {
      this.formOst.controls['customerReturnFlag'].enable();
    }
  }

  addContacts() {
    console.log('test');
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalOstContactsComponent, {
      width: '740px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        contacts: this.formOst.get('company').get('contactEmail').value,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('result', result)
        this.formOst.get('company').get('contactEmail').setValue(result);
      }
    });
  }
}
