import { NgModule } from "@angular/core";
import { HeaderComponent } from "./header/header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { NopagefoundComponent } from "./nopagefound/nopagefound.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import {
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatSelectModule,
  MatCheckboxModule,
  MatChipsModule,
  MatPaginatorModule,
  MatInputModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatRadioModule,
  MatToolbarModule,
  MatCardModule,
  MatDividerModule,
  MatGridListModule,
  MatTableModule,
  MatSortModule,
  MatNativeDateModule,
  MatOptionModule,
  MatSidenavModule,
  MatListModule,
  MatSliderModule,
  MatTabsModule,
} from "@angular/material";
import { NgbTypeaheadModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { AppendFilesModule } from "./append-files-module/append-files.module";
import { NgArrayPipesModule } from "ngx-pipes";
import { DisableControlDirective } from "../directives/disable-control.directive";
import { PaginatedViewModule } from "./paginated-view/paginated-view.module";
import { UpdatesNotificationComponent } from './updates-notification/updates-notification.component';

const material = [
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatSelectModule,
  MatCheckboxModule,
  MatChipsModule,
  MatPaginatorModule,
  MatInputModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatProgressBarModule,
  MatRadioModule,
  MatToolbarModule,
  MatCardModule,
  MatDividerModule,
  MatGridListModule,
  MatTableModule,
  MatSortModule,
  MatNativeDateModule,
  MatOptionModule,
  MatSidenavModule,
  MatListModule,
  MatSliderModule,
  MatTabsModule,
];

const ngbootstrap = [
  NgbTypeaheadModule,
  NgbDatepickerModule,
];

@NgModule({
  declarations: [
    NopagefoundComponent,
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    DisableControlDirective,
    UpdatesNotificationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ...material,
    ...ngbootstrap,
    AppendFilesModule,
    NgArrayPipesModule,
    PaginatedViewModule,
  ],
  exports: [
    NopagefoundComponent,
    HeaderComponent,
    SidebarComponent,
    BreadcrumbsComponent,
    AppendFilesModule,
    DisableControlDirective,
    PaginatedViewModule,
    UpdatesNotificationComponent,
    ...material,
    ...ngbootstrap,
  ],
})

export class SharedModule {
}
