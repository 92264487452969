import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: "root"
})
export class ParametersService {

  private company = '';

  constructor(private http: HttpClient) {
    this.company = localStorage.getItem('company');
  }


  getMotivosby(company: string) {
    return this.http.get(`${environment.API_PARAMETERS}/parameter/reasons/v1/byCompany/${company}`).pipe(
      map(resp => {
        return resp['data'];
      })
    )
  }

  getAreasbyCompany(company: string) {
    return this.http.get(`${environment.API_PARAMETERS}/parameter/area/v1/${company}`).pipe(
      map(resp => {
        return resp['data'];
      })
    );
  }


  getParamsByGroup(group: number) {
    return this.http.get(`${environment.API_PARAMETERS}/parameter/parameters/v1/${group}`).pipe(
      map(resp => {
        return resp['data'];
      })
    );
  }


}
