import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IncidenceService } from 'src/app/services/incidencia/incidence.service';
import { RESTANGULAR_PARAMETER } from 'src/app/app.restangular.config';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilService } from 'src/app/services/util/util.service';
import { NGXLogger } from 'ngx-logger';
import Swal from 'sweetalert2';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Restangular } from 'ngx-restangular';
import moment from 'moment';
import { FileObject } from 'src/app/models/file-object.model';
import { AppendFilesComponent } from 'src/app/shared/append-files-module/append-files.component';
import { FileSystemFileEntry } from 'ngx-file-drop';

const I18N_VALUES = {
  'es': {
    weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  },
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-modal-state-confirm',
  templateUrl: './modal-state-confirm.component.html',
  styleUrls: ['./modal-state-confirm.component.scss'],
  providers: [
    NGXLogger,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class ModalStateConfirmComponent implements OnInit {
  trackinIncidenceState: any;
  trackingIncidence: any;
  state: any;
  reasons: any[];
  user: any;
  states = environment.commons.states;
  responseMediums: any[] = [];

  basePath: any;
  textAreaMinRows: number = environment.textAreaMinRows;
  textAreaMaxRows: number = environment.textAreaMaxRows;
  textAreaMaxLength: number = environment.textAreaMaxLength;
  reasonsCommon = environment.commons.reasons;
  company;
  saving: boolean = false;
  parametersBasePath: Restangular;
  maxDate = undefined;

  fileList: FileObject[];

  constructor(
    public incidenciaService: IncidenceService,
    @Inject(RESTANGULAR_PARAMETER) public restangularBase,
    public dialogRef: MatDialogRef<ModalStateConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utilService: UtilService,
    public logger: NGXLogger,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public dialog: MatDialog,
  ) {

    // Deshabilitar días anteriores al día de hoy.
    const currentDate = new Date();
    this.maxDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };
    this.parametersBasePath = this.restangularBase.all('parameter').all('parameters').all('v1');
  }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.trackinIncidenceState = {};
    this.trackingIncidence = this.data.trackingIncidence;
    this.trackinIncidenceState.closingEndDate = this.calendar.getToday();
    this.state = this.data.codeState;
    this.user = localStorage.getItem('username');
    this.fileList = [];
    this.logger.debug('trackingIncidence', this.trackingIncidence);

    this.parametersBasePath.all('response-mediums').all('search').one('by-company', this.company).getList().subscribe(
      response => this.responseMediums = response,
      error => this.logger.error(error),
    );
  }

  async save(flag: number) {
    if (flag === 2 && this.state !== this.states.COMPLETED) {
      this.dialogRef.close();
      return;
    }

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Actualizando estado',
    });

    Swal.showLoading();

    this.saving = true;
    this.trackinIncidenceState.trackingIncidence = { id: this.trackingIncidence.id };
    this.trackinIncidenceState.state = { id: this.state };
    this.trackinIncidenceState.username = this.user;

    this.logger.debug('state', this.trackinIncidenceState);

    if (this.state === this.states.COMPLETED) {
      const finalizeData = {
        id: this.trackingIncidence.id,
        proceeded: flag === 1,
        closingEndDate:  moment({ year: this.trackinIncidenceState.closingEndDate.year, month: this.trackinIncidenceState.closingEndDate.month - 1, day: this.trackinIncidenceState.closingEndDate.day }).format('YYYY-MM-DD'),
        returnAmount: this.trackinIncidenceState.returnAmount,
        responseMedium: this.trackinIncidenceState.responseMedium,
      };

      this.logger.debug('Calling to updateFinalizeData method');
      await this.updateFinalizeData(this.trackingIncidence.id, finalizeData);
    }

    this.logger.debug('After updateFinalizeData method');

    this.incidenciaService.changeState(this.trackinIncidenceState).subscribe(
      detail => {
        this.logger.debug('response', detail);

        if (this.fileList && this.fileList.length > 0) {
          Swal.fire({
            allowOutsideClick: false,
            icon: 'info',
            text: 'Subiendo archivos',
          });

          Swal.showLoading();

          let document: any;
          let formData: FormData;
          let count = 0;

          this.fileList.forEach((item: FileObject, index) => {
            if (item.file.isFile) {
              const fileEntry = item.file as FileSystemFileEntry;

              fileEntry.file((file: File) => {
                document = {};
                document.name = item.name;
                document.modifiedDate = item.lastModifiedDate;
                document.contentType = item.type;
                document.size = item.size;

                // Prepare formdata to upload
                formData = new FormData();
                formData.append('document', new Blob([JSON.stringify(document)], {type: 'application/json'}));
                formData.append('file', file);

                const url = `${environment.API_INCIDENCE}/incidence/v1/comment/${detail.id}/attachment`;
                const request = new Request(url, {
                  method: 'POST',
                  mode: 'cors',
                  body: formData,
                });

                // Upload to server
                (async () => {
                  const response = await fetch(request);
                  const json = await response.json();
                  this.logger.debug('Uploaded data', json);
                  count++;

                  if (count === this.fileList.length) {
                    this.showSuccessMessage();
                  }
                })();
              });
            }
          });
        } else {
          this.showSuccessMessage();
        }
      },
      error => {
        this.logger.error(error);
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `No se pudo cambiar el estado a la incidencia.`,
          showConfirmButton: false,
          timer: 1500
        });
        this.dialogRef.close();
      });
  }

  private async updateFinalizeData(id: number, data: any) {
    this.logger.debug('Inside updateFinalizeData method');
    const response = await this.incidenciaService.updateIncidenceFinalizeValues(id, data);
    const json = await response.json();
    this.logger.debug('updateFinalizeData response', json);
  }

  showSuccessMessage() {
    Swal.fire({
      position: 'center',
      icon: 'info',
      title: `La incidencia ${this.state === environment.commons.states.REACTIVATED ? 'se reactivó' : 'cambió de estado' } correctamente.`,
      confirmButtonColor: '#20a8d8',
      confirmButtonText: 'Ok',
      timer: 1500
    });

    this.dialogRef.close(true);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    this.logger.debug('validateInput', currentValue, input);
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : null;
  }

  openAppendFileDialog() {
    const dialogRef = this.dialog.open(AppendFilesComponent, {
      width: '500px',
      disableClose: true,
      data: {},
    });

    dialogRef.afterClosed().subscribe(res => {
      this.logger.debug('inside dialog response', res);
      if (res) {
        this.fileList = res;
      }
    });
  }
}
