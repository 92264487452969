import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";

// models
import { NewIncidence } from "../../models/newIncidence.models";
import { EmailModels } from "../../models/email.models";
import { CommentModels } from "../../models/Comment.models";
import { ResponsibleModels } from "../../models/Responsible.models";
import { NewServiceTecnic } from "../../models/serviceTecnic.models";

// config
import { environment } from '../../../environments/environment';
import { RESTANGULAR_INCIDENCE, RESTANGULAR_REPORTS } from "src/app/app.restangular.config";
import { Observable } from "rxjs/internal/Observable";
import { throwError } from "rxjs";
import { Restangular } from "ngx-restangular";
import { OstModel } from "src/app/models/ost.models";

@Injectable({
  providedIn: 'root'
})
export class IncidenceService {
  private basePath: Restangular;
  private reportsBasePath: Restangular;

  constructor(
    private http: HttpClient,
    @Inject(RESTANGULAR_INCIDENCE) private restangularIncidenceBase,
    @Inject(RESTANGULAR_REPORTS) private restangularReportBase,
  ) {
    this.basePath = this.restangularIncidenceBase.all('incidence').all('v1');
    this.reportsBasePath = this.restangularReportBase;
  }

  patchIncidence(data: any, id) {
    return this.http.patch(`${environment.API_INCIDENCE}/incidence/v1/update/${id}`, data);
  }

  putIncidenceByReason(data: any) {
    return this.basePath.all('reason').customPUT(data);
  }

  getCodNewIncidence(clientDocument: string, company: string, data?: any, associateType?: string) {
    return this.basePath.all('serie').post(data, {clientDocument, company, associateType});
  }

  deleteCodIncidencia(id: number) {
    return this.basePath.all('delete').all(id).remove();
  }

  priorizarIncidencia(data: any) {
    return this.basePath.all('prioritize').post(data);
  }

  recontacto(id: number, idTracking: number, user: string) {
    return this.basePath.all('recontact').one('', id).one('', idTracking).one('', user).get();
  }

  nuevaIncidencia(incidence: NewIncidence) {
    return this.basePath.all('new').post(incidence);
  }

  newComment(comment: CommentModels) {
    return this.basePath.all('newComment').post(comment);
  }

  assignIncidence(responsible: ResponsibleModels) {
    return this.basePath.all('assignIncidence').post(responsible);
  }

  massiveReassignIncidences(responsibles: any) {
    return this.basePath.all('massive-reassign').customPUT(responsibles);
  }

  changeState(data: any) {
    return this.basePath.one('', data.trackingIncidence.id).all('status').post(data);
  }

  obtainDetails(idTracking: number) {
    return this.basePath.all('search').one('details', idTracking).get();
  }

  obtainIncidencesByOrders(orders: any[], idClient?: number) {
    const queryParams: any = {};

    if (idClient && Number(idClient) > 0) {
      const company = localStorage.getItem('company');
      queryParams.idClient = idClient;
      queryParams.company = company;
    }

    return this.basePath.all('search').all('by-orders').post(orders, queryParams);
  }

  obtainIncidencesByClient(document: string) {
    const company = localStorage.getItem('company');

    return this.reportsBasePath.one('', company).one('client', document).all('incidences').getList();
  }

  obtainAllDataByClient(document: string) {
    const company = localStorage.getItem('company');

    return this.reportsBasePath.one('', company).one('client', document).all('data').getList();
  }

  updateIncidenceProductos(id: number, products: any[]) {
    return this.basePath.one('', id).all('update-products').post(products);
  }

  getResponsiblesSellerCenter(idReason: number, type: string) {
    const company = localStorage.getItem('company');
    return this.basePath.all('responsibles').one('', company).one('responsible', idReason).getList('', { responsibleType: type});
  }

  obtainIncidencesByFilters(filters: any, pageInfo?: any) {
    if (!pageInfo) {
      pageInfo = {
        orderDir: 'DESC',
        orderColumn: 'CREATED_ON',
        pageNumber: null,
        pageSize: 20,
      };
    }

    return this.reportsBasePath.all('tracking').all('data').post(pageInfo, filters);
  }

  countIncidencesByFilters(filters: any) {
    return this.reportsBasePath.all('tracking').all('data').all('count').post({}, filters);
  }

  downloadIncidencesByFilters(filters: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      requestBy: user.username,
      requestByEmail: user.email,
    };

    return this.post(`${environment.API_REPORTS}/tracking/export`, data, filters);
  }

  updateIncidenceVisitedValue(id: number) {
    return this.basePath.one('', id).all('visited').post();
  }

  obtainDispatchPieData(filters: any) {
    return this.reportsBasePath.all('dispatch').all('data').all('chart').getList(filters);
  }

  obtainStatusPieData(filters: any) {
    return this.reportsBasePath.all('status').all('data').all('chart').getList(filters);
  }

  obtainReasonRankingPieData(filters: any) {
    return this.reportsBasePath.all('reason').all('data').all('chart').getList(filters);
  }

  obtainAreaRankingPieData(filters: any) {
    return this.reportsBasePath.all('area').all('data').all('chart').getList(filters);
  }

  obtainRecontactRankingPieData(filters: any) {
    return this.reportsBasePath.all('recontact').all('data').all('chart').getList(filters);
  }

  obtainPriorityRankingPieData(filters: any) {
    return this.reportsBasePath.all('priority').all('data').all('chart').getList(filters);
  }

  downloadDispatchReport(filters: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      requestBy: user.username,
      requestByEmail: user.email,
    };

    return this.post(`${environment.API_REPORTS}/dispatch/export`, data, filters);
  }

  downloadStatusReport(filters: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      requestBy: user.username,
      requestByEmail: user.email,
    };

    return this.post(`${environment.API_REPORTS}/status/export`, data, filters);
  }

  downloadReasonReport(filters: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      requestBy: user.username,
      requestByEmail: user.email,
    };

    return this.post(`${environment.API_REPORTS}/reason/export`, data, filters);
  }

  downloadAreaReport(filters: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      requestBy: user.username,
      requestByEmail: user.email,
    };

    return this.post(`${environment.API_REPORTS}/area/export`, data, filters);
  }

  downloadRecontactReport(filters: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      requestBy: user.username,
      requestByEmail: user.email,
    };

    return this.post(`${environment.API_REPORTS}/recontact/export`, data, filters);
  }

  downloadPriorityReport(filters: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      requestBy: user.username,
      requestByEmail: user.email,
    };

    return this.post(`${environment.API_REPORTS}/priority/export`, data, filters);
  }

  downloadChangeAndReturnReport(filters: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      requestBy: user.username,
      requestByEmail: user.email,
    };

    return this.post(`${environment.API_REPORTS}/change-return/export`, data, filters);
  }

  updateIncidenceFinalizeValues(id: number, data) {
    const url = `${environment.API_INCIDENCE}/incidence/v1/${id}/finalize`;
    const request = new Request(url, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(data),
    });

    return fetch(request);
  }

  downloadUserImportTemplate(company: string): Observable<any> {
    return this.downloadExternal(`${environment.API_LOGIN}/auth/v1/users/import/download-template`, {company});
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  getBlob(path: string, filters?: object): Observable<any> {
    let params = new HttpParams();

    if (filters) {
        Object.keys(filters).sort().forEach(key => {
            const value = filters[key];
            if (value !== null && value.toString().length > 0) {
                params = params.set(key, value.toString());
            }
        });
    }

    return this.http.get(`${path}`, { observe: 'response', params, responseType: 'blob' })
        .pipe(catchError(this.formatErrors));
  }

  postBlob(path: string, body: any = {}): Observable<any> {
    return this.http.post(`${path}`, JSON.stringify(body), { observe: 'response', responseType: 'blob' })
        .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: any = {}, filters?: object): Observable<any> {
    let params = new HttpParams();

    if (filters) {
        Object.keys(filters).sort().forEach(key => {
            const value = filters[key];
            if (value !== null && value.toString().length > 0) {
                params = params.set(key, value.toString());
            }
        });
    }

    return this.http.post(`${path}`, JSON.stringify(body), {params, headers: {'Content-Type': 'application/json; charset=utf-8'}});
  }

  downloadExternal(path, params?: object, isPost?: boolean): Observable<any> {
    const response = isPost ? this.postBlob(path, params) : this.getBlob(path, params);
    return response.pipe(
        map(data => {
            if (data.body && Number(data.body.size) > 0) {
                const temp = data.headers.get('content-disposition');
                const cd = temp.split('=');
                const filename = cd[1] && cd[1].length > 0 ? cd[1].substring(1, cd[1].length - 1) : 'file';

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(data.body);
                link.download = filename;
                link.click();
                link.remove();

                return { status: true, filename };
            } else {
                return { status: false };
            }
        }),
    );
  }

  /* newOrdenServiceTecnic(newServiceTecnic: NewServiceTecnic, incidenceId: any) {
    return this.basePath.all('serviceTecnic').all(incidenceId).post(newServiceTecnic);
  } */

  newOst(newOst: OstModel, incidenceId: any) {
    return this.basePath.all('serviceTecnic').all(incidenceId).post(newOst);
  }

  editOst(ost: OstModel, incidenceId: any) {
    const user = JSON.parse(localStorage.getItem('user'));
    const params = {
      username: user.username,
    };
    //return this.post(`${environment.API_REPORTS}/reason/export`, data, filters);
    //return this.basePath.all('serviceTecnic').all(incidenceId).customPUT(ost,params);
    return this.basePath.all('serviceTecnic').customPUT(ost,incidenceId , params);
  }

  getOst(incidenceId:number){
    return this.http.get(`${environment.API_INCIDENCE}/incidence/v1/serviceTecnic/${incidenceId}`);
  }

  getOstv2(incidenceId:number) {
    return this.basePath.all('serviceTecnic').one('',incidenceId).get();
  }

  downloadTechnicalService(filters: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      requestBy: user.username,
      requestByEmail: user.email,
    };

    return this.post(`${environment.API_REPORTS}/technical-service/export`, data, filters);
  }

}
