import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";

// models
import { UsuarioModel } from '../../models/usuario.model';

// config
import { environment } from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    public router: Router,
    public logger: NGXLogger,
  ) {
  }

  login(usuario: UsuarioModel) {
    this.clearStorage();
    usuario.system = 'TRACKING';
    usuario.username = usuario.username.trim().toLowerCase();
    const authData = {
      ...usuario
    };

    return this.http.post(`${environment.API_LOGIN}/auth/v1/signin`, authData)
      .pipe(
        map((resp: any) => {
          this.saveToken(resp.token);
          localStorage.setItem('company', resp.company);
          localStorage.setItem('username', resp.username);
          localStorage.setItem('storeCode', resp.storeCode);
          localStorage.setItem('user', JSON.stringify(resp));
          return resp;
        }),
      );
  }

  private saveToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  saveFcmTokemId(id: number, token: string) {
    localStorage.setItem('fcmTokenId', id.toString());
    localStorage.setItem('fcmToken', token);
  }

  getFcmTokenId(): number {
    return +localStorage.getItem('fcmTokenId');
  }

  getFcmToken(): string {
    return localStorage.getItem('fcmToken');
  }

  logout(errors?: any): boolean {
    const id = this.getFcmTokenId();

    [1].forEach(async val => {
      if (id > 0) {
        await this.http.delete(`${environment.API_LOGIN}/auth/v1/fcm/tokens/${id}`).toPromise().then(response => {
          this.logger.debug('Deleted token with id', id);
        });
      }
    });

    this.logger.debug('After delete token call', errors);
    this.clearStorage();
    this.router.navigate(['login'], {queryParams: {errors}});
    return false;
  }

  lockUser(usuario: UsuarioModel) {
    const authData = {
      ...usuario
    };
    return this.http.post(`${environment.API_LOGIN}/auth/v1/updateState`, authData)
      .pipe(
        map(resp => {
          this.logger.debug(resp);
        })
      );
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token !== null;
  }

  getUser(): Observable<any> {
    return of(JSON.parse(localStorage.getItem('user')));
  }

  clearStorage() {
    // const updated = localStorage.getItem('updated');
    // const updatedLater = localStorage.getItem('updated-later');
    localStorage.clear();

    // localStorage.setItem('updated', updated ? 'true' : 'false');
    // localStorage.setItem('updated-later', updatedLater ? 'true' : 'false');
  }
}
