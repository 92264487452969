import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { ContactsModels } from "../../models/contacts.models";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map, finalize } from 'rxjs/operators';
import { RESTANGULAR_USER } from 'src/app/app.restangular.config';
import { NGXLogger } from 'ngx-logger';
import { UtilService } from 'src/app/services/util/util.service';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-modal-destinatarios',
  templateUrl: './modal-destinatarios.component.html',
  styleUrls: ['./modal-destinatarios.component.scss'],
})
export class ModalDestinatariosComponent implements OnInit {
  @ViewChild('toref', {static: false}) toElement: ElementRef;
  @ViewChild('ccref', {static: false}) ccElement: ElementRef;

  company = '';
  emailscc: any;
  emailto: any;
  ccs: any[] = [];
  tos: any[] = [];
  contacts: ContactsModels = new ContactsModels();
  disabled: boolean = false;

  basePath: any;
  fromExternalProvider: boolean = false;

  searchToCtrl = new FormControl();
  searchCcCtrl = new FormControl();
  searchToLoading: boolean = false;
  searchCcLoading: boolean = false;
  filteredUsers: any[];

  companies: any[] = [
    {
      code: 'ecmx',
      name: 'Economax',
    },
    {
      code: 'mass',
      name: 'Mass',
    },
    {
      code: 'tpsa',
      name: 'Oechsle',
    },
    {
      code: 'pvea',
      name: 'Plaza Vea',
    },
    {
      code: 'hpsa',
      name: 'Promart',
    },
    {
      code: 'rplaza',
      name: 'Real Plaza',
    },
    {
      code: 'sstar',
      name: 'Shopstar',
    },
    {
      code: 'viv',
      spsaCode: 'viv',
      name: 'Vivanda',
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<ModalDestinatariosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(RESTANGULAR_USER) public restangularBase,
    private logger: NGXLogger,
    public utilService: UtilService,
  ) {
    this.basePath = this.restangularBase.all('auth').all('v1');
  }

  ngOnInit() {
    this.company = localStorage.getItem('company');
    this.ccs = this.data.contacts && this.data.contacts.cc ? this.data.contacts.cc : [];
    this.tos = this.data.contacts && this.data.contacts.to ? this.data.contacts.to : [];
    this.fromExternalProvider = this.data.fromExternalProvider || false;
    this.disabled = this.data.contacts.disabled || false;
    this.searchToCtrl.setValidators([Validators.required]);
    this.searchCcCtrl.setValidators([Validators.required]);

    this.searchToCtrl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.filteredUsers = [];
          this.searchToLoading = true;
        }),
        switchMap((keyword: string) => !keyword || (keyword && keyword.length < 3) ? [] :
          this.searchUsersQuery(keyword)
          .pipe(
            finalize(() => this.searchToLoading = false),
          ),
        ),
      )
      .subscribe((data: any[]) => {
        this.filteredUsers = data.length > 0 ? data : [];
      });

    
    this.searchCcCtrl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.filteredUsers = [];
          this.searchCcLoading = true;
        }),
        switchMap((keyword: string) => !keyword || (keyword && keyword.length < 3) ? [] :
          this.searchUsersQueryToCpy(keyword)
          .pipe(
            finalize(() => this.searchCcLoading = false),
          ),
        ),
      )
      .subscribe((data: any[]) => {
        this.filteredUsers = data.length > 0 ? data : [];
      });
  }

  displayNamePropertyFn(item: any): string {
    return item && item.username ? item.username : '';
  }

  saveContacts() {
    this.contacts.cc = this.ccs;
    this.contacts.to = this.tos;
    this.contacts.disabled = this.disabled;
    this.logger.debug('contacts', this.contacts);
    this.dialogRef.close({data: this.contacts});
  }

  dropItemCc(index: any) {
    this.ccs.splice(index, 1);
    this.resetTxtTo();
  }

  dropItemTo(index: any) {
    this.tos.splice(index, 1);
    this.resetTxtTo();
  }

  saveWithoutContacts() {
    Swal.fire({
      text: '¿Deseas registrar la incidencia sin responsable?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this.dialogRef.close({data: null});
      }
    });
  }

  searchUsersQuery = (keyword) => {
    const data: any = {};
    data.keyword = keyword;

    if (this.fromExternalProvider) {
      data.company = localStorage.getItem('company');
    } else {
      if (this.data.store.name.trim().toLowerCase() === 'marketplace') {
        const companyToQuery = this.companies.filter(company => company.name.toLowerCase() === this.data.subStore.name.toLowerCase())
                        .map(company => company.code)[0];
        data.company = companyToQuery;
      } else if (this.data.store.name.trim().toLowerCase() === 'sellercenter') {
        data.company = 'irdg';
      } else {
        data.company = this.data.store.company.code.toLowerCase() === 'spsa' ? 'pvea' : this.data.store.company.code.toLowerCase();
        data.companyTwo = 'irdg';
      }
    }

    return this.basePath.all('users').all('search').getList(data);
  }

  searchUsersQueryToCpy = (keyword) => {
    const data: any = {};
    data.keyword = keyword;

    if (this.fromExternalProvider) {
      data.company = localStorage.getItem('company');
    }

    return this.basePath.all('users').all('search').getList(data);
  }

  selectUser($event: MatAutocompleteSelectedEvent, type: number) {
    this.logger.debug('selectUser', $event);

    if ($event.option.value) {
      let user: any = $event.option.value;

      if (user.email) {
        if (type === 1) {
          const findTo = this.tos.find(item => {
            return item === user.email.trim();
          });

          if (!findTo && user && user.email && user.email.trim().length > 0) {
            this.tos.push(user.email.trim());
          }
        } else {
          const findCc = this.ccs.find(item => {
            return item === user.email.trim();
          });

          if (!findCc && user && user.email && user.email.trim().length > 0) {
            this.ccs.push(user.email.trim());
          }
        }
      }
    }

    this.resetTxtTo();
  }

  resetTxtTo() {
    this.searchToCtrl.setValue('');
    this.searchCcCtrl.setValue('');
    this.filteredUsers = [];

    this.searchToCtrl.setValidators(this.tos.length > 0 ? [Validators.nullValidator] : [Validators.required]);
    this.searchToCtrl.updateValueAndValidity();

    this.searchCcCtrl.setValidators(this.ccs.length > 0 ? [Validators.nullValidator] : [Validators.required]);
    this.searchCcCtrl.updateValueAndValidity();
  }
}
