import { RouterModule, Routes } from "@angular/router";
import { PagesComponent } from "./pages.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { SearchIncidenceComponent } from "./search-incidence/search-incidence.component";
import { NopagefoundComponent } from "../shared/nopagefound/nopagefound.component";
import { NgModule } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthGuard } from "../services/auth/auth-guard.service";

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'search',
        component: SearchIncidenceComponent,
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [...environment.commons.internalRoles],
        },
      },
      {
        path: 'tracing',
        loadChildren: () => import('./tracing/home-tracing/home-tracing.module')
          .then(module => module.HomeTracingModule),
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [...environment.commons.internalRoles, ...environment.commons.externalRoles],
        },
      },
      {
        path: 'results',
        loadChildren: () => import('./reports/home-reports/home-reports.module')
          .then(module => module.HomeReportsModule),
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [...environment.commons.internalRoles],
        },
      },
      {
        path: 'users',
        loadChildren: () => import('./users/user-home/user-home.module')
          .then(module => module.UserHomeModule),
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [...environment.commons.adminRoles],
        },
      },
      {
        path: 'claims',
        loadChildren: () => import('./claims/claims.module')
          .then(module => module.ClaimsModule),
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [...environment.commons.claimsRoles],
        },
      },
      {
        path: 'external',
        loadChildren: () => import('./external/external.module')
          .then(module => module.ExternalModule),
        canActivate: [AuthGuard],
        data: {
          expectedRoles: [...environment.commons.externalRoles],
        },
      },
      {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: NopagefoundComponent,
      },
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
