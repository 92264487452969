import {Injectable} from "@angular/core";
import {environment} from '../../../environments/environment';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class FileUploadService {

  constructor(private http: HttpClient) {
  }

  subirArchivo(data: FormData, codIncidence: string): Observable<any> {
    return this.http.post<any>(`${environment.API_UPLOADFILE}/files/v1/upload/${codIncidence}`, data);
  }
}
