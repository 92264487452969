import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppendFilesComponent } from './append-files.component';
import { MatFormFieldModule, MatDialogModule, MatCardModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxFileDropModule } from 'ngx-file-drop';

@NgModule({
  declarations: [AppendFilesComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCardModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgxFileDropModule,
  ],
  entryComponents: [
    AppendFilesComponent,
  ],
})
export class AppendFilesModule { }
