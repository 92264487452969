import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_CLAIMS, RESTANGULAR_CLIENT } from 'src/app/app.restangular.config';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import moment from 'moment';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from 'src/app/services/util/util.service';
import { Overlay } from '@angular/cdk/overlay';
import { ModalClaimAssignComponent } from '../modal-claim-assign/modal-claim-assign.component';
import { ModalClaimCommentComponent } from '../modal-claim-comment/modal-claim-comment.component';
import { ModalClaimInvalidateComponent } from '../modal-claim-invalidate/modal-claim-invalidate.component';
import { ModalClaimStatusConfirmComponent } from '../modal-claim-status-confirm/modal-claim-status-confirm.component';
import { ModalClaimRegisterComponent } from '../modal-claim-register/modal-claim-register.component';
import { ModalClaimTemplateEditorComponent } from '../modal-claim-template-editor/modal-claim-template-editor.component';

const I18N_VALUES = {
  'es': {
    weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  },
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-modal-claim-detail',
  templateUrl: './modal-claim-detail.component.html',
  styleUrls: ['./modal-claim-detail.component.scss'],
  providers: [
    NGXLogger,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class ModalClaimDetailComponent implements OnInit {
  claim: any;
  user: any;
  company: string = '';
  claimsBasePath: any;
  clientsBasePath: any;
  downloadEndpoint = environment.API_INCIDENCE;
  status = environment.commons.claimStatus;

  firstManagementDateObj: NgbDate | null;
  ocurrencyDateObj: NgbDate | null;
  charterGenerationDateObj: NgbDate | null;
  letterSendDateObj: NgbDate | null;

  today: NgbDate | null;
  saving: boolean = false;
  fromIncidenceModule: boolean = false;
  disabledReAssign: boolean = false;

  constructor(
    public logger: NGXLogger,
    private authService: AuthService,
    @Inject(RESTANGULAR_CLAIMS) public restangularBase,
    @Inject(RESTANGULAR_CLIENT) public restangularClientBase,
    public dialogRef: MatDialogRef<ModalClaimDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private overlay: Overlay,
    public utilService: UtilService,
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
  ) { }

  ngOnInit() {
    this.authService.getUser().subscribe((user: any) => {
      this.user = user;
      this.company = this.data.claim.company;
      this.today = this.calendar.getToday();
      this.claimsBasePath = this.restangularBase.one('', this.company).all('claims');
      this.clientsBasePath = this.restangularClientBase.all('client');
      this.fromIncidenceModule = this.data.fromIncidenceModule;
      this.loadDetails();
    });
  }

  checkCompany() {
    return ['spsa', 'pvea', 'viv', 'ecmx', 'mass'].includes(this.company);
  }

  loadDetails() {
    this.claimsBasePath.one('', this.data.claim.id).get().subscribe(
      claim => {
        this.claim = claim;

        if (this.claim.firstManagementDate) {
          const firstManagementDate = moment(this.claim.firstManagementDate, 'YYYY-MM-DD');
          this.firstManagementDateObj = new NgbDate(firstManagementDate.year(), firstManagementDate.month() + 1, firstManagementDate.date());
        }

        if (this.claim.ocurrencyDate) {
          const ocurrencyDate = moment(this.claim.ocurrencyDate, 'YYYY-MM-DD');
          this.ocurrencyDateObj = new NgbDate(ocurrencyDate.year(), ocurrencyDate.month() + 1, ocurrencyDate.date());
        }

        if (this.claim.letterGenerationDate) {
          const letterGenerationDate = moment(this.claim.letterGenerationDate, 'YYYY-MM-DD');
          this.charterGenerationDateObj = new NgbDate(letterGenerationDate.year(), letterGenerationDate.month() + 1, letterGenerationDate.date());
        }

        if (this.claim.letterSendDate) {
          const letterSendDate = moment(this.claim.letterSendDate, 'YYYY-MM-DD');
          this.letterSendDateObj = new NgbDate(letterSendDate.year(), letterSendDate.month() + 1, letterSendDate.date());
        }

        this.clientsBasePath.one('', this.claim.idClient).get().subscribe(
          client => this.claim.client = client,
          error => { this.logger.error(error); Swal.close()} ,
          () => Swal.close(),
        );

        if (this.claim.orders) {
          this.claim.orders.sort((a, b) => (moment(a.dateOrder, 'DD/MM/YYYY').isBefore(moment(b.dateOrder, 'DD/MM/YYYY'))) ? 1 : -1);

          this.claim.orders.forEach(order => {
            order.products.sort((a, b) => a.name > b.name ? 1 : -1);
          });
        }

        if (this.claim.activities) {
          this.claim.activities.sort((a, b) => (moment(a.createdAt).isAfter(moment(b.createdAt))) ? 1 : -1);

          this.claim.activities.forEach(activity => {
            if (activity.type === 'COMMENT' || activity.type === 'COMMENT_LETTER' || activity.type === 'SEND_LETTER') {
              this.claimsBasePath.one('', this.data.claim.id).all('activity').one('', activity.id).all('documents').getList().subscribe(
                documents => activity.documents = documents,
                error => this.logger.error(error),
              );
            }
          });
        }

        this.claimsBasePath.one('', this.data.claim.id).all('documents').getList().subscribe(
          documents => this.claim.documents = documents,
          error => this.logger.error(error),
        );

        if (this.claim) {
          const firstResponsibles: any[] = claim.firstResponsible ? claim.firstResponsible.split(', ') : [];
          const findFirstResponsible = firstResponsibles.find((item: string) => item.toLowerCase().trim() === this.user.email.toLowerCase().trim());

          this.disabledReAssign = findFirstResponsible === undefined;
        }
      },
      error => this.logger.error(error),
      () => this.saving = false,
    );
  }

  isDisabled() {
    const finalStatus: number[] = [
      environment.commons.claimStatus.INVALIDATED,
      environment.commons.claimStatus.ATTENDED,
    ];

    return this.claim ? finalStatus.includes(this.claim.currentStatus.id) : true;
  }

  isDisabledInvalidated() {
    const checkStatus: number[] = [
      environment.commons.claimStatus.REGISTERED,
      environment.commons.claimStatus.IN_REVIEW,
    ];

    return this.claim ? !checkStatus.includes(this.claim.currentStatus.id) : true;
  }

  isDisabledTemplates() {
    const checkStatus: number[] = [
      environment.commons.claimStatus.ATTENDED,
      environment.commons.claimStatus.NOT_ATTENDED,
      environment.commons.claimStatus.NOT_SHOWED,
    ];

    const isProduction = environment.production;
    return this.claim ? checkStatus.includes(this.claim.currentStatus.id) || this.claim.letterSendDate || !!(isProduction && !this.utilService.isTpsa(this.company)) : true;
  }

  prioritize() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando...',
    });

    Swal.showLoading();

    this.saving = true;

    const data = {
      createdBy: this.user.username,
    };

    this.claimsBasePath.one('', this.data.claim.id).all('prioritize').customPUT(data).subscribe(
      response => {
        this.logger.debug('prioritize', response);
        this.loadDetails();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `Se priorizó con éxito el reclamo ${this.claim.code}`,
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 3000,
        });
      },
      error => {
        this.logger.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Lo sentimos',
          text: 'No se pudo priorizar el reclamo',
          showConfirmButton: false,
          timer: 1500,
        });
        this.saving = false;
      }
    );
  }

  recontact() {
    Swal.fire({
      title: '¿Deseas recontactar este reclamo?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        this.saving = true;

        Swal.fire({
          allowOutsideClick: false,
          icon: 'info',
          text: 'Guardando...',
        });

        Swal.showLoading();

        const data = {
          createdBy: this.user.username,
        };

        this.claimsBasePath.one('', this.data.claim.id).all('recontact').customPUT(data).subscribe(
          response => {
            this.logger.debug('recontact', response);
            this.loadDetails();
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: `Se re-contactó con éxito el reclamo ${this.claim.code}`,
              showConfirmButton: true,
              confirmButtonColor: '#20a8d8',
              confirmButtonText: 'Ok',
              timer: 3000,
            });
          },
          error => {
            this.logger.error(error);
            Swal.fire({
              icon: 'error',
              title: 'Lo sentimos',
              text: 'No se pudo recontactar el reclamo',
              showConfirmButton: false,
              timer: 1500,
            });
            this.saving = false;
          }
        );
      }
    });
  }

  assign() {
    this.saving = true;

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalClaimAssignComponent, {
      width: '740px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        claim: this.claim,
      }
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      } else {
        this.saving = false;
      }
    });
  }

  comment() {
    this.saving = true;

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalClaimCommentComponent, {
      width: '500px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        claim: this.claim,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      } else {
        this.saving = false;
      }
    });
  }

  invalidate() {
    this.saving = true;

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalClaimInvalidateComponent, {
      width: '700px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        claim: this.claim,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      } else {
        this.saving = false;
      }
    });
  }

  changeStatus(status: any) {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalClaimStatusConfirmComponent, {
      width: '806px',
      maxHeight: '100vh',
      disableClose: true, 
      autoFocus: false,
      scrollStrategy,
      data: {
        claim: this.claim,
        status,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }


  updateOcurrencyDate() {
    this.saving = true;

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Actualizando...',
    });

    Swal.showLoading();

    const data = {
      createdBy: this.user.username,
      date: moment({ year: this.ocurrencyDateObj.year, month: this.ocurrencyDateObj.month - 1, day: this.ocurrencyDateObj.day }).format('YYYY-MM-DD'),
    };

    this.claimsBasePath.one('', this.data.claim.id).all('dates').all('ocurrency-date').customPUT(data).subscribe(
      response => {
        this.logger.debug('dates/ocurrency-date', response);
        this.loadDetails();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `Se actualizó correctamente el reclamo ${this.claim.code}`,
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 3000,
        });
      },
      error => {
        this.logger.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Lo sentimos',
          text: 'No se pudo actualizar el reclamo',
          showConfirmButton: false,
          timer: 1500,
        });
        this.saving = false;
        this.ocurrencyDateObj = null;
      }
    );
  }

  updateFirstManagementDate() {
    this.saving = true;

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Actualizando...',
    });

    Swal.showLoading();

    const data = {
      createdBy: this.user.username,
      date: moment({ year: this.firstManagementDateObj.year, month: this.firstManagementDateObj.month - 1, day: this.firstManagementDateObj.day }).format('YYYY-MM-DD'),
    };

    this.claimsBasePath.one('', this.data.claim.id).all('dates').all('first-management').customPUT(data).subscribe(
      response => {
        this.logger.debug('dates/first-management', response);
        this.loadDetails();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `Se actualizó correctamente el reclamo ${this.claim.code}`,
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 3000,
        });
      },
      error => {
        this.logger.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Lo sentimos',
          text: 'No se pudo actualizar el reclamo',
          showConfirmButton: false,
          timer: 1500,
        });
        this.saving = false;
        this.firstManagementDateObj = null;
      }
    );
  }

  updateLetterGenerationDate() {
    this.saving = true;

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Actualizando...',
    });

    Swal.showLoading();

    const data = {
      createdBy: this.user.username,
      date: moment({ year: this.charterGenerationDateObj.year, month: this.charterGenerationDateObj.month - 1, day: this.charterGenerationDateObj.day }).format('YYYY-MM-DD'),
    };

    this.claimsBasePath.one('', this.data.claim.id).all('dates').all('letter-generation').customPUT(data).subscribe(
      response => {
        this.logger.debug('dates/letter-generation', response);
        this.loadDetails();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `Se actualizó correctamente el reclamo ${this.claim.code}`,
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 3000,
        });
      },
      error => {
        this.logger.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Lo sentimos',
          text: 'No se pudo actualizar el reclamo',
          showConfirmButton: false,
          timer: 1500,
        });
        this.saving = false;
        this.charterGenerationDateObj = null;
      }
    );
  }

  updateLetterSendDate() {
    this.saving = true;

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Actualizando...',
    });

    Swal.showLoading();

    const data = {
      createdBy: this.user.username,
      date: moment({ year: this.letterSendDateObj.year, month: this.letterSendDateObj.month - 1, day: this.letterSendDateObj.day }).format('YYYY-MM-DD'),
    };

    this.claimsBasePath.one('', this.data.claim.id).all('dates').all('letter-send').customPUT(data).subscribe(
      response => {
        this.logger.debug('dates/letter-send', response);
        this.loadDetails();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `Se actualizó correctamente el reclamo ${this.claim.code}`,
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 3000,
        });
      },
      error => {
        this.logger.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Lo sentimos',
          text: 'No se pudo actualizar el reclamo',
          showConfirmButton: false,
          timer: 1500,
        });
        this.saving = false;
        this.letterSendDateObj = null;
      }
    );
  }

  edit() {
    this.saving = true;

    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalClaimRegisterComponent, {
      width: '916px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        client: this.claim.client,
        claim: this.claim,
        edit: true,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);

      if (dialogResponse) {
        const data = {
          createdBy: this.user.username,
        };

        this.claimsBasePath.one('', this.claim.id).all('activity-edit').post(data).subscribe(
          response => {
            this.logger.debug('activity-edit', response);
            this.loadDetails();
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: `Se actualizó con éxito el reclamo ${this.claim.code}`,
              showConfirmButton: true,
              confirmButtonColor: '#20a8d8',
              confirmButtonText: 'Ok',
              timer: 3000,
            });
          },
          error => this.logger.error(error),
          () => this.saving = false,
        );
      } else {
        this.saving = false;
      }
    });
  }

  dowloadPDF() {
    if (!this.claim.document) {
      Swal.fire({
        title: 'Aún no se generó el PDF, por favor intente en un momento.',
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: '#20a8d8',
        confirmButtonText: 'Ok',
        timer: 3000,
      });

      return;
    }

    Swal.fire({
      title: '¿Deseas descargar el reclamo?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#20a8d8',
      cancelButtonColor: '#ea635f',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        const link = document.createElement('a');
        link.href = `${this.downloadEndpoint}/incidence/v1/documents/${this.claim.document.document.id}/download?force=true`;
        link.setAttribute('target', '_blank');
        link.click();
        link.remove();
      }
    });
  }

  editTemplate() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(ModalClaimTemplateEditorComponent, {
      width: '1436px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        claim: this.claim,
      },
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside dialog response', dialogResponse);
      if (dialogResponse) {
        this.loadDetails();
      }
    });
  }
}
