import { Component, OnInit, Inject } from '@angular/core';
import { AppendFilesComponent } from 'src/app/shared/append-files-module/append-files.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { CommentModels } from 'src/app/models/Comment.models';
import { FileObject } from 'src/app/models/file-object.model';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { IncidenceService } from 'src/app/services/service.index';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-comment',
  templateUrl: './modal-comment.component.html',
  styleUrls: ['./modal-comment.component.scss'],
  providers: [
    NGXLogger,
  ],
})
export class ModalCommentComponent implements OnInit {
  logo = '';
  user = '';
  trackingIncidence: any;
  comment: CommentModels;
  fileList: FileObject[];
  timeNow: Date;

  constructor(
    public incidenceService: IncidenceService,
    public dialogRef: MatDialogRef<ModalCommentComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public logger: NGXLogger,
  ) {
    this.user = localStorage.getItem('username');
  }

  ngOnInit() {
    this.comment = new CommentModels();
    this.fileList = [];
    this.timeNow = new Date();
    this.trackingIncidence = this.data.trackingIncidence;
    this.comment.idIncidence = this.trackingIncidence.id;
  }

  openAppendFileDialog() {
    const dialogRef = this.dialog.open(AppendFilesComponent, {
      width: '500px',
      disableClose: true,
      data: {},
    });

    dialogRef.afterClosed().subscribe(res => {
      this.logger.debug('inside dialog response', res);
      if (res) {
        this.fileList = res;
      }
    });
  }

  saveComment(form: NgForm) {
    if (form.invalid) {
      return;
    }

    this.comment.user = localStorage.getItem('username');

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando comentario',
    });

    Swal.showLoading();
    this.incidenceService.newComment(this.comment).subscribe(
      detail => {

        if (this.fileList && this.fileList.length > 0) {
          Swal.fire({
            allowOutsideClick: false,
            icon: 'info',
            text: 'Subiendo archivos',
          });

          Swal.showLoading();

          let document: any;
          let formData: FormData;
          let count = 0;

          this.fileList.forEach((item: FileObject, index) => {
            if (item.file.isFile) {
              const fileEntry = item.file as FileSystemFileEntry;

              fileEntry.file((file: File) => {
                document = {};
                document.name = item.name;
                document.modifiedDate = item.lastModifiedDate;
                document.contentType = item.type;
                document.size = item.size;

                // Prepare formdata to upload
                formData = new FormData();
                formData.append('document', new Blob([JSON.stringify(document)], {type: 'application/json'}));
                formData.append('file', file);

                const url = `${environment.API_INCIDENCE}/incidence/v1/comment/${detail.id}/attachment`;
                const request = new Request(url, {
                  method: 'POST',
                  mode: 'cors',
                  body: formData,
                });

                // Upload to server
                (async () => {
                  const response = await fetch(request);
                  const json = await response.json();
                  this.logger.debug('Uploaded data', json);
                  count++;

                  if (count === this.fileList.length) {
                    this.showCommentSuccessMessage();
                  }
                })();
              });
            }
          });
        } else {
          this.showCommentSuccessMessage();
        }
      },
      error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Error al guardar comentario',
          showConfirmButton: false,
          timer: 1500
        });

        this.logger.error(error);
        this.dialogRef.close();
    });
  }

  showCommentSuccessMessage() {
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Se guardó el comentario correctamente',
      showConfirmButton: false,
      timer: 1500
    });

    this.dialogRef.close(true);
  }
}
