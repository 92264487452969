import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NGXLogger } from 'ngx-logger';
import { merge, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared/shared.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-updates-notification',
  templateUrl: './updates-notification.component.html',
  styleUrls: ['./updates-notification.component.scss'],
})
export class UpdatesNotificationComponent implements OnInit {
  updateAvailable$: Observable<boolean>;
  closed$ = new Subject<void>();
  fullView: boolean = false;
  updatedLater: boolean = false;

  constructor(
    private updates: SwUpdate,
    private logger: NGXLogger,
    private sharedService: SharedService,
  ) {
    this.updateAvailable$ = merge(
      of(false),
      this.updates.available.pipe(map(() => true)),
      this.closed$.pipe(map(() => false)),
    );
    
    this.updateAvailable$.subscribe((value: boolean) => {
      this.logger.debug('updates check:', value, environment.swWorkerEnable);

      if (value) {
        this.promptUser();
      }
    });

    this.updates.activated.subscribe(resp => {
      this.logger.debug('App is updated', resp);
    });
  }

  ngOnInit(): void {
    this.sharedService.getUpdatedLater().subscribe((value: boolean) => {
      this.updatedLater = value;
    });
  }

  promptUser() {
    if (environment.swWorkerEnable) {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'La plataforma Tracking de Incidencias tiene una nueva versión',
        text: 'Haz click  para iniciar la actualización',
        showCancelButton: true,
        confirmButtonColor: '#49a2b6',
        cancelButtonColor: '#ea635f',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Mas tarde',
        customClass: {
          title: 'sweet-promptuser-title',
          content: 'sweet-promptuser-content',
          confirmButton: 'sweet-promptuser-confirmbtn',
          cancelButton: 'sweet-promptuser-cancelbtn',
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.value) {
          this.applyUpdates();
        } else {
          this.sharedService.setUpdatedLater(true);
        }
      });
    }
  }

  applyUpdates() {
    this.updates.activateUpdate().then(() => {
      localStorage.setItem('updated', 'true');
      this.sharedService.setUpdatedLater(false);
      document.location.reload();
    });
  }
}
