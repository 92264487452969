import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDatepicker } from "@angular/material";
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NGXLogger } from "ngx-logger";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    constructor(private logger: NGXLogger) {
    }

    openCalendar(picker: MatDatepicker<Date>, input: any) {
        picker.open();
        setTimeout(() => input.focus());
    }

    closeCalendar(input: any) {
        setTimeout(() => input.blur());
    }

    compareIds(item1: any, item2: any) {
        return item1 && item2 ? item1.id === item2.id : item1 === item2;
    }

    trackByFn(index, item) {
        return item.id;
    }

    getOrderColumnKey(value: string): string {
        return [...environment.commons.orderColumns].find(([key, val]) => val === value)[0];
    }

    getOrderColumnClaimKey(value: string): string {
        return [...environment.commons.orderColumnsClaim].find(([key, val]) => val === value)[0];
    }

    isSpsa(company: string): boolean {
        const options: any[] = ['pvea', 'viv', 'ecmx', 'mass', 'mkr'];
        return options.includes(company.toLowerCase());
    }

    isIrDg(company: string): boolean {
        return company === 'irdg';
    }

    isRPlaza(company: string): boolean {
        const options: any[] = ['rplaza'];
        return options.includes(company.toLowerCase());
    }

    isHpsa(company: string) {
        return company.toLowerCase() === 'hpsa';
    }

    isTpsa(company: string) {
        return company.toLowerCase() === 'tpsa';
    }

    isPveaOrViv(company: string): boolean {
        const options: any[] = ['pvea', 'viv'];
        return options.includes(company.toLowerCase());
    }

    markFormGroupTouched(form: FormGroup) {
        Object.values(form.controls).forEach(control => {
          control.markAsTouched();

          if ((control as any).controls) {
            this.markFormGroupTouched(control as FormGroup);
          }
        });
    }

    validateInput(currentValue: NgbDate | null, input: string, calendar: NgbCalendar, formatter: NgbDateParserFormatter): NgbDate | null {
        this.logger.debug('validateInput', currentValue, input);
        const parsed = formatter.parse(input);
        return parsed && calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : null;
    }

    deleteEmptyQueryParams(obj: any) {
        Object.keys(obj).forEach(key => {
          if (!obj[key] || obj[key].length === 0) {
            delete obj[key];
          }
        });

        return obj;
    }
}
