export class NewIncidence {

  id: number;
  codIncidence: string;
  user: string;
  client: number;
  codRequest: string;
  store: number;
  subStore: number;
  reason: number;
  subReason: number;
  format: number;
  deliveryMethod: number;
  area1: number;
  area2: number;
  observations: string;
  company: string;
  attachedUrl: string;
  contactsTO: string[];
  contactsCC: string[];
  subStoreName: string;
  products: any[];
}
