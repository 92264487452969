export class ClienteModels {
  id?: number;
  name: string;
  lastname: string;
  address: string;
  phone: string;
  email: string;
  codUbigeo: string;
  typeDoc: string;
  numDoc: string;
  identificationType: any;
}
