import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';
import { NGXLogger } from 'ngx-logger';
import { NgxFileDropEntry, FileSystemDirectoryEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { environment } from 'src/environments/environment';
import { FileObject } from 'src/app/models/file-object.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-append-files-module',
  templateUrl: './append-files.component.html',
  styleUrls: ['./append-files.component.scss'],
  providers: [
    NGXLogger,
  ],
})
export class AppendFilesComponent implements OnInit {
  fileSize: number = 0;
  allowedContentTypes: string[] = [
    'image/*',
    'video/*',
    'audio/*',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/zip',
    'application/x-7z-compressed',
    'application/x-rar-compressed',

    '.pdf',
    '.ppt',
    '.pptx',
    '.xls',
    '.xlsx',
    '.doc',
    '.docx',
    '.zip',
    '.7z',
    '.rar',
  ];
  maxFileSizeMB = environment.maxFileSizeMB;
  maxFiles: number = 5;
  dragOver: boolean;
  uploading: boolean;
  fileList: FileObject[] = [];
  isFromClaims: boolean = false;

  constructor(
    private fb: FormBuilder,
    public logger: NGXLogger,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.fileList = [];
    this.isFromClaims = this.data.isFromClaims || false;
    this.maxFiles = this.data.totalDocuments || 5;
    this.logger.debug('init values', this.data, this.maxFiles);
  }

  // DRAG / DROP
  dropped(files: NgxFileDropEntry[]) {
    this.dragOver = false;
    let fileItem: FileObject;

    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

        fileEntry.file((file: File) => {
          fileItem = {} as FileObject;
          fileItem.isFile = true;
          fileItem.isDirectory = false;
          fileItem.name = droppedFile.relativePath;
          fileItem.lastModifiedDate = file.lastModified;
          fileItem.size = file.size;
          fileItem.type = file.type;
          fileItem.file = fileEntry;
          fileItem.extension = fileItem.name.split('.').pop();

          if ((file.size / 1024 / 1024) <= this.maxFileSizeMB && this.validateFilesSize(file.size) && this.fileList.length < this.maxFiles) {
            if (this.validateMimeTypeOrExtension(fileItem)) {
              this.logger.debug('dropped', droppedFile, file, fileItem);
              this.fileList.push(fileItem);
            } else {
              Swal.fire({
                icon: 'error',
                text: `Se ha producido un error al cargar el archivo, intenta nuevamente.`,
              });
              this.logger.error('rejected', droppedFile, file, fileItem);
            }
          } else {
            Swal.fire({
              icon: 'info',
              text: `Los archivos subidos superan la capacidad máxima de ${this.maxFileSizeMB} MB`,
              confirmButtonColor: '#20a8d8',
              confirmButtonText: 'Ok',
            });
            this.logger.error('rejected', droppedFile, file, fileItem);
          }
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        this.logger.debug(droppedFile.relativePath, fileEntry);
      }
    }
  }

  fileOver(event) {
    this.logger.debug('fileOver', event);
    this.dragOver = true;
  }

  fileLeave(event) {
    this.logger.debug('fileLeave', event);
  }

  removeFileItem(index: number) {
    this.fileList.splice(index, 1);
  }

  cancel() {
    this.fileList = [];
  }

  validateFilesSize(newFileSize?: number): boolean {
    this.fileSize = 0;

    this.fileList.forEach(item => {
      this.fileSize += item.size;
    });

    const newFilesSize = this.fileSize + newFileSize;
    return (newFilesSize / 1024 / 1024) <= this.maxFileSizeMB;
  }

  validateMimeTypeOrExtension(fileItem: FileObject): boolean {
    const find = this.allowedContentTypes.find(item => {
      return fileItem.type.startsWith(item.replace('*', '').trim()) || item === `.${fileItem.extension}`;
    });

    return find !== undefined;
  }
}
