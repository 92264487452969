import { Directive, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: "input"
})
export class InputValidatorDirective {

  notApplied: boolean = true;
  private _oldvalue: string = "";
  private regExpr: any = new RegExp(/^[A-Z|a-z|0-9]+$/);
  @Input('ird-validator')
  set validator(value) {
    if (value !== null && value !== undefined && value !== '') {
      this.notApplied = false;
      if (value == "alphanumeric") {
        this.regExpr = new RegExp(/^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s_.-]*$/);
      } else if (value == "alphabetic") {
        this.regExpr = new RegExp(/^[A-Za-zñÑáéíóúÁÉÍÓÚ\s]*$/);
      } else if (value == "integer") {
        this.regExpr = new RegExp(/^[0-9]*$/);
      } else if (value == "numeric") {
        this.regExpr = new RegExp(/^[0-9.]*$/);
      } else if (value == "alphanumericV2") {
        this.regExpr = new RegExp(/^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s_.,;-]*$/);
      } else if (value == "domain") {
        this.regExpr = new RegExp(/^[a-zA-Z.:/-]*$/);
      } else if (value == "email") {
        this.regExpr = new RegExp(/^[a-zA-Z0-9_.@-]*$/);
      } else {
        this.regExpr = new RegExp(value);
      }
    }
  }

  constructor(@Optional() private control: NgControl) { }
  @HostListener("input", ["$event"])
  change($event) {
    if (this.notApplied) return;

    let item = $event.target;
    let value = item.value;
    let pos = item.selectionStart;
    let matchvalue = value;
    let noMatch: boolean = value && !this.regExpr.test(matchvalue);
    if (noMatch) {
      item.selectionStart = item.selectionEnd = pos - 1;
      if (item.value.length < this._oldvalue.length && pos == 0) pos = 2;
      if (this.control)
        this.control.control.setValue(this._oldvalue, { emit: false });

      item.value = this._oldvalue;
      item.selectionStart = item.selectionEnd = pos - 1;
    } else this._oldvalue = value;
  }
}
