import { Overlay } from '@angular/cdk/overlay';
import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbCalendar, NgbDate, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_CLAIMS } from 'src/app/app.restangular.config';
import { StoreService } from 'src/app/services/stores/store.service';
import { UtilService } from 'src/app/services/util/util.service';
import moment from 'moment';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { AppendFilesComponent } from 'src/app/shared/append-files-module/append-files.component';
import { FileObject } from 'src/app/models/file-object.model';
import { FileSystemFileEntry } from 'ngx-file-drop';

const I18N_VALUES = {
  'es': {
    weekdays: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  },
};

@Injectable()
export class I18n {
  language = 'es';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day : parseInt(date[0], 10),
        month : parseInt(date[1], 10),
        year : parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-modal-claim-register',
  templateUrl: './modal-claim-register.component.html',
  styleUrls: ['./modal-claim-register.component.scss'],
  providers: [
    NGXLogger,
    I18n,
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class ModalClaimRegisterComponent implements OnInit {
  claim: any;
  client: any;
  user: any;
  company: string = '';
  claimsBasePath: any;
  parametersBasePath: any;
  saving: boolean = false;
  loading: boolean = true;
  textAreaMinRows: number = +3;
  textAreaMaxRows: number = +5;

  store: any;
  subStore: any;
  reason: any;
  subReason: any;
  area: any;
  requestType: any;
  format: any;
  source: any;
  responseMedium: any;
  claimType: any;
  asset: any;
  amount: number;
  details: string;
  orderRemarks: string;
  assetsRemarks: string;

  stores: any[] = [];
  subStores: any[] = [];
  reasons: any[] = [];
  subReasons: any[] = [];
  areas: any[] = [];
  requestTypes: any[] = [];
  formats: any[] = [];
  sources: any[] = [];
  responseMediums: any[] = [];
  claimTypes: any[] = [];
  assets: any[] = [];

  filteredStores: Observable<any[]>;
  filteredSubStores: Observable<any[]>;
  filteredReasons: Observable<any[]>;
  filteredSubReasons: Observable<any[]>;
  filteredAreas: Observable<any[]>;
  filteredFormats: Observable<any[]>;
  filteredSources: Observable<any[]>;
  filteredRequestTypes: Observable<any[]>;
  filteredResponseMediums: Observable<any[]>;
  filteredClaimTypes: Observable<any[]>;
  filteredAssets: Observable<any[]>;

  disabledSubStore: boolean = false;

  registerDateObj: NgbDate | null;
  registerDateInputModel;
  fileList: FileObject[];

  today: NgbDate | null;

  constructor(
    private authService: AuthService,
    public storeService: StoreService,
    public logger: NGXLogger,
    @Inject(RESTANGULAR_CLAIMS) public restangularBase,
    public dialogRef: MatDialogRef<ModalClaimRegisterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private overlay: Overlay,
    public utilService: UtilService,
    public calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
  ) {
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user: any) => {
      this.user = user;
      this.company = this.data.claim ? this.data.claim.company : this.user.company;

      this.claimsBasePath = this.restangularBase.one('', this.company).all('claims');
      this.parametersBasePath = this.restangularBase.one('', this.company).all('parameters');

      this.client = this.data.client;
      this.today = this.calendar.getToday();

      if (this.data.claim.id) {
        this.claimsBasePath.one('', this.data.claim.id).get().subscribe(
          claim => {
            this.claim = claim;

            // Load claim data
            if (this.data.edit) {
              this.logger.debug('Loading claim values');
              this.source = this.claim.source;
              this.format = this.claim.format;
              this.area = this.claim.area;
              this.requestType = this.claim.requestType;
              this.store = this.claim.store;
              this.subStore = {
                id: this.claim.idPhysicalStore,
                name: this.claim.physicalStoreName,
              };
              this.reason = this.claim.reason;
              this.subReason = this.claim.subReason;
              this.responseMedium = this.claim.responseMedium;
              this.claimType = this.claim.type;
              this.asset = this.claim.asset;
              this.amount = this.claim.amount;
              this.details = this.claim.details;
              this.orderRemarks = this.claim.orderRemarks;
              this.assetsRemarks = this.claim.assetsRemarks;
              const registerDate = moment(this.claim.registeredDate, 'YYYY-MM-DD');
              this.registerDateObj = new NgbDate(registerDate.year(), registerDate.month() + 1, registerDate.date());
            }

            this.claimsBasePath.one('', this.claim.id).all('documents').getList().subscribe(
              documents => this.claim.documents = documents,
              error => this.logger.error(error),
              () => this.loading = false,
            );
          },
          error => this.logger.error(error),
          () => {
            this.loadDependencies();
          },
        );
      } else {
        this.claim = this.data.claim;

        if (this.claim.orders.length > 0) {
          let assetsRemarks = '';

          this.claim.orders.sort((a, b) => (moment(a.dateOrder, 'DD/MM/YYYY').isBefore(moment(b.dateOrder, 'DD/MM/YYYY'))) ? 1 : -1);

          this.claim.orders.forEach(order => {
            assetsRemarks += `N° pedido: ${order.orderId}\r\n`;

            order.products.sort((a, b) => a.name > b.name ? 1 : -1);

            order.products.forEach(product => {
              assetsRemarks += `${product.name}, Cantidad: ${product.quantity}\r\n`;
            });
            assetsRemarks += `\r\n`;
          });

          this.assetsRemarks = assetsRemarks;
          this.logger.debug('assetsRemarks', this.assetsRemarks);
        }

        this.loading = false;
        this.loadDependencies();
      }
    });
  }

  loadDependencies() {
    this.logger.debug('Loading dependecies');
    // this.loadSources();
    this.loadFormats();
    this.loadAreas();
    this.loadRequestTypes();
    this.loadStores();
    this.loadReasons();
    this.loadSubReasons();
    this.loadResponseMediums();
    this.loadClaimTypes();
    this.loadAssets();
    this.loadPhysicalStores();
  }

  loadSources() {
    this.parametersBasePath.all("sources").getList().subscribe(
      items => this.sources = items,
    );
  }

  filterSources(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.source === 'string' ? this.source : this.source.name;
    }

    this.filteredSources = of(value && value.trim().length > 0 ?
      this.sources.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.sources.slice());
  }

  loadFormats() {
    this.parametersBasePath.all("formats").getList().subscribe(
      items => this.formats = items,
    );
  }

  filterFormats(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.format === 'string' ? this.format : this.format.name;
    }

    this.filteredFormats = of(value && value.trim().length > 0 ?
      this.formats.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.formats.slice());
  }

  loadAreas() {
    this.parametersBasePath.all("areas").getList().subscribe(
      items => this.areas = items,
    );
  }

  filterAreas(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.area === 'string' ? this.area : this.area.name;
    }

    this.filteredAreas = of(value && value.trim().length > 0 ?
      this.areas.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.areas.slice());
  }

  loadRequestTypes() {
    this.parametersBasePath.all("request-types").getList().subscribe(
      items => this.requestTypes = items,
    );
  }

  filterRequestTypes(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.requestType === 'string' ? this.requestType : this.requestType.name;
    }

    this.filteredRequestTypes = of(value && value.trim().length > 0 ?
      this.requestTypes.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.requestTypes.slice());
  }

  loadStores() {
    const request = this.format ? this.parametersBasePath.all("stores").all('search-by').one('format', this.format.id).getList() : this.parametersBasePath.all("stores").getList();

    request.subscribe(
      items => this.stores = items,
    );
  }

  filterStores(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.store === 'string' ? this.store : this.store.name;
    }

    this.filteredStores = of(value && value.trim().length > 0 ?
      this.stores.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.stores.slice());
  }

  filterSubStores(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.subStore === 'string' ? this.subStore : this.subStore.name;
    }

    this.filteredSubStores = of(value && value.trim().length > 0 ?
      this.subStores.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.subStores.slice());
  }

  loadReasons() {
    this.parametersBasePath.all("reasons").getList().subscribe(
      items => this.reasons = items,
    );
  }

  filterReasons(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.reason === 'string' ? this.reason : this.reason.name;
    }

    this.filteredReasons = of(value && value.trim().length > 0 ?
      this.reasons.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.reasons.slice());
  }

  loadSubReasons() {
    this.subReasons = this.reason ? this.reason.subreasons : [];
  }

  filterSubReasons(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.subReason === 'string' ? this.subReason : this.subReason.name;
    }

    this.filteredSubReasons = of(value && value.trim().length > 0 ?
      this.subReasons.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.subReasons.slice());
  }

  loadResponseMediums() {
    this.parametersBasePath.all("response-mediums").getList().subscribe(
      items => this.responseMediums = items,
    );
  }

  filterResponseMediums(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.responseMedium === 'string' ? this.responseMedium : this.responseMedium.name;
    }

    this.filteredResponseMediums = of(value && value.trim().length > 0 ?
      this.responseMediums.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.responseMediums.slice());
  }

  loadClaimTypes() {
    this.parametersBasePath.all("claim-types").getList().subscribe(
      items => this.claimTypes = items,
    );
  }

  filterClaimTypes(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.claimType === 'string' ? this.claimType : this.claimType.name;
    }

    this.filteredClaimTypes = of(value && value.trim().length > 0 ?
      this.claimTypes.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.claimTypes.slice());
  }

  loadAssets() {
    this.parametersBasePath.all("assets").getList().subscribe(
      items => this.assets = items,
    );
  }

  filterAssets(clearKeyword?: boolean) {
    let value = '';

    if (!clearKeyword) {
      value = typeof this.asset === 'string' ? this.asset : this.asset.name;
    }

    this.filteredAssets = of(value && value.trim().length > 0 ?
      this.assets.filter(item => item.name.toLowerCase().includes(value.toLowerCase())) : this.assets.slice());
  }

  loadPhysicalStores() {
    if (this.store && this.store.id && this.store.id === environment.commons.promartPeClaimID) {
      this.disabledSubStore = true;
    } else {
      this.disabledSubStore = false;

      this.storeService.obtainStoresForClaim(this.format && this.format.code ? this.format.code : this.company).subscribe(
        (stores: any[]) => {
          // Hide some stores
          const hiddenStores = environment.commons.hiddenStores[this.company];
          this.subStores = hiddenStores ? stores.filter(store => hiddenStores.includes(+store.codigoTienda) === false) : stores;
          this.subStores = this.subStores.map(store => ({ ...store, id: +store.codigoTienda, name: store.nombreTienda }));
          this.subStores.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          this.logger.debug('loadPhysicalStores', this.subStores);
        },
      );
    }
  }

  resetSource() {
    this.source = null;
  }

  resetStore() {
    this.store = null;
  }

  resetSubStore() {
    this.subStore = null;
  }

  resetReason() {
    this.reason = null;
  }

  resetSubReason() {
    this.subReason = null;
  }

  resetArea() {
    this.area = null;
  }

  resetRequestType() {
    this.requestType = null;
  }

  resetFormat() {
    this.format = null;
  }

  resetResponseMedium() {
    this.responseMedium = null;
  }

  resetClaimType() {
    this.claimType = null;
  }

  resetAsset() {
    this.asset = null;
  }

  displayNamePropertyFn(item: any): string {
    return item && item.name ? item.name : '';
  }

  checkCompany() {
    return ['hpsa', 'tpsa'].includes(this.company);
  }

  save() {
    this.saving = true;
    // this.claim.source = this.source;
    this.claim.format = this.format ? {id: this.format.id} : null;
    this.claim.area = this.area;
    this.claim.requestType = this.requestType;
    this.claim.store = this.store;
    this.claim.subStore = this.subStore;
    this.claim.reason = (this.reason) ? {id: this.reason.id} : null;
    this.claim.subReason = (this.subReason) ? {id: this.subReason.id} : null;
    this.claim.responseMedium = this.responseMedium;
    this.claim.type = this.claimType;
    this.claim.asset = this.asset;
    this.claim.amount = this.amount;
    this.claim.details = this.details;
    this.claim.orderRemarks = this.orderRemarks;
    this.claim.assetsRemarks = this.assetsRemarks;
    this.claim.registeredDate = (this.registerDateObj) ? moment({ year: this.registerDateObj.year, month: this.registerDateObj.month - 1, day: this.registerDateObj.day }).format('YYYY-MM-DD') : null;
    this.claim.idPhysicalStore = this.subStore ? this.subStore.id : null;
    this.claim.physicalStoreName = this.subStore ? this.subStore.name : null;

    this.claim.storeCode = !isNaN(Number(localStorage.getItem('storeCode'))) ? localStorage.getItem('storeCode') : '';

    this.claim.activities = null;
    this.claim.orders = this.claim.id ? null : this.claim.orders;
    this.claim.status = null;

    this.logger.debug('claim data', this.claim);

    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando...',
    });

    Swal.showLoading();

    const requestForClaim = this.claim.id ?
      this.claimsBasePath.one('', this.claim.id).customPUT(this.claim) :
      this.claimsBasePath.post(this.claim);

    requestForClaim.subscribe(
      response => {
        this.logger.debug('PUT response', response);
        this.claim = response;

        // Upload files
        if (this.fileList && this.fileList.length > 0) {
          Swal.fire({
            allowOutsideClick: false,
            icon: 'info',
            text: 'Subiendo archivos'
          });

          Swal.showLoading();

          let document: any;
          let formData: FormData;
          let count = 0;

          this.fileList.forEach((item: FileObject, index) => {
            if (item.file.isFile) {
              const fileEntry = item.file as FileSystemFileEntry;

              fileEntry.file((file: File) => {
                document = {};
                document.name = item.name;
                document.modifiedDate = item.lastModifiedDate;
                document.contentType = item.type;
                document.size = item.size;

                // Prepare formdata to upload
                formData = new FormData();
                formData.append('document', new Blob([JSON.stringify(document)], {type: 'application/json'}));
                formData.append('file', file);

                const url = `${environment.API_CLAIMS}/${this.company}/claims/${this.claim.id}/attachment`;
                const request = new Request(url, {
                  method: 'POST',
                  mode: 'cors',
                  body: formData,
                });

                // Upload to server
                (async () => {
                  const responseFiles = await fetch(request);
                  const json = await responseFiles.json();
                  count++;
                  this.logger.debug('Uploaded data', json, count, this.fileList.length);

                  if (count === this.fileList.length) {
                    this.dialogRef.close(response);
                  }
                })();
              });
            }
          });
        } else {
          this.dialogRef.close(response);
        }
      },
      error => {
        this.logger.error('Generating claim error', error);
        Swal.fire({
          icon: 'error',
          html: '<b>Lo sentimos NO se <br> pudo generar el reclamo</b>'
        });
        this.saving = false;
        this.dialogRef.close();
      }
    );
  }

  openAppendFileDialog() {
    const scrollStrategy = this.overlay.scrollStrategies.block();
    const dialogRef = this.dialog.open(AppendFilesComponent, {
      width: '500px',
      maxHeight: '100vh',
      disableClose: true,
      autoFocus: false,
      scrollStrategy,
      data: {
        isFromClaims: true,
        totalDocuments: this.claim.documents ? (5 - this.claim.documents.length) : null,
      }
    });

    dialogRef.afterClosed().subscribe(dialogResponse => {
      this.logger.debug('inside openAppendFileDialog dialog response', dialogResponse);
      if (dialogResponse) {
        this.fileList = dialogResponse;
        this.logger.debug('fileList', this.fileList);
      }
    });
  }
}
