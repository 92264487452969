import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  ClienteService,
  SearchService,
  AuthService,
  StoreService,
  ParametersService,
  IncidenceService,
  FileUploadService,
  SearchIncidenceService,
  ClaimService,
  JsPDFFontService,
} from './service.index';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    ClienteService,
    SearchService,
    AuthService,
    StoreService,
    ParametersService,
    IncidenceService,
    FileUploadService,
    SearchIncidenceService,
    ClaimService,
    JsPDFFontService,
  ],
  declarations: []
})
export class ServiceModule {
}
