import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatedViewComponent } from './paginated-view.component';



@NgModule({
  declarations: [PaginatedViewComponent],
  imports: [
    CommonModule
  ],
  exports: [PaginatedViewComponent],
})
export class PaginatedViewModule { }
