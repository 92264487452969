import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { RESTANGULAR_CLAIMS } from 'src/app/app.restangular.config';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { ClaimService } from 'src/app/services/claims/claim.service';
import { UtilService } from 'src/app/services/util/util.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-claim-invalidate',
  templateUrl: './modal-claim-invalidate.component.html',
  styleUrls: ['./modal-claim-invalidate.component.scss']
})
export class ModalClaimInvalidateComponent implements OnInit {
  claimStatus: any;
  claim: any;
  reasons: any[];
  user: any;
  states = environment.commons.claimStatus;

  claimsBasePath: any;
  parametersBasePath: any;

  textAreaMinRows: number = environment.textAreaMinRows;
  textAreaMaxRows: number = environment.textAreaMaxRows;
  reasonsCommon = environment.commons.reasons;
  company;
  saving: boolean = false;

  constructor(
    private authService: AuthService,
    public claimService: ClaimService,
    @Inject(RESTANGULAR_CLAIMS) public restangularBase,
    public dialogRef: MatDialogRef<ModalClaimInvalidateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utilService: UtilService,
    public logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    this.authService.getUser().subscribe((user: any) => {
      this.user = user;
      this.claim = this.data.claim;
      this.company = this.claim.company;
      this.claimStatus = {};
      this.claimsBasePath = this.restangularBase.one('', this.company).all('claims');
      this.parametersBasePath = this.restangularBase.one('', this.company).all('parameters');

      this.parametersBasePath.all('status').all('reasons').getList().subscribe(
        response => {
          this.logger.debug('response', response);
          this.reasons = response;
        },
        error => this.logger.error(error),
      );
    });
  }

  save() {
    Swal.fire({
      allowOutsideClick: false,
      icon: 'info',
      text: 'Guardando...',
    });

    Swal.showLoading();

    this.saving = true;
    this.claimStatus.claim = { id: this.claim.id };
    this.claimStatus.status = { id: this.states.INVALIDATED };
    this.claimStatus.createdBy = this.user.username;
    this.claimStatus.company = this.company;

    this.logger.debug('state', this.claimStatus);

    this.claimService.changeState(this.claimStatus).subscribe(
      response => {
        this.dialogRef.close(true);
        this.logger.debug('response', response);

        Swal.fire({
          position: 'center',
          icon: 'success',
          title: `El reclamo se anuló correctamente`,
          showConfirmButton: true,
          confirmButtonColor: '#20a8d8',
          confirmButtonText: 'Ok',
          timer: 3000,
        });
      },
      error => {
        this.dialogRef.close();
        this.logger.error(error);

        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Lo sentimos',
          text: 'No se pudo anular el reclamo',
          showConfirmButton: false,
          timer: 1500,
        });
      });
  }
}
